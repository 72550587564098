import axios, { AxiosInstance } from 'axios';
import { AttachFile } from '../data/attachFile';
import { NavigateFunction } from 'react-router-dom';
import { refreshToken } from './apiService';

const fetchDropzonePreview = async (
  axiosInstance: AxiosInstance,
  files: AttachFile[],
  navigate: NavigateFunction
): Promise<File[] | null> => {
  if (!files || files.length === 0) return null;

  try {
    const filePromises = files.map(async file => {
      const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;

      const response = await axiosInstance.get(fileUrl, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        }
      });

      const blob = response.data;
      return new File([blob], file.originFileName, { type: blob.type });
    });

    return await Promise.all(filePromises);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 600) {
        await refreshToken(navigate, () =>
          fetchDropzonePreview(axiosInstance, files, navigate)
        );
      } else {
        console.error('서버 에러', error);
      }
    } else {
      console.error('Unexpected error:', error);
    }
    return null;
  }
};

export default fetchDropzonePreview;
