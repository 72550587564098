import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { Feeder } from '../../data/feeder';
import React, { ChangeEvent, useEffect, useState } from 'react';
import DaumPostModal from './DaumPostModal';
import { Address } from 'react-daum-postcode';
import { jsonpRequest } from '../../helpers/jsonpHelper';
import Dropzone from '../base/Dropzone';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InfoModal from './InfoModal';
import CustomDropzone from '../base/CustomDropzone';

export interface AttachFile {
  id: string;
  originFileName: string;
  savedFileName: string;
  path: string;
  extension: string;
}

interface AddDealModalProps {
  show: boolean;
  handleClose: () => void;
  feeder?: Feeder;
}

const FeederFormModal = ({ show, handleClose, feeder }: AddDealModalProps) => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<Feeder>({
    id: '',
    name: '',
    address: '',
    type: '',
    latitude: 0,
    longitude: 0,
    deleted: 'N',
    publicStatus: '',
    owner: '',
    regId: '',
    uptId: '',
    catCnt: 0,
    tnrCatCnt: 0,
    tnrRate: 0,
    addUserList: [],
    deleteUserList: [],
    addFileList: [],
    deleteFileList: [],
    fileList: []
  });

  const [showPostcode, setShowPostcode] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [isFileUploadSuccess, setIsFileUploadSuccess] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);

  const [errors, setErrors] = useState({
    name: '',
    address: '',
    type: '',
    publicStatus: '',
    owner: ''
  });

  useEffect(() => {
    if (feeder) {
      setUpdateMode(true);
      setFormState(feeder);

      // 파일 목록을 Dropzone에 추가
      const getFiles = async () => {
        const files = await Promise.all(
          feeder.fileList?.map(async (file: AttachFile) => {
            const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;
            try {
              const response = await axiosInstance.get(fileUrl, {
                responseType: 'blob',
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
              });

              const blob = response.data;
              return new File([blob], file.originFileName, { type: blob.type });
            } catch (error) {
              console.error('파일 로드 에러:', error);
              return null;
            }
          }) || []
        );
        setSelectedFiles(files.filter(file => file !== null) as File[]);
      };

      getFiles();
    }
  }, []);

  useEffect(() => {
    if (isFileUploadSuccess) {
      saveFeeder();
    }
  }, [isFileUploadSuccess]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFiles([]);
    setSelectedFiles(prevFiles => [...prevFiles, acceptedFiles[0]]);
  };

  const handleRemoveFile = (file: File) => {
    setSelectedFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
  };

  const handleAddressClick = () => {
    setShowPostcode(true);
  };

  const handleAddressComplete = async (data: Address) => {
    setFormState(prevState => ({
      ...prevState,
      address: data.address
    }));
    setShowPostcode(false);
    try {
      const key = `${process.env.REACT_APP_VWORLD_KEY}`;
      const url = `https://api.vworld.kr/req/address?service=address&request=getcoord&version=2.0&crs=epsg:4326&address=${data.address}&refine=true&simple=true&format=json&type=road&key=${key}`;
      const response = await jsonpRequest(url, 'handleVworldResponse');
      if (
        response.response &&
        response.response.result &&
        response.response.result.point
      ) {
        const { x: longitude, y: latitude } = response.response.result.point;
        setFormState(prevState => ({
          ...prevState,
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude)
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileUpload = async (): Promise<boolean> => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('files', file);
    });

    if (updateMode) {
      formState.fileList!.map((file, index) => {
        formData.append(`deleteFileList[${index}]`, file.id);
      });

      try {
        await axiosInstance.post('api/file-delete', formData);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, handleFileUpload);
          } else {
            console.error('파일 삭제 에러:', error);
          }
        } else {
          console.error('파일 삭제 에러:', error);
        }
        return false;
      }
    }

    try {
      const response = await axiosInstance.post('api/file-upload', formData);
      const filesData: AttachFile[] = response.data.response;

      setFormState(prevState => ({
        ...prevState,
        addFileList: [
          ...(prevState.addFileList || []),
          ...filesData.map(file => file.id)
        ]
      }));

      setIsFileUploadSuccess(true);

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, handleFileUpload);
        } else {
          console.error('파일 업로드 에러:', error);
        }
      } else {
        console.error('파일 업로드 에러:', error);
      }
      return false;
    }
  };

  const validateForm = () => {
    const newErrors = { ...errors };
    let isValid = true;

    if (!formState.name) {
      newErrors.name = '급식소 이름을 입력해 주세요.';
      isValid = false;
    } else {
      newErrors.name = '';
    }

    if (!formState.address) {
      newErrors.address = '주소를 입력해 주세요.';
      isValid = false;
    } else {
      newErrors.address = '';
    }

    if (!formState.type) {
      newErrors.type = '타입을 선택해 주세요.';
      isValid = false;
    } else {
      newErrors.type = '';
    }

    if (!formState.publicStatus) {
      newErrors.publicStatus = '공개여부를 선택해 주세요.';
      isValid = false;
    } else {
      newErrors.publicStatus = '';
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (selectedFiles.length > 0 || formState.fileList!.length > 0) {
      const uploadSuccess = await handleFileUpload();
      if (!uploadSuccess) {
        console.error('파일 업로드에 실패하여 급식소 저장을 중단합니다.');
      }
    } else {
      await saveFeeder();
    }
  };

  const saveFeeder = async () => {
    const formData = new FormData();

    const userId = localStorage.getItem('userId');

    if (updateMode) {
      formState.uptId = userId!;
    } else {
      formState.regId = userId!;
      formState.uptId = userId!;
    }

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/feeders/${formState.id}`, formData);
      } else {
        await axiosInstance.post('api/feeders', formData);
      }
      // 급식소 등록 성공 후 모달 띄우기
      setShowSuccessModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveFeeder);
        } else {
          console.error('Feeder 저장 에러:', error);
        }
      } else {
        console.error('Feeder 저장 에러:', error);
      }
    }
  };

  // 초기화 함수 추가
  const resetForm = () => {
    setFormState({
      id: '',
      name: '',
      address: '',
      type: '',
      latitude: 0,
      longitude: 0,
      deleted: 'N',
      publicStatus: '',
      owner: '',
      regId: '',
      uptId: '',
      catCnt: 0,
      tnrCatCnt: 0,
      tnrRate: 0,
      addUserList: [],
      deleteUserList: [],
      addFileList: [],
      deleteFileList: [],
      fileList: []
    });
    setSelectedFiles([]);
    setErrors({
      address: '',
      name: '',
      owner: '',
      publicStatus: '',
      type: ''
    });
  };

  // handleClose 호출 시 resetForm도 실행
  const handleModalHide = () => {
    resetForm();
    handleClose();
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate(0);
  };

  return (
    <div>
      <Modal
        // size="lg"
        show={show}
        centered
        backdrop="static"
        onHide={handleModalHide}
        className="modal-open"
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">{feeder ? '급식소 수정' : '급식소 추가'}</h3>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleModalHide}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row as="form" className="g-4" onSubmit={handleSubmit}>
            <Col lg={12} className="d-flex flex-column gap-4">
              <Form.Group>
                <Form.Label className="form-label-header mb-2">
                  급식소 이름 *
                </Form.Label>
                <Form.Control
                  id="name"
                  type="text"
                  placeholder="급식소 이름 (12자)"
                  value={formState.name}
                  onChange={handleChange}
                  maxLength={12}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label className="form-label-header mb-2">
                  급식소 소유주 *
                </Form.Label>
                <Form.Control
                  id="owner"
                  type="text"
                  placeholder="급식소 소유주 (ex: 해운대구)"
                  value={formState.owner}
                  onChange={handleChange}
                  maxLength={12}
                  isInvalid={!!errors.owner}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.owner}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label className="form-label-header mb-2">
                  주소 *
                </Form.Label>
                <Form.Control
                  id="address"
                  type="text"
                  placeholder="주소 "
                  value={formState.address}
                  onChange={handleChange}
                  onClick={handleAddressClick}
                  readOnly
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={12} className="d-flex flex-column gap-4">
              <Row className="g-3">
                <Form.Group as={Col} xs={6}>
                  <Form.Label className="form-label-header mb-2">
                    타입 *
                  </Form.Label>
                  <Form.Select
                    id="type"
                    value={formState.type}
                    onChange={handleChange}
                    isInvalid={!!errors.type}
                  >
                    <option>선택</option>
                    <option value="PUBLIC">공공</option>
                    <option value="PRIVATE">사설</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.type}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} xs={6}>
                  <Form.Label className="form-label-header mb-2">
                    공개여부 *
                  </Form.Label>
                  <Form.Select
                    id="publicStatus"
                    value={formState.publicStatus}
                    onChange={handleChange}
                    isInvalid={!!errors.publicStatus}
                  >
                    <option>선택</option>
                    <option value="ENABLED">공개</option>
                    <option value="DISABLED">비공개</option>
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.publicStatus}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>

              <Row className="g-3">
                <Form.Group as={Col} lg={12} xl={6}>
                  <Form.Label className="form-label-header mb-2">
                    고양이 *
                  </Form.Label>
                  <Form.Control
                    id="catCnt"
                    type="number"
                    placeholder="고양이 개체수"
                    value={formState.catCnt}
                    onChange={handleChange}
                    min={0}
                    max={99}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;
                      if (value.length > 2) {
                        e.target.value = value.slice(0, 2);
                      }
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col} lg={12} xl={6}>
                  <Form.Label className="form-label-header mb-2">
                    중성화 고양이 *
                  </Form.Label>
                  <Form.Control
                    id="tnrCatCnt"
                    type="number"
                    placeholder="중성화 고양이 개체수"
                    value={formState.tnrCatCnt}
                    onChange={handleChange}
                    min={0}
                    max={99}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value;
                      if (value.length > 2) {
                        e.target.value = value.slice(0, 2);
                      }
                    }}
                  />
                </Form.Group>
              </Row>
            </Col>
            <Col lg={12} className="d-flex flex-column gap-4">
              <Form.Label className="form-label-header mb-2">
                급식소 사진
              </Form.Label>
              <CustomDropzone
                multiple={false}
                className="mb-3"
                accept={{
                  'image/*': ['.png', '.gif', '.jpeg', '.jpg']
                }}
                onDrop={handleDrop}
                onRemoveFile={handleRemoveFile}
                initialFiles={selectedFiles}
                pictureAmount={1}
              />
              {/*<Dropzone*/}
              {/*  accept={{*/}
              {/*    'image/*': ['.png', '.gif', '.jpeg', '.jpg']*/}
              {/*  }}*/}
              {/*  onDrop={handleDrop}*/}
              {/*  onRemoveFile={handleRemoveFile}*/}
              {/*  initialFiles={selectedFiles}*/}
              {/*/>*/}
            </Col>
            <Col lg={12} className="d-flex flex-column gap-4">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="link"
                  className="text-danger px-3 my-0"
                  onClick={handleModalHide}
                >
                  취소
                </Button>
                <Button type="submit" variant="primary" className="my-0">
                  {feeder ? '수정' : '저장'}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0"></Modal.Footer>
      </Modal>
      <DaumPostModal
        show={showPostcode}
        handleClose={() => setShowPostcode(false)}
        handleComplete={handleAddressComplete}
      />
      <InfoModal
        show={showSuccessModal}
        title="성공"
        body={updateMode ? '급식소 수정 완료' : '급식소 등록 완료'}
        handleClose={handleSuccessModalClose}
      />
    </div>
  );
};

export default FeederFormModal;
