import Button from 'components/base/Button';
import TinymceEditor from 'components/base/TinymceEditor';
import { Col, Form, Row } from 'react-bootstrap';
import React, { ChangeEvent, useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Notice } from '../../data/notice';
import axiosInstance, { refreshToken } from "../../helpers/apiService";
import axios from "axios";
import InfoModal from "../../components/modals/InfoModal";
import { AttachFile } from "../manage/ReportForm";

const NoticeForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [updateMode, setUpdateMode] = useState(false);
  const [formState, setFormState] = useState<Notice>({
    id: '',
    title: '',
    content: '',
    pinned: 'F',
    number: 0,
    views: 0,
    modifiedDate: '',
    fileList: [],
    addFileList: [],
    deleteFileList: []
  });

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);

  useEffect(() => {
    if (location.state?.notice) {
      setUpdateMode(true);
      const noticeData = location.state?.notice;
      setFormState(noticeData);
    }
  }, [location.state]);

  useEffect(() => {
    if (fileUploadSuccess) {
      saveNotice();
      setFileUploadSuccess(false);
    }
  }, [fileUploadSuccess]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleEditorChange = (content: string) => {
    setFormState(prevState => ({
      ...prevState,
      content: content
    }));
  };

  const handleTogglePinned = () => {
    setFormState(prevState => ({
      ...prevState,
      pinned: prevState.pinned === 'T' ? 'F' : 'T'
    }));
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalTitle === '성공') {
      navigate('/notice');
    }
  };

  const handleFileUpload = async (): Promise<boolean> => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('files', file);
    });

    if (updateMode) {
      formState.fileList!.map((file, index) => {
        formData.append(`deleteFileList[${index}]`, file.id);
      });

      try {
        await axiosInstance.post('api/file-delete', formData);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, handleFileUpload);
          } else {
            console.error('파일 삭제 에러:', error);
          }
        } else {
          console.error('파일 삭제 에러:', error);
        }
        return false;
      }
    }

    try {
      const response = await axiosInstance.post('api/file-upload', formData);
      const filesData: AttachFile[] = response.data.response;

      setFormState(prevState => ({
        ...prevState,
        addFileList: [
          ...(prevState.addFileList || []),
          ...filesData.map(file => file.id)
        ]
      }));

      setFileUploadSuccess(true);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, handleFileUpload);
        } else {
          console.error('파일 업로드 에러:', error);
        }
      } else {
        console.error('파일 업로드 에러:', error);
      }
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { title, content } = formState;

    if ((title ?? '').trim() === '' || (content ?? '').trim() === '') {
      setModalTitle('오류');
      setModalBody('제목과 내용은 필수입니다');
      setShowModal(true);
      return;
    }

    if (selectedFiles.length > 0 || (formState.fileList?.length ?? 0) > 0) {
      const uploadSuccess = await handleFileUpload();
      if (!uploadSuccess) {
        console.error('파일 업로드에 실패하여 급식소 저장을 중단합니다.');
        return;
      }
    } else {
      await saveNotice();
    }
  };

  const saveNotice = async () => {
    const formData = new FormData();

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/notice/${formState.id}`, formData);
      } else {
        await axiosInstance.post('api/notice', formData);
      }

      setModalTitle('성공');
      setModalBody(updateMode ? '공지사항 수정 완료' : '공지사항 등록 완료');
      setShowModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveNotice);
        } else {
          console.error('공지사항 저장 에러:', error);
        }
      } else {
        console.error('공지사항 저장 에러:', error);
      }
    }
  };

  return (
    <div>
      <div className="mb-5"></div>
      <form className="mb-5" onSubmit={handleSubmit}>
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <div className="d-flex flex-wrap gap-3 flex-between-end mb-5">
              <div>
                <h2 className="mb-2">
                  {updateMode ? '공지사항 수정' : '공지사항 등록'}
                </h2>
              </div>
              <div className="d-flex flex-wrap gap-2">
                <Button
                  variant="phoenix-secondary"
                  type="button"
                  onClick={handleTogglePinned}
                >
                  고정{' '}
                  {formState.pinned === 'T' ? (
                    <FeatherIcon
                      icon="check"
                      size={15}
                      className={'text-success'}
                    />
                  ) : (
                    // <span style={{ width: 15, display: 'inline-block' }}></span>
                    ''
                  )}
                </Button>
                <Button
                  variant="phoenix-danger"
                  onClick={() => navigate('/notice')}
                >
                  취소
                </Button>
                <Button variant="primary" type="submit">
                  {updateMode ? '수정' : '등록'}
                </Button>
              </div>
            </div>
            <h4 className="mb-3">제목</h4>
            <Form.Control
              id="title"
              placeholder="제목을 입력해 주세요"
              className="mb-5"
              value={formState.title}
              onChange={handleChange}
            />
            <div className="mb-6">
              <h4 className="mb-3">내용</h4>
              <TinymceEditor
                options={{
                  height: '30rem',
                  placeholder: '내용을 입력해 주세요'
                }}
                value={formState.content}
                onChange={handleEditorChange}
              />
            </div>
            {/*<div className="mb-5">*/}
            {/*  <h4 className="mb-3">Display images</h4>*/}
            {/*  <Dropzone*/}
            {/*    className="mb-3"*/}
            {/*    accept={{*/}
            {/*      'image/*': ['.png', '.gif', '.jpeg', '.jpg']*/}
            {/*    }}*/}
            {/*  />*/}
            {/*</div>*/}
          </Col>
        </Row>
      </form>
      <InfoModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default NoticeForm;
