import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { ChangeEvent, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Tnr } from '../../../data/tnr';

const TrapSchedulesTopSection = () => {
  useNavigate();
  const { setGlobalFilter, getPrePaginationRowModel, getColumn } =
    useAdvanceTableContext<Tnr>();

  const handleFilterItemClick = (columnId: string, value: string) => {
    const column = getColumn(columnId);
    column?.setFilterValue(value === 'all' ? '' : value);
  };

  const tabItems: FilterTabItem[] = useMemo(() => {
    const getDataCount = (label: string) =>
      getPrePaginationRowModel().rows.filter(
        ({ original }) => original.tnrStatus === label
      ).length;

    return [
      {
        label: '전체',
        value: 'all',
        onClick: () => handleFilterItemClick('tnrStatus', 'all'),
        count: getPrePaginationRowModel().rows.length
      },
      {
        label: '신청완료',
        value: 'submission',
        onClick: () => handleFilterItemClick('tnrStatus', 'submission'),
        count: getDataCount('submission')
      },
      {
        label: '포획완료',
        value: 'trap',
        onClick: () => handleFilterItemClick('tnrStatus', 'trap'),
        count: getDataCount('trap')
      },
      {
        label: '중성화완료',
        value: 'neuter',
        onClick: () => handleFilterItemClick('tnrStatus', 'neuter'),
        count: getDataCount('neuter')
      },
      {
        label: '방사완료',
        value: 'return',
        onClick: () => handleFilterItemClick('tnrStatus', 'return'),
        count: getDataCount('return')
      }
    ];
  }, [getPrePaginationRowModel]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <Row className="g-3 justify-content-between align-items-center mb-4">
      <Col xs={12} sm="auto">
        <FilterTab tabItems={tabItems} />
      </Col>
      <Col xs={12} sm="auto">
        <div className="d-flex align-items-center gap-1">
          <SearchBox
            onChange={handleSearchInputChange}
            placeholder="검색"
            style={{ width: '100%' }}
            // style={{ maxWidth: '30rem' }}
            // className="me-3"
          />
        </div>
      </Col>
    </Row>
  );
};

export default TrapSchedulesTopSection;
