import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useWizardFormContext } from '../../providers/WizardFormProvider';
import { Nav } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TnrWizardNavItem = ({
  icon,
  label,
  step,
  disabled
}: {
  icon: IconProp;
  label: string;
  step: number;
  disabled: boolean;
}) => {
  const { selectedStep, totalStep } = useWizardFormContext();

  return (
    <Nav.Item as="li" className="nav-item">
      <Nav.Link
        className={classNames('fw-semibold', {
          done: selectedStep > step && step !== totalStep,
          complete: selectedStep > step && step !== totalStep - 1
        })}
        eventKey={step}
        disabled={disabled}
      >
        <div className="text-center d-inline-block">
          <span className="nav-item-circle-parent">
            <span className="nav-item-circle">
              <FontAwesomeIcon icon={icon} />
            </span>
          </span>
          <span className="d-none d-md-block mt-1 fs-9">{label}</span>
        </div>
      </Nav.Link>
    </Nav.Item>
  );
};

export default TnrWizardNavItem;
