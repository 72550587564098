import SignInForm from 'components/forms/SignInForm';
import SignInLayout from 'layouts/SignInLayout';
import bg from 'assets/img/bg/bg.png';

const SignIn = () => {
  return (
    <SignInLayout bg={bg} className="pb-md-7">
      <SignInForm />
    </SignInLayout>
  );
};

export default SignIn;
