import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Badge from 'components/base/Badge';
import { Tnr } from '../../data/tnr';
import React, { useState } from 'react';
import TnrFormModal from '../modals/TnrFormModal';

export const tnrListTableColumns: ColumnDef<Tnr>[] = [
  {
    id: 'submissionAddress',
    accessorKey: 'submissionAddress',
    header: '신청 장소',
    cell: ({ row: { original } }) => {
      const [openTnrModal, setOpenTnrModal] = useState(false);

      return (
        <>
          <Link
            to=""
            state={{ tnr: original }}
            className="text-decoration-none fw-bold fs-8"
            onClick={() => {
              setOpenTnrModal(true);
            }}
          >
            {/*{`TNR 신청 - ${original.feeder?.name}`}*/}
            {original.submissionAddress ?? ''}
          </Link>
          <TnrFormModal
            show={openTnrModal}
            handleClose={() => setOpenTnrModal(false)}
            tnr={original}
          />
        </>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-4' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    id: 'manager',
    header: '신청인',
    cell: ({ row: { original } }) => {
      const { user } = original;
      return <>{user!.nick}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '신청 일자',
    accessorKey: 'createdDate',
    cell: ({ row: { original } }) => {
      return <>{original.createdDate!.split('T')[0]}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '포획 일자',
    accessorKey: 'trapDate',
    cell: ({ row: { original } }) => {
      return <>{(original.trapDate ?? '').split('T')[0]}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '중성화 일자',
    accessorKey: 'neuterDate',
    cell: ({ row: { original } }) => {
      return <>{(original.neuterDate ?? '').split('T')[0]}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '방사 일자',
    accessorKey: 'returnDate',
    cell: ({ row: { original } }) => {
      return <>{(original.returnDate ?? '').split('T')[0]}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    id: 'tnrStatus',
    header: '상태',
    accessorFn: ({ tnrStatus }) => tnrStatus,
    cell: ({ row: { original } }) => {
      const { trapDate, neuterDate, returnDate } = original;
      if (!trapDate && !neuterDate && !returnDate) {
        return (
          <Badge variant="phoenix" bg={'info'}>
            신청완료
          </Badge>
        );
      }

      if (trapDate && !neuterDate && !returnDate) {
        return (
          <Badge variant="phoenix" bg={'secondary'}>
            포획완료
          </Badge>
        );
      }

      if (trapDate && neuterDate && !returnDate) {
        return (
          <Badge variant="phoenix" bg={'primary'}>
            중성화완료
          </Badge>
        );
      }

      if (trapDate && neuterDate && returnDate) {
        return (
          <Badge variant="phoenix" bg={'success'}>
            방사완료
          </Badge>
        );
      }
    },
    meta: {
      cellProps: { className: 'ps-8 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  }
];

const TnrTable = () => {
  return (
    <div className="border-translucent">
      <div>
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table border-top border-translucent fs-9'
          }}
        />
      </div>
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default TnrTable;
