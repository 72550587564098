import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ReportTopSection from '../../components/modules/manage/ReportTopSection';
import { Notice } from '../../data/notice';
import { Spinner } from 'react-bootstrap';
import NoticeTable from '../../components/tables/NoticeTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { noticeListTableColumns } from '../../components/tables/NoticeTable';

interface NoticeWithPinned extends Notice {
  isPinned: boolean;
}

const NoticeList = () => {
  const navigate = useNavigate();
  const [notices, setNotices] = useState<Notice[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getNotices = async () => {
      try {
        const response = await axiosInstance.get('api/notice');
        setNotices(response.data.response.content);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getNotices);
          } else {
            console.error('서버 에러', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getNotices();
  }, []);

  const pinnedNotices = notices
    .filter(notice => notice.pinned === 'T')
    .map(notice => ({ ...notice, isPinned: true }));
  const nonPinnedNotices = notices.map(notice => ({
    ...notice,
    isPinned: false
  }));
  const tableData = [...pinnedNotices, ...nonPinnedNotices];

  const table = useAdvanceTable<NoticeWithPinned>({
    data: tableData,
    columns: noticeListTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-5"></div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center justify-content-between">
          <h2 className="mb-0">
            <span className="me-3">공지사항 목록</span>{' '}
            {/*<span className="fw-normal text-body-tertiary">*/}
            {/*  ({notices.length})*/}
            {/*</span>*/}
          </h2>
          <Link className="btn btn-primary px-5" to="/notice-form">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            공지사항 추가
          </Link>
        </div>

        <ReportTopSection />
        <NoticeTable />
      </AdvanceTableProvider>
    </div>
  );
};

export default NoticeList;
