import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useEffect, useState } from 'react';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { feederListTableColumns } from 'components/tables/FeederTable';
import CardViewItem from 'components/modules/manage/CardViewItem';
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Feeder } from 'data/feeder';
import FeederTopSection from 'components/modules/manage/FeederTopSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import AdvanceTableFooter from '../../components/base/AdvanceTableFooter';
import FeederFormModal from "../../components/modals/FeederFormModal";

const Feeders = () => {
  const navigate = useNavigate();
  const [feeders, setFeeder] = useState<Feeder[]>([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const getFeeders = async () => {
      try {
        const response = await axiosInstance.get('api/feeders');
        setFeeder(response.data.response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getFeeders);
          } else {
            console.error('Login error:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getFeeders();
  }, []);

  const table = useAdvanceTable<Feeder>({
    data: feeders,
    columns: feederListTableColumns,
    pageSize: 8,
    pagination: true,
    sortable: true
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div>
      {/*<PageBreadcrumb items={defaultBreadcrumbItems} />*/}
      <div className="mb-5"></div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center justify-content-between">
          <h2 className="mb-0">
            <span className="me-3">급식소 목록</span>{' '}
            {/*<span className="fw-normal text-body-tertiary">*/}
            {/*  ({feeders.length})*/}
            {/*</span>*/}
          </h2>
          {/*<Link className="btn btn-primary px-5" to="/feeder-form">*/}
          {/*  <FontAwesomeIcon icon={faPlus} className="me-2" />*/}
          {/*  급식소 추가*/}
          {/*</Link>*/}
          <Button
            className="btn btn-primary px-5"
            onClick={() => setOpenModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            급식소 추가
          </Button>
        </div>
        <FeederTopSection feederList={feeders} />
        {/*<FeederTable />*/}
        <Row className="g-3 mb-9">
          {table
            .getRowModel()
            .rows.map(row => row.original)
            .map(feeder => (
              <Col xs={12} sm={6} xl={4} xxl={3} key={feeder.id}>
                <CardViewItem feeder={feeder} />
              </Col>
            ))}
        </Row>
        <AdvanceTableFooter pagination className="py-3" />
      </AdvanceTableProvider>

      <FeederFormModal
        show={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </div>
  );
};

export default Feeders;
