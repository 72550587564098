import { Modal, Button } from 'react-bootstrap';
import React from 'react';

interface ModalProps {
  show: boolean;
  title: string;
  body: string;
  handleCancel: () => void;
  handleSubmit: () => void;
}

const DeleteModal: React.FC<ModalProps> = ({
  show,
  title,
  body,
  handleCancel,
  handleSubmit
}) => {
  return (
    <Modal
      show={show}
      centered
      backdrop="static"
      onHide={handleCancel}
      className="modal-open"
      contentClassName="bg-body-highlight p-6 border border-translucent"
    >
      <Modal.Header className="border-0 p-0 mb-2">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ whiteSpace: 'pre-line' }} className="px-0 mb-6">
        {body}
      </Modal.Body>
      <Modal.Footer className="border-0 p-0">
        <Button variant="danger" onClick={handleCancel}>
          취소
        </Button>
        <Button variant="secondary" onClick={handleSubmit}>
          확인
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
