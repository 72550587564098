import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft
} from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import ScrollSpy from 'components/base/ScrollSpy';
import FeederDetailsReportTab from '../../components/feeder/FeederDetailsReportTab';
import { User } from '../../data/users';
import { Feeder } from '../../data/feeder';
import FeederDetailsUserTab from '../../components/feeder/FeederDetailsUserTab';
import FeederDetailsProfileCard from '../../components/feeder/FeederDetailsProfileCard';
import FeederInfoCard from '../../components/cards/FeederInfoCard';
import FeederFormModal from '../../components/modals/FeederFormModal';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import InfoModal from '../../components/modals/InfoModal';

const FeederDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<User>();
  const [feeder, setFeeder] = useState<Feeder>();
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [openFeederFormModal, setOpenFeederFormModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    if (location.state?.user) {
      const user = location.state.user;
      setUser(user);
    }

    if (location.state?.feeder) {
      const feeder = location.state.feeder;
      setFeeder(feeder);
    }
  }, [location.state]);

  const handleDelete = async () => {
    setLoading(true);
    deleteFeeder();
  };

  const deleteFeeder = async () => {
    try {
      await axiosInstance.delete(`api/feeders/${feeder!.id}`);
      setShowSuccessModal(true);
      setLoading(false);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, deleteFeeder);
        } else {
          console.error('Feeder 삭제 에러:', error);
          setLoading(false);
        }
      } else {
        console.error('Feeder 삭제 에러:', error);
        setLoading(false);
      }
    }
  };

  const handleSuccessModalClose = () => {
    setDeleteOpenModal(false);
    setShowSuccessModal(false);
    navigate('/feeders', { replace: true });
  };

  return (
    <div>
      <div className="mb-5"></div>
      <div className="mb-9">
        <Row className="align-items-center justify-content-between g-3 mb-4">
          <Col md="auto">
            <h2 className="mb-0">급식소 상세</h2>
          </Col>
          <Col md="auto" className="d-flex">
            <Button
              variant="phoenix-primary"
              // className="me-2"
              startIcon={
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
              }
              onClick={() => navigate('/feeders')}
            >
              <span>뒤로가기</span>
            </Button>
            {/*<Button*/}
            {/*  variant="phoenix-secondary"*/}
            {/*  className="me-2"*/}
            {/*  startIcon={<FontAwesomeIcon icon={faEdit} className="me-2" />}*/}
            {/*  onClick={() => setOpenFeederFormModal(true)}*/}
            {/*>*/}
            {/*  <span>수정</span>*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  variant="phoenix-danger"*/}
            {/*  startIcon={<FontAwesomeIcon icon={faTrash} className="me-2" />}*/}
            {/*  onClick={() => setDeleteOpenModal(true)}*/}
            {/*>*/}
            {/*  <span>삭제</span>*/}
            {/*</Button>*/}
          </Col>
        </Row>
        <Row className="g-4 g-xl-6">
          <Col xl={5} xxl={4}>
            <div className="sticky-leads-sidebar">
              <FeederDetailsProfileCard className="mb-3" feeder={feeder} />
              {/*<DealsOtherInformation />*/}
              <FeederInfoCard className="mb-3" feeder={feeder} />
            </div>
          </Col>
          <Col xl={7} xxl={8}>
            <div className="lead-details-container">
              <ScrollSpy>
                {/*<FeederDetailsNavbar />*/}
                <ScrollSpy.Content
                  id="users"
                  className="lead-details-scrollspy mb-8"
                >
                  {feeder && <FeederDetailsUserTab feeder={feeder} />}
                </ScrollSpy.Content>
              </ScrollSpy>
              <ScrollSpy>
                <ScrollSpy.Content
                  id="reports"
                  className="lead-details-scrollspy mb-8"
                >
                  {feeder && <FeederDetailsReportTab feederId={feeder.id} />}
                </ScrollSpy.Content>
              </ScrollSpy>
            </div>
          </Col>
        </Row>
        <FeederFormModal
          show={openFeederFormModal}
          handleClose={() => setOpenFeederFormModal(false)}
          feeder={feeder}
        />
      </div>
      <Modal
        show={openDeleteModal}
        centered
        backdrop="static"
        onHide={() => setDeleteOpenModal(false)}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">급식소 삭제</h3>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <span className="text-primary">{feeder?.name}</span> 급식소를 정말로
          삭제하시겠습니까?
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-primary px-3 my-0"
            onClick={() => setDeleteOpenModal(false)}
            disabled={loading}
          >
            취소
          </Button>
          <Button
            variant="danger"
            className="my-0"
            loading={loading}
            loadingPosition={'start'}
            onClick={handleDelete}
          >
            삭제
          </Button>
        </Modal.Footer>
      </Modal>
      <InfoModal
        show={showSuccessModal}
        title="성공"
        body={'급식소 삭제 성공'}
        handleClose={handleSuccessModalClose}
      />
    </div>
  );
};

export default FeederDetails;
