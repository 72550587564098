import classNames from "classnames";
import Logo from "components/common/Logo";
import { PropsWithChildren } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UilCheckCircle } from "@iconscout/react-unicons";
import Unicon from "../components/base/Unicon";
import authIllustrations from "assets/img/spot-illustrations/auth.png";
import authIllustrationsDark from "assets/img/spot-illustrations/auth-dark.png";

interface AuthSimpleLayoutProps {
  logo?: boolean;
  className?: string;
  bg?: string;
}

const SignLayout = ({
                      logo = true,
                      // className = 'col-xl-5 col-xxl-3',
                      className,
                      bg,
                      children
                    }: PropsWithChildren<AuthSimpleLayoutProps>) => {
  return (
    // <div className="container">
    //   <Row className="flex-center min-vh-100 py-5">
    //     <Col sm={10} md={8} lg={5} className={classNames(className)}>
    //       {logo && (
    //         <Link
    //           to="/"
    //           className="d-flex flex-center text-decoration-none mb-4"
    //         >
    //           <Logo
    //             text={false}
    //             width={250}
    //             className="fw-bolder fs-5 d-inline-block"
    //           />
    //         </Link>
    //       )}
    //       {children}
    //     </Col>
    //   </Row>
    // </div>
    // <Row className="vh-100 g-0">
    //   <Col lg={6} className="position-relative d-none d-lg-block">
    //     <div
    //       className="bg-holder"
    //       style={{
    //         backgroundImage: `url(${bg})`
    //       }}
    //     />
    //   </Col>
    //   <Col lg={6}>
    //     <Row className="flex-center h-100 g-0 px-4 px-sm-0">
    //       <Col sm={6} lg={7} xl={6}>
    //         {logo && (
    //           <div className="text-center">
    //             {/*<Link*/}
    //             {/*  to="/"*/}
    //             {/*  className="d-inline-block text-decoration-none mb-4"*/}
    //             {/*>*/}
    //             <div className={'d-inline-block text-decoration-none mb-4'}>
    //               <Logo
    //                 text={false}
    //                 width={300}
    //                 className="fw-bolder fs-5 d-inline-block"
    //               />
    //             </div>
    //             {/*</Link>*/}
    //           </div>
    //         )}
    //         {children}
    //       </Col>
    //     </Row>
    //   </Col>
    // </Row>
    <Container fluid className="bg-body-tertiary dark__bg-gray-1200">
      <div
        className="bg-holder bg-auth-card-overlay"
        style={{ backgroundImage: `url(${bg})` }}
      />

      <Row className="flex-center position-relative min-vh-100 g-0 py-5">
        <Col xs={11} sm={10} xl={8}>
          {/*<Card className="border border-translucent auth-card">*/}
          <Card className="border border-translucent auth-card" style={{ backgroundColor: "#ffffff91" }}>
            <Card.Body className="pe-md-0">
              <Row className="align-items-center gx-0 gy-7">
                {/*<Col*/}
                {/*  xs="auto"*/}
                {/*  className="bg-body-highlight dark__bg-gray-1100 rounded-3 position-relative overflow-hidden auth-title-box"*/}
                {/*>*/}
                <Col
                  xs="auto"
                  className="dark__bg-gray-1100 rounded-3 position-relative overflow-hidden auth-title-box"
                  style={{ backgroundColor: '#ffffff54' }}
                >
                  {/*<div*/}
                  {/*  className="bg-holder"*/}
                  {/*  style={{ backgroundImage: `url(${bg})` }}*/}
                  {/*/>*/}
                  <div
                    className={classNames(
                      className,
                      "position-relative px-4 px-lg-7 py-7 pb-sm-5 text-center text-md-start pb-lg-7"
                    )}
                  >
                    <h3 className="mb-3 text-body-emphasis fs-7">
                      길고양이 통합관리 플랫폼
                    </h3>
                    <p className="text-body-tertiary">
                      길고양이 통합관리 플랫폼을 통해 길고양이 공공급식소를 체계적으로 관리하고, 중성화 사업에 손쉽게 참여하세요.
                      {/*길고양이와의 조화로운 공존을 위해 여러분의 도움이 필요합니다.*/}
                    </p>
                    <ul className="list-unstyled mb-0 w-max-content w-md-auto mx-auto">
                      <li className="d-flex align-items-center gap-2">
                        <Unicon
                          icon={UilCheckCircle}
                          className="text-success"
                          size={16}
                        />
                        <span className="text-body-tertiary fw-semibold">
                          중성화
                        </span>
                      </li>
                      <li className="d-flex align-items-center gap-2">
                        <Unicon
                          icon={UilCheckCircle}
                          className="text-success"
                          size={16}
                        />
                        <span className="text-body-tertiary fw-semibold">
                          공공급식소
                        </span>
                      </li>
                      {/*<li className="d-flex align-items-center gap-2">*/}
                      {/*  <Unicon*/}
                      {/*    icon={UilCheckCircle}*/}
                      {/*    className="text-success"*/}
                      {/*    size={16}*/}
                      {/*  />*/}
                      {/*  <span className="text-body-tertiary fw-semibold">*/}
                      {/*    Responsive*/}
                      {/*  </span>*/}
                      {/*</li>*/}
                    </ul>
                  </div>
                  <div className="position-relative mb-6 d-none d-md-block text-center mt-md-15 z-n1">
                    <img
                      className="auth-title-box-img d-dark-none"
                      src={authIllustrations}
                      alt=""
                    />
                    <img
                      className="auth-title-box-img d-light-none"
                      src={authIllustrationsDark}
                      alt=""
                    />
                    {/*<div*/}
                    {/*  className="auth-title-box-img d-dark-none"*/}
                    {/*  style={{ width: '100%', height: '200px' }} // 원하는 높이 설정*/}
                    {/*/>*/}
                  </div>
                </Col>
                <Col className="mx-auto">
                  {/*{logo && (*/}
                  {/*  <div className="text-center">*/}
                  {/*    <div*/}
                  {/*      className={'d-inline-block text-decoration-none mb-4'}*/}
                  {/*    >*/}
                  {/*      <Logo*/}
                  {/*        text={false}*/}
                  {/*        width={300}*/}
                  {/*        className="fw-bolder fs-5 d-inline-block"*/}
                  {/*      />*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                  <div className="auth-form-box">{children}</div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SignLayout;
