import { useEffect, useRef, DependencyList } from 'react';

type EffectFunction = () => void | (() => void);

const useDidMountEffect = (func: EffectFunction, deps: DependencyList) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);
};

export default useDidMountEffect;
