import Button from 'components/base/Button';
import { Col, Modal, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import ReactSelect from '../base/ReactSelect';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import { User } from '../../data/users';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Feeder } from '../../data/feeder';
import { ActionMeta, MultiValue } from "react-select";
import InfoModal from './InfoModal';

interface UserListModalProps {
  show: boolean;
  handleClose: () => void;
  feeder: Feeder;
}

interface SelectOption {
  value: string;
  label: string;
}

const UserListModal = ({ show, handleClose, feeder }: UserListModalProps) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const [formState, setFormState] = useState<Feeder>({
    id: '',
    name: '',
    address: '',
    type: '',
    latitude: 0,
    longitude: 0,
    deleted: 'N',
    publicStatus: '',
    addUserList: [],
    deleteUserList: [],
    addFileList: [],
    deleteFileList: [],
    fileList: []
  });

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosInstance.get(
          `api/users?feederId=${feeder.id}&notInFeeder=true`
        );
        setUsers(
          response.data.response.filter(
            (user: User) => user.username !== 'admin'
          )
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getUsers);
          } else {
            console.error('통신에러:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    getUsers();
  }, []);

  useEffect(() => {
    setFormState(feeder);
  }, [feeder]);

  const handleSelectChange = (
    newValue: MultiValue<SelectOption>,
    actionMeta: ActionMeta<unknown>
  ) => {
    const selectedValues = newValue.map(option => option.value);
    setSelectedOptions(newValue as SelectOption[]);
    setFormState(prevState => ({
      ...prevState,
      addUserList: selectedValues
    }));
  };

  const assignUser = async () => {
    setLoading(true);

    const userId = localStorage.getItem('userId');
    formState.uptId = userId!;

    const formData = new FormData();

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      await axiosInstance.put(`api/feeders/${formState.id}`, formData);

      setLoading(false);
      setShowSuccessModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, assignUser);
        } else {
          console.error('통신에러:', error);
          setLoading(false);
        }
      } else {
        console.error('Unexpected error:', error);
        setLoading(false);
      }
    }
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate(0);
  };

  return (
    <div>
      <Modal
        // size="lg"
        show={show}
        centered
        backdrop="static"
        onHide={handleClose}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">관리자 배정</h3>
          {/*<Button*/}
          {/*  variant="phoenix-secondary"*/}
          {/*  className="ms-auto"*/}
          {/*  onClick={handleClose}*/}
          {/*  size="sm"*/}
          {/*>*/}
          {/*  <FontAwesomeIcon icon={faTimes} className="text-danger" />*/}
          {/*</Button>*/}
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row as="form" className="g-4">
            <Col lg={12} className="d-flex flex-column gap-4">
              <ReactSelect
                isMulti
                placeholder="관리자 선택(복수 선택 가능)"
                options={users.map(user => ({
                  value: user.id,
                  label: `${user.nick} (${user.username})`
                }))}
                classNames={{
                  control: () => 'py-3',
                  valueContainer: () => 'lh-1'
                }}
                onChange={(newValue, actionMeta) =>
                  handleSelectChange(
                    newValue as MultiValue<SelectOption>,
                    actionMeta
                  )
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={handleClose}
            disabled={loading}
          >
            취소
          </Button>
          <Button
            type="button"
            variant="primary"
            className="my-0"
            onClick={assignUser}
            loading={loading}
            loadingPosition={'start'}
          >
            배정
          </Button>
        </Modal.Footer>
      </Modal>
      <InfoModal
        show={showSuccessModal}
        title="성공"
        body={'관리자 배정 완료'}
        handleClose={handleSuccessModalClose}
      />
    </div>
  );
};

export default UserListModal;
