import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Trap } from '../../data/trap';
import { Link } from 'react-router-dom';

export const trapListTableColumns: ColumnDef<Trap>[] = [
  {
    header: '고유번호',
    accessorKey: 'uniqueNum',
    cell: ({ row: { original } }) => {
      const { uniqueNum } = original;
      return (
        <Link
          to="/trap-form"
          state={{ trap: original }}
          className="text-decoration-none fw-bold fs-8"
        >
          {uniqueNum}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 text-body' },
      headerProps: { style: { width: '12%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'owner',
    header: '소유자',
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '15%' } }
    }
  },
  {
    id: 'type',
    header: '공공/사설',
    accessorFn: ({ type }) => type,
    cell: ({ row: { original } }) => {
      const { type } = original;
      return <>{type === 'PRIVATE' ? '사설' : '공공'}</>;
    },
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '15%' } }
    }
  },
  {
    accessorKey: 'trapStatus',
    header: '상태',
    cell: ({ row: { original } }) => {
      const { trapStatus } = original;
      switch (trapStatus) {
        case 'RESERVED':
          return <>예약중</>;
        case 'AVAILABLE':
          return <>사용가능</>;
        case 'RENTED':
          return <>사용중</>;
        case 'RETURNING':
          return <>반납중</>;
      }
    },
    meta: {
      cellProps: { className: 'ps-3' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  }
];

const TrapTable = () => {
  return (
    <div className="border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default TrapTable;
