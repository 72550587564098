import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { faClipboard, faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { Report } from "../../data/report";
import Button from "../base/Button";
import { useState } from "react";
import ReportDetailModal from "../modals/ReportDetailModal";

const ReportCard = ({ report }: { report: Report }) => {
  const [openDetailsModal, setOpenDetailsModal] = useState(false);

  return (
    <>
      <Card>
        <Card.Body>
          <div className="border-bottom border-translucent">
            <div className="d-flex align-items-start mb-1">
              <FontAwesomeIcon
                icon={faClipboard}
                className="text-primary-dark"
                width={16}
                height={16}
              />
              <div className="d-sm-flex align-items-center ps-3">
                <Link
                  to=""
                  className="fw-bold fs-7 lh-sm line-clamp-1 me-sm-4"
                  onClick={() => setOpenDetailsModal(true)}
                >
                  {report.title}
                </Link>
              </div>
            </div>
            <p className="fs-9 fw-semibold text-body ms-4 text mb-4 ps-2">
              {report.feeder?.name}
            </p>
          </div>
          <Row className="g-1 g-sm-3 mt-2 lh-1">
            <Col className="d-flex justify-content-end">
              <div className="d-flex align-items-center me-3">
                <FontAwesomeIcon
                  icon={faUserEdit}
                  className="me-2"
                  width={16}
                  height={16}
                />
                <p className="mb-0 fs-9 fw-semibold text-body-tertiary">
                  {report.user?.nick}
                </p>
              </div>
              <div className="d-flex align-items-center">
                <FeatherIcon
                  icon="clock"
                  className="me-2"
                  width={16}
                  height={16}
                />
                <p className="mb-0 fs-9 fw-semibold text-body-tertiary">
                  {report.modifiedDate.split("T")[0]}{" "}
                  {report.modifiedDate.split("T")[1].split(":")[0]} :{" "}
                  {report.modifiedDate.split("T")[1].split(":")[1]}
                </p>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ReportDetailModal
        show={openDetailsModal}
        handleClose={() => setOpenDetailsModal(false)}
        report={report}
      />
    </>
  );
};

export default ReportCard;
