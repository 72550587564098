import { Col, Row } from 'react-bootstrap';
import EcomTotalOrdersCard from 'components/cards/EcomTotalOrdersCard';
import EcomNewCustomersCard from 'components/cards/EcomNewCustomersCard';
import EcomTopCouponsCard from 'components/cards/EcomTopCouponsCard';
import EcomPayingVsNonPayingCard from 'components/cards/EcomPayingVsNonPayingCard';
import EcomStats from 'components/stats/EcomStats';
import EcomTotalSells from 'components/modules/e-commerce/dashboard/EcomTotalSells';
import EcomTopRegionsMap from 'components/leaflet-maps/EcomTopRegionsMap';
import comingSoonText from 'assets/img/spot-illustrations/40.png';
import 'style.css';
import { mapMarkerPoints } from '../../data/mapMarkerPoints';

const Dashboard = () => {
  return (
    <>
      {/*<div className="blur-overlay">*/}
      {/*  <img*/}
      {/*    src={comingSoonText}*/}
      {/*    alt=""*/}
      {/*    className="mb-5 w-75 w-lg-50 w-xl-75 w-xxl-100 d-dark-none"*/}
      {/*    style={{ maxWidth: 415 }}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className="pb-5 blur-content">*/}
      <div className="pb-5">
        {/*<Row className="g-4">*/}
        {/*  <Col xs={12} xxl={6}>*/}
        {/*    <div className="mb-8">*/}
        {/*      <h2 className="mb-2">대시보드</h2>*/}
        {/*      <h5 className="text-body-tertiary fw-semibold">*/}
        {/*        Here’s what’s going on at your business right now*/}
        {/*      </h5>*/}
        {/*    </div>*/}
        {/*    <EcomStats />*/}
        {/*    <hr className="bg-body-secondary mb-6 mt-4" />*/}
        {/*    <EcomTotalSells />*/}
        {/*  </Col>*/}
        {/*  <Col xs={12} xxl={6}>*/}
        {/*    <Row className="g-3">*/}
        {/*      <Col xs={12} md={6}>*/}
        {/*        <EcomTotalOrdersCard />*/}
        {/*      </Col>*/}
        {/*      <Col xs={12} md={6}>*/}
        {/*        <EcomNewCustomersCard />*/}
        {/*      </Col>*/}
        {/*      <Col xs={12} md={6}>*/}
        {/*        <EcomTopCouponsCard />*/}
        {/*      </Col>*/}
        {/*      <Col xs={12} md={6}>*/}
        {/*        <EcomPayingVsNonPayingCard />*/}
        {/*      </Col>*/}
        {/*    </Row>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
        <Row className="pb-5">
          <Col xs={12} xxl={6}>
            <h2 className="mb-1">길고양이 공공급식소 관리플랫폼</h2>
            <h3 className="mb-2 text-secondary-light">대시보드</h3>
          </Col>
        </Row>
        <Row className="gx-6 pb-5">
          <Col xs={12}>
            <div className="ms-xl-0 h-100">
              <div className="h-100 w-100" style={{ minHeight: 400 }}>
                <EcomTopRegionsMap data={mapMarkerPoints} />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="g-3">
          <Col xs={12} md={3}>
            <EcomTotalOrdersCard />
          </Col>
          <Col xs={12} md={3}>
            <EcomNewCustomersCard />
          </Col>
          <Col xs={12} md={3}>
            <EcomTopCouponsCard />
          </Col>
          <Col xs={12} md={3}>
            <EcomPayingVsNonPayingCard />
          </Col>
        </Row>
      </div>
      {/*<Row className="gx-6">*/}
      {/*  <Col xs={12}>*/}
      {/*    <div className="ms-xl-0 h-100">*/}
      {/*      <div className="h-100 w-100" style={{ minHeight: 500 }}>*/}
      {/*        <EcomTopRegionsMap data={mapMarkerPoints} />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Col>*/}
      {/*  <Row className="g-3">*/}
      {/*    <Col xs={12} md={3}>*/}
      {/*      <EcomTotalOrdersCard />*/}
      {/*    </Col>*/}
      {/*    <Col xs={12} md={3}>*/}
      {/*      <EcomNewCustomersCard />*/}
      {/*    </Col>*/}
      {/*    <Col xs={12} md={3}>*/}
      {/*      <EcomTopCouponsCard />*/}
      {/*    </Col>*/}
      {/*    <Col xs={12} md={3}>*/}
      {/*      <EcomPayingVsNonPayingCard />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</Row>*/}
      {/*<div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-6 pb-9 border-top">*/}
      {/*  <Row className="g-6">*/}
      {/*    <Col xs={12} xl={6}>*/}
      {/*      <EcomProjectionVsActual />*/}
      {/*    </Col>*/}
      {/*    <Col xs={12} xl={6}>*/}
      {/*      <EcomReturningCustomerRate />*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*</div>*/}
    </>
  );
};

export default Dashboard;
