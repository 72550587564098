import { Col, Modal, Row } from 'react-bootstrap';
import AvatarDropdown from 'components/common/AvatarDropdown';
import CoverImage from 'components/common/CoverImage';
import DatePicker from 'components/base/DatePicker';
import { Report } from '../../data/report';

interface ReportDetailModalProps {
  handleClose: () => void;
  show: boolean;
  report: Report;
}

const ReportDetailModal = ({
  handleClose,
  show,
  report
}: ReportDetailModalProps) => {
  return (
    <Modal show={show} onHide={handleClose} className="p-0">
      <Modal.Header className="position-relative p-0 overflow-hidden">
        <CoverImage handleClose={handleClose} reportImage={report.fileList!} />
      </Modal.Header>
      <Modal.Body className="p-5 px-md-6 pb-md-6">
        {/*<ActionSection /> */}
        <Row className="g-5">
          <Col xs={12} xl={8}>
            <div className="mb-4">
              <h3 className="fw-bolder lh-sm">{report.title}</h3>
              <p className="text-body-highlight fw-semibold mb-0">
                {report.feeder?.name}
              </p>
            </div>

            <div className="mb-5">
              <h5 className="text-body-secondary mb-2">내용</h5>
              <div className="d-flex gap-2 align-items-center">
                {report.content}
              </div>
            </div>

            <div className="mb-5">
              <h5 className="text-body-secondary mb-2">비고</h5>
              <div className="d-flex gap-2 align-items-center">
                {report.comment}
              </div>
            </div>

            <div className="mb-3">
              <h6 className="text-body-secondary mb-2">작성자</h6>
              <div className="d-flex gap-1">
                <AvatarDropdown user={report.user!} size="m" key={report.id} />
              </div>
            </div>

            <h6 className="text-body-secondary mb-2">작성 일자</h6>
            <div className="mb-3">
              <div className="w-60">
                <DatePicker
                  placeholder="Set the due date"
                  // options={{
                  //   defaultDate: 'May 4, 2013'
                  // }}
                  value={new Date(report.modifiedDate)}
                  disabled
                />
              </div>
            </div>
          </Col>

          <Col xs={12} xl={4}>
            <h5 className="text-body-secondary mb-3">고양이 현황</h5>
            <div className="mb-6 d-flex flex-column gap-2">
              <h6 className="rounded-2 bg-gray-50 p-2 text-body-secondary text-center">
                수컷 : {report.male}마리
              </h6>
              <h6 className="rounded-2 bg-gray-50 p-2 text-body-secondary text-center">
                암컷 : {report.female}마리
              </h6>
              <h6 className="rounded-2 bg-gray-50 p-2 text-body-secondary text-center">
                중성화 : {report.tnred}마리
              </h6>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ReportDetailModal;
