import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import React, { ChangeEvent, useEffect, useState } from 'react';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import InfoModal from './InfoModal';
import { Report } from '../../data/report';
import CustomDropzone from "../base/CustomDropzone";

export interface AttachFile {
  id: string;
  originFileName: string;
  savedFileName: string;
  path: string;
  extension: string;
}

interface AddDealModalProps {
  show: boolean;
  handleClose: () => void;
  report?: Report;
  feederId?: string;
}

const ReportFormModal = ({
  show,
  handleClose,
  report,
  feederId
}: AddDealModalProps) => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<Report>({
    id: '',
    male: 0,
    female: 0,
    tnred: 0,
    title: '',
    content: '',
    comment: '',
    modifiedDate: '',
    feederId: '',
    fileList: [],
    addFileList: [],
    deleteFileList: []
  });

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);

  const [errors, setErrors] = useState({
    title: '',
    content: '',
    comment: '',
    male: '',
    female: '',
    tnred: ''
  });

  const cats: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const contents: string[] = ['주변 청결 상태 양호', '사료 재고 확인 필요'];

  useEffect(() => {
    if (feederId != null) {
      formState.feederId = feederId;
    }
  }, [feederId]);

  useEffect(() => {
    if (report) {
      setUpdateMode(true);
      setFormState(report);

      // 파일 목록을 Dropzone에 추가
      const getFiles = async () => {
        const files = await Promise.all(
          report.fileList?.map(async (file: AttachFile) => {
            const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;
            try {
              const response = await axiosInstance.get(fileUrl, {
                responseType: 'blob',
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
              });

              const blob = response.data;
              return new File([blob], file.originFileName, { type: blob.type });
            } catch (error) {
              console.error('파일 로드 에러:', error);
              return null;
            }
          }) || []
        );
        setSelectedFiles(files.filter(file => file !== null) as File[]);
      };

      getFiles();
    }
  }, []);

  useEffect(() => {
    if (fileUploadSuccess) {
      saveReport();
      setFileUploadSuccess(false);
    }
  }, [fileUploadSuccess]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFiles([]);
    setSelectedFiles(prevFiles => [...prevFiles, acceptedFiles[0]]);
  };

  const handleRemoveFile = (file: File) => {
    setSelectedFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
  };

  const handleFileUpload = async (): Promise<boolean> => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('files', file);
    });

    if (updateMode) {
      formState.fileList!.map((file, index) => {
        formData.append(`deleteFileList[${index}]`, file.id);
      });

      try {
        await axiosInstance.post('api/file-delete', formData);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, handleFileUpload);
          } else {
            console.error('파일 삭제 에러:', error);
          }
        } else {
          console.error('파일 삭제 에러:', error);
        }
        return false;
      }
    }

    try {
      const response = await axiosInstance.post('api/file-upload', formData);
      const filesData: AttachFile[] = response.data.response;

      setFormState(prevState => ({
        ...prevState,
        addFileList: [
          ...(prevState.addFileList || []),
          ...filesData.map(file => file.id)
        ]
      }));

      setFileUploadSuccess(true);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, handleFileUpload);
        } else {
          console.error('파일 업로드 에러:', error);
        }
      } else {
        console.error('파일 업로드 에러:', error);
      }
      return false;
    }
  };

  const validateForm = () => {
    const newErrors = { ...errors };
    let isValid = true;

    if (!formState.title) {
      newErrors.title = '일지 제목을 입력해 주세요';
      isValid = false;
    } else {
      newErrors.title = '';
    }

    if (!formState.content) {
      newErrors.content = '내용을 선택해 주세요';
      isValid = false;
    } else {
      newErrors.content = '';
    }

    if (!formState.male) {
      newErrors.male = '선택해 주세요';
      isValid = false;
    } else {
      newErrors.male = '';
    }

    if (!formState.female) {
      newErrors.female = '선택해 주세요';
      isValid = false;
    } else {
      newErrors.female = '';
    }

    if (!formState.tnred) {
      newErrors.tnred = '선택해 주세요';
      isValid = false;
    } else {
      newErrors.tnred = '';
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (selectedFiles.length > 0 || formState.fileList!.length > 0) {
      const uploadSuccess = await handleFileUpload();
      if (!uploadSuccess) {
        console.error('파일 업로드에 실패하여 급식소 저장을 중단합니다.');
      }
    } else {
      await saveReport();
    }
  };

  const saveReport = async () => {
    const formData = new FormData();

    formData.append('userId', localStorage.getItem('userId')!);

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/reports/${formState.id}`, formData);
      } else {
        await axiosInstance.post('api/reports', formData);
      }

      setModalTitle('성공');
      setModalBody(updateMode ? '일지 수정 완료' : '일지 등록 완료');
      setShowModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveReport);
        } else {
          console.error('일지 저장 에러:', error);
        }
      } else {
        console.error('일지 저장 에러:', error);
      }
    }
  };

  // 초기화 함수 추가
  const resetForm = () => {
    setFormState({
      id: '',
      male: 0,
      female: 0,
      tnred: 0,
      title: '',
      content: '',
      comment: '',
      modifiedDate: '',
      feederId: feederId || '',
      fileList: [],
      addFileList: [],
      deleteFileList: []
    });
    setSelectedFiles([]);
    setErrors({
      title: '',
      content: '',
      comment: '',
      male: '',
      female: '',
      tnred: ''
    });
  };

  // handleClose 호출 시 resetForm도 실행
  const handleModalHide = () => {
    resetForm();
    handleClose();
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalTitle === '성공') {
      navigate(0);
    }
  };

  return (
    <div>
      <Modal
        // size="lg"
        show={show}
        centered
        backdrop="static"
        onHide={handleModalHide}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">{report ? '일지 수정' : '일지 등록'}</h3>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleModalHide}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row as="form" className="g-4" onSubmit={handleSubmit}>
            <Col lg={12} className="d-flex flex-column gap-4">
              <Form.Group>
                <Form.Label className="form-label-header mb-2">
                  제목 *
                </Form.Label>
                <Form.Control
                  id="title"
                  type="text"
                  placeholder="일지 제목"
                  value={formState.title}
                  onChange={handleChange}
                  isInvalid={!!errors.title}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label className="form-label-header mb-2">
                  내용 *
                </Form.Label>
                <Form.Select
                  id="content"
                  value={formState.content}
                  onChange={handleChange}
                  isInvalid={!!errors.content}
                >
                  <option>선택</option>
                  {contents.map((content, index) => (
                    <option key={index} value={content}>
                      {content}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.content}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={12} className="d-flex flex-column gap-4">
              <Row className="g-3">
                <Form.Group as={Col} lg={12} xl={4}>
                  <Form.Label className="form-label-header mb-2">
                    수컷 *
                  </Form.Label>
                  <Form.Select
                    id="male"
                    value={formState.male}
                    onChange={handleChange}
                    isInvalid={!!errors.male}
                  >
                    <option>선택</option>
                    {cats.map(catNum => (
                      <option key={catNum} value={catNum}>
                        {catNum}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.male}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} lg={12} xl={4}>
                  <Form.Label className="form-label-header mb-2">
                    암컷 *
                  </Form.Label>
                  <Form.Select
                    id="female"
                    value={formState.female}
                    onChange={handleChange}
                    isInvalid={!!errors.female}
                  >
                    <option>선택</option>
                    {cats.map(catNum => (
                      <option key={catNum} value={catNum}>
                        {catNum}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.female}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group as={Col} lg={12} xl={4}>
                  <Form.Label className="form-label-header mb-2">
                    중성화 *
                  </Form.Label>
                  <Form.Select
                    id="tnred"
                    value={formState.tnred}
                    onChange={handleChange}
                    isInvalid={!!errors.tnred}
                  >
                    <option>선택</option>
                    {cats.map(catNum => (
                      <option key={catNum} value={catNum}>
                        {catNum}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">
                    {errors.tnred}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Col>
            <Col lg={12} className="d-flex flex-column gap-4">
              <Form.Label className="form-label-header mb-2">
                첨부 사진
              </Form.Label>
              <CustomDropzone
                multiple={false}
                className="mb-3"
                accept={{
                  'image/*': ['.png', '.gif', '.jpeg', '.jpg']
                }}
                onDrop={handleDrop}
                onRemoveFile={handleRemoveFile}
                initialFiles={selectedFiles}
                pictureAmount={1}
              />
              {/*<Dropzone*/}
              {/*  accept={{*/}
              {/*    'image/*': ['.png', '.gif', '.jpeg', '.jpg']*/}
              {/*  }}*/}
              {/*  onDrop={handleDrop}*/}
              {/*  onRemoveFile={handleRemoveFile}*/}
              {/*  initialFiles={selectedFiles}*/}
              {/*/>*/}
            </Col>
            <Col lg={12} className="d-flex flex-column gap-4">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="link"
                  className="text-danger px-3 my-0"
                  onClick={handleModalHide}
                >
                  취소
                </Button>
                <Button type="submit" variant="primary" className="my-0">
                  {report ? '수정' : '저장'}
                </Button>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0"></Modal.Footer>
      </Modal>
      <InfoModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default ReportFormModal;
