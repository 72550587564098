import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, Tab } from 'react-bootstrap';
import MemberDetailsReport from './MemberDetailsReport';
import { dealNotes, meetingData, taskList } from 'data/crm/dealDetailsData';
import MemberDetailsNotes from './MemberDetailsNotes';
import MemberDetailsMeeting from './MemberDetailsMeeting';
import MemberDetailsTask from './MemberDetailsTask';
import MemberDetailsCall from './MemberDetailsCall';
import MemberDetailsAttachments from './MemberDetailsAttachments';
import { attachments } from 'data/project-management/todoListData';
import LeadEmails from '../modules/crm/LeadEmails';
import {
  faChartLine,
  faClipboard,
  faEnvelope,
  faPaperclip,
  faPhone,
  faSquareCheck,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { User } from '../../data/users';

interface TabItem {
  name: string;
  icon: IconProp;
  content: JSX.Element;
}

const MemberDetailsTab = ({ user }: { user: User }) => {
  const tabItems: TabItem[] = [
    {
      name: 'Report',
      icon: faChartLine,
      content: <MemberDetailsReport user={user} />
    },
    {
      name: 'Notes',
      icon: faClipboard,
      content: <MemberDetailsNotes notes={dealNotes} />
    },
    {
      name: 'Meeting',
      icon: faVideo,
      content: <MemberDetailsMeeting meetings={meetingData} />
    },
    {
      name: 'Task',
      icon: faSquareCheck,
      content: <MemberDetailsTask tasks={taskList} />
    },
    {
      name: 'Call',
      icon: faPhone,
      content: <MemberDetailsCall />
    },
    {
      name: 'Emails',
      icon: faEnvelope,
      content: <LeadEmails />
    },
    {
      name: 'Attachments',
      icon: faPaperclip,
      content: <MemberDetailsAttachments attachments={attachments} />
    }
  ];

  return (
    <Tab.Container defaultActiveKey="report">
      <Nav
        variant="underline"
        className="deal-details scrollbar flex-nowrap w-100 pb-1 mb-6"
      >
        {tabItems.map(item => (
          <Nav.Item key={item.name} className="me-2 text-nowrap">
            <Nav.Link eventKey={item.name.toLowerCase()}>
              <FontAwesomeIcon
                icon={item.icon}
                className="me-2 tab-icon-color"
              />
              {item.name}
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
      <Tab.Content>
        {tabItems.map(item => (
          <Tab.Pane key={item.name} eventKey={item.name.toLowerCase()}>
            {item.content}
          </Tab.Pane>
        ))}
      </Tab.Content>
    </Tab.Container>
  );
};

export default MemberDetailsTab;
