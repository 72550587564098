import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { ChangeEvent, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Trap } from '../../../data/trap';

const TrapTopSection = () => {
  useNavigate();
  const { setGlobalFilter, getPrePaginationRowModel, getColumn } =
    useAdvanceTableContext<Trap>();

  const handleFilterItemClick = (columnId: string, value: string) => {
    const column = getColumn(columnId);
    column?.setFilterValue(value === 'all' ? '' : value);
  };

  const tabItems: FilterTabItem[] = useMemo(() => {
    const getDataCount = (label: string) =>
      getPrePaginationRowModel().rows.filter(
        ({ original: { type } }) => type === label
      ).length;

    return [
      {
        label: '전체',
        value: 'all',
        onClick: () => handleFilterItemClick('type', 'all'),
        count: getPrePaginationRowModel().rows.length
      },
      {
        label: '공공급식소',
        value: 'public',
        onClick: () => handleFilterItemClick('type', 'public'),
        count: getDataCount('PUBLIC')
      },
      {
        label: '사설급식소',
        value: 'private',
        onClick: () => handleFilterItemClick('type', 'private'),
        count: getDataCount('PRIVATE')
      }
    ];
  }, []);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <Row className="g-3 justify-content-between align-items-center mb-4">
      <Col xs={12} sm="auto">
        <FilterTab tabItems={tabItems} />
      </Col>
      <Col xs={12} sm="auto">
        <div className="d-flex align-items-center gap-1">
          <SearchBox
            onChange={handleSearchInputChange}
            placeholder="검색"
            style={{ width: '100%' }}
            // style={{ maxWidth: '30rem' }}
            // className="me-3"
          />
        </div>
      </Col>
    </Row>
  );
};

export default TrapTopSection;
