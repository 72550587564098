import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import ActionTrapScheduleDropdownItems from 'components/dropdown/ActionTrapScheduleDropdownItems';
import Badge from 'components/base/Badge';
import { TrapSchedule } from 'data/trapSchedules';

export const trapScheduleListTableColumns: ColumnDef<TrapSchedule>[] = [
  {
    id: 'trapUniqueNum',
    header: '통덫 고유번호',
    cell: ({ row: { original } }) => {
      const { trap } = original;
      return <>{trap!.uniqueNum}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { className: 'ps-3' }
    }
  },
  {
    id: 'feederName',
    header: '급식소 이름',
    cell: ({ row: { original } }) => {
      const { feeder } = original;
      return (
        <Link
          to="/trap-schedule-form"
          state={{ trapSchedule: original }}
          className="text-decoration-none fw-bold fs-8 text-truncate d-inline-block"
          style={{ maxWidth: '150px' }}
        >
          {feeder?.name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap overflow-hidden text-truncate py-4' },
    }
  },
  {
    id: 'manager',
    header: '관리자',
    cell: ({ row: { original } }) => {
      const { user } = original;
      return <>{user!.nick}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '예약 일자',
    accessorKey: 'reservedDate',
    cell: ({ row: { original } }) => {
      const { reservedDate } = original;
      return <>{reservedDate.split('T')[0]}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '반납 일자',
    accessorKey: 'returnDate',
    cell: ({ row: { original } }) => {
      const { returnDate } = original;
      return <>{returnDate ? returnDate.split('T')[0] : ''}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
      headerProps: { style: { width: '15%' }, className: 'ps-3' }
    }
  },
  {
    id: 'trapStatus',
    header: '상태',
    accessorFn: ({ trap }) => trap?.trapStatus,
    cell: ({ row: { original } }) => {
      const { returnDate, trap } = original;
      if (returnDate) {
        return (
          <Badge variant="phoenix" bg={'success'}>
            반납완료
          </Badge>
        );
      }
      switch (trap?.trapStatus) {
        case 'RESERVED':
          return (
            <Badge variant="phoenix" bg={'warning'}>
              예약중
            </Badge>
          );
        case 'RENTED':
          return (
            <Badge variant="phoenix" bg={'primary'}>
              사용중
            </Badge>
          );
      }
    },
    meta: {
      cellProps: { className: 'ps-8 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  },
  {
    id: 'action',
    cell: ({ row: { original } }) => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionTrapScheduleDropdownItems trapSchedule={original} />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const TrapScheduleTable = () => {
  return (
    <div className="border-translucent">
      <div>
        <AdvanceTable
          tableProps={{
            className: 'phoenix-table border-top border-translucent fs-9'
          }}
        />
      </div>
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default TrapScheduleTable;
