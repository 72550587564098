import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import generic43 from 'assets/img/generic/43.webp';
import { AttachFile } from '../../data/attachFile';
import axiosInstance from '../../helpers/apiService';

const CoverImage = ({
  handleClose,
  reportImage
}: {
  handleClose: () => void;
  reportImage: AttachFile[];
}) => {
  const [image, setImage] = useState<File | null>(null);
  const [thumbnails, setThumbnails] = useState<string[]>([]);

  useEffect(() => {
    const fetchThumbnails = async () => {
      if (!reportImage) return;

      const urls = await Promise.all(
        reportImage.map(async file => {
          const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;
          try {
            const response = await axiosInstance.get(fileUrl, {
              responseType: 'blob',
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`
              }
            });

            return URL.createObjectURL(response.data);
          } catch (error) {
            console.error('파일 로드 에러:', error);
            return null;
          }
        })
      );

      setThumbnails(urls.filter((url): url is string => url !== null));
    };

    fetchThumbnails();
  }, [reportImage]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };

  return (
    <>
      <input
        type="file"
        id="projectCoverInput"
        className="d-none"
        onChange={handleChange}
      />
      <label
        className="position-absolute top-0 start-0"
        htmlFor="projectCoverInput"
      >
        {/*<span className="project-modal-btn d-inline-block bg-body-emphasis dark__text-gray-100 rounded-2 py-2 px-3 fs-9 fw-bolder mt-3 ms-3 cursor-pointer">*/}
        {/*  <FontAwesomeIcon icon={faImage} className="me-1" />*/}
        {/*  Change*/}
        {/*</span>*/}
      </label>

      <Button
        variant="circle"
        className="project-modal-btn position-absolute end-0 top-0 mt-3 me-3 bg-body-emphasis"
        onClick={handleClose}
      >
        <FontAwesomeIcon icon={faXmark} />
      </Button>
      <img
        src={image ? URL.createObjectURL(image) : thumbnails[0] || generic43}
        alt="cover"
        className="w-100"
        style={{ minHeight: 150, maxHeight: 280 }}
      />
    </>
  );
};

export default CoverImage;
