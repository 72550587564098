import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from 'components/base/Button';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import axiosInstance, { refreshToken } from 'helpers/apiService';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoModal from 'components/modals/InfoModal';
import { TrapSchedule } from '../../data/trapSchedules';
import DatePicker from '../../components/base/DatePicker';
import { Feeder } from '../../data/feeder';
import { Trap } from '../../data/trap';

// enum 정의
enum TrapStatus {
  RESERVED = '예약중',
  RENTED = '사용중',
  RETURNING = '반납중',
  AVAILABLE = '사용가능'
}

const TrapScheduleForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formState, setFormState] = useState<TrapSchedule>({
    id: '',
    reservedDate: '',
    feederId: '',
    trapId: '',
    owner: '',
    status: {
      label: '',
      type: 'primary'
    }
  });

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [updateMode, setUpdateMode] = useState(false);
  const [feeders, setFeeders] = useState<Feeder[]>([]);
  const [traps, setTraps] = useState<Trap[]>([]);
  const [selectedOwner, setSelectedOwner] = useState<string>('');
  const [selectedTrap, setSelectedTrap] = useState<Trap>();

  useEffect(() => {
    const getFeeders = async () => {
      try {
        const response = await axiosInstance.get('api/feeders');
        setFeeders(response.data.response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getFeeders);
          } else {
            console.error('통신에러:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    getFeeders();
  }, []);

  useEffect(() => {
    const getTraps = async () => {
      try {
        const response = await axiosInstance.get('api/traps');
        setTraps(response.data.response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getTraps);
          } else {
            console.error('Login error:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    getTraps();
  }, []);

  useEffect(() => {
    if (location.state?.trapSchedule) {
      setUpdateMode(true);
      const reportData = location.state?.trapSchedule;
      setFormState(prevState => ({
        ...prevState,
        id: reportData.id,
        reservedDate:
          reportData.reservedDate.split('T')[0] +
          ' ' +
          reportData.reservedDate.split('T')[1].split('.')[0],
        feederId: reportData.feeder.id,
        trapId: reportData.trap.id,
        status: prevState.status
      }));
      setSelectedOwner(reportData.trap.owner);
      setSelectedTrap(reportData.trap);
    }
  }, [location.state]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleOwnerChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setSelectedOwner(value);
    setFormState(prevState => ({
      ...prevState,
      trapId: '' // Reset the trapId when owner changes
    }));
  };

  const handleTrapChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const selectedTrap = traps.find(trap => trap.id === value);

    if (selectedTrap && selectedTrap.trapStatus === 'AVAILABLE') {
      setFormState(prevState => ({
        ...prevState,
        trapId: value
      }));
      setSelectedTrap(selectedTrap);
    } else {
      // '사용가능' 상태가 아닌 경우
      setFormState(prevState => ({
        ...prevState,
        trapId: ''
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { reservedDate } = formState;

    if (reservedDate.trim() === '') {
      setModalTitle('오류');
      setModalBody('*는 필수 입력값입니다');
      setShowModal(true);
      return;
    }

    await reserveTrap();
  };

  const saveTrapSchedule = async () => {
    const formData = new FormData();

    formData.append('userId', localStorage.getItem('userId')!);

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/schedules/${formState.id}`, formData);
      } else {
        await axiosInstance.post('api/schedules', formData);
      }
      // 급식소 등록 성공 후 모달 띄우기
      setModalTitle('성공');
      setModalBody(updateMode ? '통덫 예약 수정 완료' : '통덫 예약 등록 완료');
      setShowModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveTrapSchedule);
        } else {
          console.error('통덫 예약 저장 에러:', error);
        }
      } else {
        console.error('통덫 예약 저장 에러:', error);
      }
    }
  };

  const reserveTrap = async () => {
    const formData = new FormData();

    if (selectedTrap) {
      selectedTrap.isCancel = false;
      formData.append('id', selectedTrap.id);
      formData.append('uniqueNum', selectedTrap.uniqueNum);
      formData.append('owner', selectedTrap.owner);
      formData.append('type', selectedTrap.type);
      formData.append('trapStatus', 'RESERVED');
      formData.append('isCancel', selectedTrap.isCancel.toString());
    }

    try {
      await axiosInstance.put(`api/traps/${selectedTrap!.id}`, formData);
      await saveTrapSchedule(); // 예약 성공 시 스케줄 저장
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, reserveTrap);
        } else if (error.response?.status === 409) {
          setModalTitle('오류');
          setModalBody('이미 예약된 통덫입니다');
          setShowModal(true);
        } else {
          console.error('통덫 예약 상태변경 에러:', error);
        }
      } else {
        console.error('통덫 예약 상태변경 에러:', error);
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalTitle === '성공') {
      navigate('/trap-schedules');
    }
  };

  // 소유자 중복 제거 함수
  const getUniqueOwners = (traps: Trap[]): string[] => {
    const owners = traps.map(trap => trap.owner);
    return Array.from(new Set(owners));
  };

  const uniqueOwners = getUniqueOwners(traps);

  // 선택된 소유자의 '사용가능' 상태인 트랩 필터링
  const availableTraps = traps.filter(trap => trap.owner === selectedOwner);

  const handleDateChange = (date: Date[]) => {
    const kstDate = new Date(date[0].getTime() + 9 * 60 * 60 * 1000); // UTC+9로 변환
    setFormState(prevState => ({
      ...prevState,
      reservedDate:
        kstDate.toISOString().split('T')[0] +
        ' ' +
        kstDate.toISOString().split('T')[1].split('.')[0]
    }));
  };

  return (
    <div>
      {/*<PageBreadcrumb items={defaultBreadcrumbItems} />*/}
      <div className="mb-5"></div>
      <h2 className="mb-4">
        {updateMode ? '통덫 예약 수정' : '통덫 예약 등록'}
      </h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6" onSubmit={handleSubmit}>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="owner" label="소유자 *">
                <Form.Select onChange={handleOwnerChange} value={selectedOwner}>
                  <option>선택</option>
                  {uniqueOwners.map((owner, index) => (
                    <option key={index} value={owner}>
                      {owner}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="trapId" label="고유번호 *">
                <Form.Select
                  onChange={handleTrapChange}
                  value={formState.trapId}
                  disabled={!selectedOwner}
                >
                  <option>선택</option>
                  {availableTraps.map((trap, index) => (
                    <option key={index} value={trap.id}>
                      {trap.uniqueNum} (
                      {TrapStatus[trap.trapStatus as keyof typeof TrapStatus]})
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="feederId" label="급식소 *">
                <Form.Select onChange={handleChange} value={formState.feederId}>
                  <option>선택</option>
                  {feeders.map((feeder, index) => (
                    <option key={index} value={feeder.id}>
                      {feeder.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={6}>
              <DatePicker
                onChange={handleDateChange}
                value={
                  formState.reservedDate
                    ? new Date(formState.reservedDate)
                    : new Date()
                }
                render={(_, ref) => {
                  return (
                    <Form.Floating>
                      <Form.Control
                        type="text"
                        placeholder="예약 일자"
                        ref={ref}
                        id="reservedDate"
                        value={formState.reservedDate}
                        onChange={() => {}}
                      />
                      <label htmlFor="reservedDate" className="ps-6">
                        예약 일자
                      </label>
                    </Form.Floating>
                  );
                }}
              />
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="phoenix-primary"
                  className="px-5"
                  onClick={() => navigate('/trap-schedules')}
                >
                  취소
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="px-5 px-sm-15"
                >
                  {updateMode ? '수정' : '등록'}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <InfoModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default TrapScheduleForm;
