// jsonpHelper.js
export const jsonpRequest = (url, callbackName) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = `${url}&callback=${callbackName}`;
    script.onerror = () => {
      reject(new Error(`JSONP request to ${url} failed`));
    };
    window[callbackName] = data => {
      resolve(data);
      delete window[callbackName];
      document.body.removeChild(script);
    };
    document.body.appendChild(script);
  });
};
