import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface LeadInfoItemInterface {
  icon: IconProp;
  label: string;
  value?: string;
  className?: string;
}

const LeadInfoItem = ({
  className,
  label,
  value,
  icon,
  children
}: PropsWithChildren<LeadInfoItemInterface>) => {
  return (
    <div className={classNames(className)}>
      <div className="d-flex align-items-center mb-1">
        <FontAwesomeIcon
          icon={icon}
          size="1x"
          className="me-2"
        />
        <h5 className="text-body-highlight mb-0">{label}</h5>
      </div>
      {value && <p className="mb-0 text-body-secondary">{value}</p>}
      {children}
    </div>
  );
};

export default LeadInfoItem;
