import classNames from 'classnames';
import LeadInfoItem from 'components/info-items/LeadInfoItem';
import { Card } from 'react-bootstrap';
import { Feeder } from '../../data/feeder';
import {
  faLock,
  faSearch,
  faVenusMars
} from '@fortawesome/free-solid-svg-icons';
import { faGithubAlt } from '@fortawesome/free-brands-svg-icons';

const FeederInfoCard = ({
  className,
  feeder
}: {
  className?: string;
  feeder?: Feeder;
}) => {
  return (
    <Card className={classNames(className)}>
      <Card.Body>
        <div className="d-flex align-items-center mb-5">
          <h3>현황</h3>
        </div>
        <LeadInfoItem className="mb-4" label="고양이 개체수" icon={faVenusMars}>
          <p className="mb-0 text-body-primary">{feeder?.catCnt || ''}</p>
        </LeadInfoItem>
        <LeadInfoItem className="mb-4" label="중성화 개체수" icon={faGithubAlt}>
          <p className="mb-0 text-body-secondary">{feeder?.tnrCatCnt || ''}</p>
        </LeadInfoItem>

        <LeadInfoItem className="mb-4" label="공공/사설" icon={faLock}>
          <p className="mb-0 text-body-secondary">
            {feeder?.type === 'PRIVATE' ? '사설급식소' : '공공급식소'}
          </p>
        </LeadInfoItem>

        <LeadInfoItem className="mb-4" label="공개여부" icon={faSearch}>
          <p className="mb-0 text-body-secondary">
            {feeder?.publicStatus === 'ENABLED' ? '공개' : '공공급식소'}
          </p>
        </LeadInfoItem>
      </Card.Body>
    </Card>
  );
};

export default FeederInfoCard;
