import Button from 'components/base/Button';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import Avatar from '../../components/base/Avatar';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import InfoModal from '../../components/modals/InfoModal';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';

const MemberForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [updateMode, setUpdateMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [loading, setLoading] = useState(false); // 로딩 상태 추가

  const [formState, setFormState] = useState({
    username: '',
    nick: '',
    affiliation: '',
    password: '',
    confirmPassword: '',
    role: 'ROLE_USER',
    vendor: 'email'
  });

  const [errors, setErrors] = useState({
    username: '',
    nick: '',
    password: '',
    confirmPassword: ''
  });

  useEffect(() => {
    if (location.state?.user) {
      setUpdateMode(true);
      const userData = location.state?.user;
      setFormState(userData);
    }
  }, [location.state]);

  const validateForm = () => {
    const newErrors = { ...errors };
    let isValid = true;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formState.username) {
      newErrors.username = '이메일을 입력해 주세요.';
      isValid = false;
    } else if (!emailRegex.test(formState.username)) {
      newErrors.username = '유효한 이메일 형식을 입력해 주세요.';
      isValid = false;
    } else {
      newErrors.username = '';
    }

    if (!formState.nick) {
      newErrors.nick = '닉네임을 입력해 주세요.';
      isValid = false;
    } else {
      newErrors.nick = '';
    }

    if (!updateMode) {
      const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+]).{8,}$/;
      if (!formState.password) {
        newErrors.password = '비밀번호를 입력해 주세요.';
        isValid = false;
      } else if (!passwordRegex.test(formState.password)) {
        newErrors.password =
          '영문, 숫자, 특수문자를 모두 포함한 8자 이상의 비밀번호를 입력하세요.';
        isValid = false;
      } else {
        newErrors.password = '';
      }

      if (!formState.confirmPassword) {
        newErrors.confirmPassword = '비밀번호 확인을 입력해 주세요.';
        isValid = false;
      } else if (formState.password !== formState.confirmPassword) {
        newErrors.confirmPassword = '비밀번호가 일치하지 않습니다.';
        isValid = false;
      } else {
        newErrors.confirmPassword = '';
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true); // 로딩 시작
      await saveUser();
      setLoading(false); // 로딩 끝
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [e.target.id]: e.target.value });
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalTitle === '성공') {
      navigate('/members');
    }
  };

  const checkUser = async () => {
    try {
      const response = await axiosInstance.get(
        `/dup-check?email=${formState.username}`
      );
      return response.data; // 중복 여부 반환 (true: 사용 가능, false: 사용 불가)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, checkUser);
        } else {
          console.error('이메일 중복 확인 에러:', error);
        }
      } else {
        console.error('이메일 중복 확인 에러:', error);
      }
      return false;
    }
  };

  const saveUser = async () => {
    if (!updateMode) {
      const isExistEmail = await checkUser();
      if (isExistEmail) {
        setErrors(prevErrors => ({
          ...prevErrors,
          username: '사용 중인 이메일입니다.'
        }));
        return;
      }
    }

    const formData = new FormData();

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put('api/users', formData);
      } else {
        await axiosInstance.post('join', formData);
      }

      setModalTitle('성공');
      setModalBody(updateMode ? '회원 정보 수정 완료' : '회원 등록 완료');
      setShowModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveUser);
        } else {
          console.error('회원 정보 저장 에러:', error);
        }
      } else {
        console.error('회원 정보 저장 에러:', error);
      }
    }
  };

  return (
    <div className="mb-9">
      <div className="mb-5"></div>
      <Row>
        <Col xl={9}>
          <div className="d-sm-flex justify-content-between">
            <h2 className="mb-4">
              {updateMode ? '회원 정보 수정' : '회원 등록'}
            </h2>
          </div>
        </Col>
      </Row>
      <div className="mb-5"></div>
      <Row>
        <Col xl={9}>
          <div className="d-flex align-items-end position-relative mb-7">
            <Avatar src={''} size="5xl" />
          </div>
          <div>
            <Form
              onSubmit={handleSubmit}
              noValidate
              className={classNames('g-3', 'mb-6')}
            >
              <h4 className="mb-3">회원 정보</h4>
              <Row className={classNames('g-3', 'mb-6')}>
                <Col sm={6} md={4}>
                  <FloatingLabel controlId="username" label="이메일 *">
                    <Form.Control
                      type="email"
                      placeholder="이메일"
                      value={formState.username || ''}
                      onChange={handleChange}
                      disabled={updateMode}
                      isInvalid={!!errors.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col sm={6} md={4}>
                  <FloatingLabel controlId="nick" label="닉네임 *">
                    <Form.Control
                      type="text"
                      placeholder="닉네임"
                      value={formState.nick || ''}
                      onChange={handleChange}
                      isInvalid={!!errors.nick}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.nick}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col sm={6} md={4}>
                  <FloatingLabel controlId="affiliation" label="소속">
                    <Form.Control
                      type="text"
                      placeholder="소속"
                      value={formState.affiliation || ''}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </Col>
                <Col sm={6} md={6}>
                  <FloatingLabel controlId="password" label="비밀번호 *">
                    <Form.Control
                      type="password"
                      placeholder="비밀번호"
                      value={updateMode ? '********' : formState.password}
                      onChange={handleChange}
                      isInvalid={!!errors.password}
                      disabled={updateMode}
                      style={updateMode ? { backgroundColor: '#f0f0f0' } : {}}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
                <Col sm={6} md={6}>
                  <FloatingLabel
                    controlId="confirmPassword"
                    label="비밀번호 확인 *"
                  >
                    <Form.Control
                      type="password"
                      placeholder="비밀번호 확인"
                      value={
                        updateMode ? '********' : formState.confirmPassword
                      }
                      onChange={handleChange}
                      isInvalid={!!errors.confirmPassword}
                      disabled={updateMode}
                      style={updateMode ? { backgroundColor: '#f0f0f0' } : {}}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col xl={12} className="text-end">
                  <Button
                    variant="phoenix-primary"
                    className="me-2 px-6"
                    onClick={() => navigate('/members')}
                  >
                    취소
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    loading={loading}
                    loadingPosition={'start'}
                  >
                    {updateMode ? '수정' : '등록'}
                  </Button>
                </Col>
              </Row>
            </Form>
            <InfoModal
              show={showModal}
              title={modalTitle}
              body={modalBody}
              handleClose={handleModalClose}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default MemberForm;
