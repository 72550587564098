import axios, { AxiosInstance } from 'axios';
import { AttachFile } from '../data/attachFile';
import { NavigateFunction } from 'react-router-dom';
import { refreshToken } from './apiService';

const fetchImage = async (
  axiosInstance: AxiosInstance,
  files: AttachFile[],
  navigate: NavigateFunction
): Promise<string | null> => {
  if (!files || files.length === 0) return null;

  const file = files[0];
  const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;

  try {
    const response = await axiosInstance.get(fileUrl, {
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      }
    });

    const blob = response.data;
    return URL.createObjectURL(blob);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 600) {
        await refreshToken(navigate, () =>
          fetchImage(axiosInstance, files, navigate)
        );
      } else {
        console.error('서버 에러', error);
      }
    } else {
      console.error('Unexpected error:', error);
    }
    return null;
  }
};

export default fetchImage;
