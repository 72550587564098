import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useEffect, useState } from 'react';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';
import TrapTable, {
  trapListTableColumns
} from '../../components/tables/TrapTable';
import TrapTopSection from '../../components/modules/manage/TrapTopSection';
import { Trap } from '../../data/trap';

const Traps = () => {
  const navigate = useNavigate();
  const [traps, setTraps] = useState<Trap[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTraps = async () => {
      try {
        const response = await axiosInstance.get('api/traps');
        setTraps(response.data.response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getTraps);
          } else {
            console.error('서버 에러', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getTraps();
  }, []);

  const table = useAdvanceTable<Trap>({
    data: traps,
    columns: trapListTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-5"></div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center justify-content-between">
          <h2 className="mb-0">
            <span className="me-3">통덫 목록</span>{' '}
            {/*<span className="fw-normal text-body-tertiary">*/}
            {/*  ({traps.length})*/}
            {/*</span>*/}
          </h2>
          <Link className="btn btn-primary px-5" to="/trap-form">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            통덫 추가
          </Link>
        </div>
        <TrapTopSection />
        <TrapTable />
      </AdvanceTableProvider>
    </div>
  );
};

export default Traps;
