import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Report } from '../../data/report';
import { useEffect, useState } from 'react';
import axiosInstance from '../../helpers/apiService';
import noImage from 'assets/img/no-image.png';

export const reportListTableColumns: ColumnDef<Report>[] = [
  {
    header: '미리보기',
    cell: ({ row: { original } }) => {
      const [thumbnails, setThumbnails] = useState<string[]>([]);

      useEffect(() => {
        const fetchThumbnails = async () => {
          if (!original.fileList) return;

          const urls = await Promise.all(
            original.fileList.map(async file => {
              const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;
              try {
                const response = await axiosInstance.get(fileUrl, {
                  responseType: 'blob',
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                  }
                });

                return URL.createObjectURL(response.data);
              } catch (error) {
                console.error('파일 로드 에러:', error);
                return null;
              }
            })
          );

          setThumbnails(urls.filter((url): url is string => url !== null));
        };

        fetchThumbnails();
      }, [original.fileList]);

      return (
        <>
          {thumbnails.length > 0 ? (
            thumbnails.map((src, index) => (
              <img
                key={index}
                src={src}
                alt="thumbnail"
                style={{
                  width: 50,
                  height: 50,
                  objectFit: 'cover',
                  marginRight: 5,
                  border: '1px solid #ccc',
                  borderRadius: '8px'
                }}
                onError={e => (e.currentTarget.src = noImage)}
              />
            ))
          ) : (
            <img
              src={noImage}
              alt="no thumbnail"
              style={{
                width: 50,
                height: 50,
                objectFit: 'cover',
                marginRight: 5,
                borderRadius: '8px'
              }}
            />
          )}
        </>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '12%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'feeder.name',
    header: '급식소 이름',
    cell: ({ row: { original } }) => {
      original.feederName = original.feeder!.name;
      return <>{original.feederName}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 text-body py-4' },
      headerProps: { style: { width: '12%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'title',
    header: '일지 제목',
    cell: ({ row: { original } }) => {
      return (
        <Link
          to="/report-form"
          state={{ report: original }}
          className="text-decoration-none fw-bold fs-8"
        >
          {original.title}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-4' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    accessorKey: 'userNick',
    header: '작성자',
    cell: ({ row: { original } }) => {
      original.userNick = original.user!.nick;
      return <>{original.userNick}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '작성 일자',
    accessorKey: 'modifiedDate',
    cell: ({ row: { original } }) => {
      const { modifiedDate } = original;
      return (
        <>
          {modifiedDate.split('T')[0]}{' '}
          {modifiedDate.split('T')[1].split(':')[0]} :{' '}
          {modifiedDate.split('T')[1].split(':')[1]}
        </>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  }
];

const ReportTable = () => {
  return (
    <div className="border-bottom border-translucent">
      <AdvanceTable
        tableProps={{
          className: 'phoenix-table border-top border-translucent fs-9'
        }}
      />
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default ReportTable;
