import React, { useEffect, useState } from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';
import FeatherIcon from 'feather-icons-react';
import Badge, { BadgeBg } from 'components/base/Badge';
import { Feeder } from '../../data/feeder';
import classNames from 'classnames';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import { useNavigate } from 'react-router-dom';
import fetchImage from '../../helpers/fetchImage';
import noImage from 'assets/img/no-image.png';

const FeederDetailsProfileCard = ({
  className,
  feeder
}: {
  className?: string;
  feeder?: Feeder;
}) => {
  const navigate = useNavigate();
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (!feeder?.fileList || feeder.fileList.length === 0) return;

    fetchImage(axiosInstance, feeder!.fileList!, navigate).then(value => {
      setImageSrc(value);
    });

    return () => {
      if (imageSrc) {
        URL.revokeObjectURL(imageSrc);
      }
    };
  }, [feeder]);

  const getVariant = (percent: number): BadgeBg => {
    if (percent < 20) return 'danger';
    if (percent < 40) return 'warning';
    if (percent < 80) return 'primary';
    return 'success';
  };

  const getLabel = (percent: number): string => {
    if (percent < 20) return '조치 필요';
    if (percent < 40) return '권고 필요';
    if (percent < 80) return '양호';
    return '매우 양호';
  };

  return (
    <Card className={className}>
      <Card.Body>
        <Row className="align-items-center g-3">
          <Col sm="auto">
            <div className="d-md-flex d-xl-block align-items-center justify-content-between mb-5">
              <div className="d-flex align-items-center mb-3 mb-md-0 mb-xl-3">
                {/*<Avatar size="4xl" src={''} className="me-3" />*/}
                {imageSrc ? (
                  <img
                    src={imageSrc}
                    alt="Feeder"
                    className="me-3"
                    style={{
                      width: '150px',
                      height: '150px',
                      borderRadius: '5%'
                    }}
                  />
                ) : (
                  <div
                    style={{
                      width: '150px',
                      height: '150px',
                      borderRadius: '5%',
                      backgroundColor: '#f0f0f0',
                      display: 'flex', // Enables Flexbox
                      justifyContent: 'center', // Centers content horizontally
                      alignItems: 'center' // Centers content vertically
                    }}
                    className="me-3"
                  >
                    등록된 이미지가
                    <br />
                    없습니다
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col sm="auto">
            <h3 className="fw-bolder mb-2 line-clamp-1">{feeder?.name}</h3>
            <div className="d-flex align-items-center mb-4">
              <h5 className="mb-0 me-4">{feeder?.address}</h5>
              <h5 className="fw-semibold">
                <FeatherIcon
                  icon="grid"
                  className="d-inline-block lh-sm me-1"
                  width={16}
                  height={16}
                />
                <span className="d-inline-block lh-sm">{feeder?.owner}</span>
              </h5>
            </div>
          </Col>
        </Row>
        <div className="mb-2">
          <Badge
            variant="phoenix"
            bg={getVariant(feeder?.tnrRate ?? 0)}
            className="me-2"
          >
            {getLabel(feeder?.tnrRate ?? 0)}
          </Badge>
        </div>
        <ProgressBar
          now={feeder?.tnrRate}
          className={classNames(
            'flex-1',
            `bg-${getVariant(feeder?.tnrRate ?? 0)}-subtle`,
            'mb-2'
          )}
          variant={getVariant(feeder?.tnrRate ?? 0)}
        />
        <div className="d-flex align-items-center justify-content-between">
          <p className="mb-0">중성화율</p>
          <div>
            {/*<FeatherIcon*/}
            {/*  icon="clock"*/}
            {/*  className="lh-sm me-1"*/}
            {/*  width={16}*/}
            {/*  height={16}*/}
            {/*/>*/}
            <span className="d-inline-block lh-sm">
              {feeder?.tnrRate ?? 0}%
            </span>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FeederDetailsProfileCard;
