import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from 'components/base/Button';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import axiosInstance, { refreshToken } from 'helpers/apiService';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoModal from 'components/modals/InfoModal';
import { Trap } from '../../data/trap';

const TrapForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formState, setFormState] = useState<Trap>({
    id: '',
    uniqueNum: '',
    owner: '',
    type: '',
    trapStatus: 'AVAILABLE'
  });

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [updateMode, setUpdateMode] = useState(false);

  useEffect(() => {
    if (location.state?.trap) {
      setUpdateMode(true);
      const trapData = location.state?.trap;
      setFormState(trapData);
    }
  }, [location.state]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { uniqueNum, owner } = formState;

    if (uniqueNum.trim() === '' || owner.trim() === '') {
      setModalTitle('오류');
      setModalBody('*는 필수 입력값입니다');
      setShowModal(true);
      return;
    }

    await saveTrap();
  };

  const saveTrap = async () => {
    const formData = new FormData();

    formData.append('userId', localStorage.getItem('userId')!);

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/traps/${formState.id}`, formData);
      } else {
        await axiosInstance.post('api/traps', formData);
      }
      // 급식소 등록 성공 후 모달 띄우기
      setModalTitle('성공');
      setModalBody(updateMode ? '통덫 수정 완료' : '통덫 등록 완료');
      setShowModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveTrap);
        } else {
          console.error('통덫 저장 에러:', error);
        }
      } else {
        console.error('일지 저장 에러:', error);
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalTitle === '성공') {
      navigate('/traps');
    }
  };

  return (
    <div>
      {/*<PageBreadcrumb items={defaultBreadcrumbItems} />*/}
      <div className="mb-5"></div>
      <h2 className="mb-4">{updateMode ? '통덫 수정' : '통덫 등록'}</h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6" onSubmit={handleSubmit}>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="uniqueNum" label="고유번호 *">
                <Form.Control
                  type="text"
                  placeholder="고유번호 *"
                  onChange={handleChange}
                  maxLength={12}
                  value={formState.uniqueNum}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="owner" label="소유자 *">
                <Form.Control
                  type="text"
                  placeholder="소유자 *"
                  onChange={handleChange}
                  maxLength={12}
                  value={formState.owner}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="type" label="타입 *">
                <Form.Select value={formState.type} onChange={handleChange}>
                  <option>선택</option>
                  <option value="PUBLIC">공공</option>
                  <option value="PRIVATE">사설</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="phoenix-primary"
                  className="px-5"
                  onClick={() => navigate('/traps')}
                >
                  취소
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="px-5 px-sm-15"
                >
                  {updateMode ? '수정' : '등록'}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <InfoModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default TrapForm;
