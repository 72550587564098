import classNames from 'classnames';
import Footer from 'components/footers/Footer';
import NavbarTopDefault from 'components/navbars/NavbarTopDefault';
import { useMainLayoutContext } from 'providers/MainLayoutProvider';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import NavbarVertical from 'components/navbars/NavbarVertical';

const MainLayout = () => {
  const { contentClass, footerClass } = useMainLayoutContext();

  return (
    <Container fluid className="px-0">
      <NavbarVertical />
      <NavbarTopDefault />

      <div className={classNames(contentClass, 'content')}>
        <Outlet />
        {/*<Footer className={classNames(footerClass, 'position-absolute')} />*/}
      </div>
    </Container>
  );
};

export default MainLayout;
