import { Dropdown } from 'react-bootstrap';
import { TrapSchedule } from '../../data/trapSchedules';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Trap } from '../../data/trap';
import { useEffect, useState } from 'react';

interface TrapScheduleProps {
  trapSchedule?: TrapSchedule;
}

const ActionTrapScheduleDropdownItems = ({
  trapSchedule
}: TrapScheduleProps) => {
  const navigate = useNavigate();
  const [newTrapSchedule, setNewTrapSchedule] = useState<TrapSchedule>();
  const [isReturnDisabled, setIsReturnDisabled] = useState(false);

  useEffect(() => {
    if (trapSchedule) {
      trapSchedule.trapId = trapSchedule.trap!.id;
      trapSchedule.feederId = trapSchedule.feeder!.id;
      trapSchedule.reservedDate = trapSchedule.reservedDate!.replace('T', ' ');
      setNewTrapSchedule(trapSchedule);

      const reservedDate = new Date(trapSchedule.reservedDate);
      const today = new Date();
      if (reservedDate > today) {
        setIsReturnDisabled(true);
      }
    }
  }, [trapSchedule]);

  const handleActionClick = async (status: string, action: string) => {
    if (trapSchedule?.trap) {
      try {
        await updateTrapStatus(trapSchedule.trap, status, action);
        navigate(0);
      } catch (error) {
        console.error('Error updating trap status:', error);
      }
    }
  };

  const updateTrapStatus = async (
    trap: Trap,
    status: string,
    action: string
  ) => {
    const formData = new FormData();

    action === 'cancel' ? (trap.isCancel = true) : (trap.isCancel = false);
    formData.append('id', trap.id);
    formData.append('uniqueNum', trap.uniqueNum);
    formData.append('owner', trap.owner);
    formData.append('type', trap.type);
    formData.append('trapStatus', status);
    formData.append('isCancel', trap.isCancel.toString());

    try {
      await axiosInstance.put(`api/traps/status/${trap.id}`, formData);
      if (action === 'cancel') {
        await deleteTrapSchedule();
      } else if (action === 'complete') {
        await updateTrapSchedule();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, () =>
            updateTrapStatus(trap, status, action)
          );
        } else {
          console.error('API error:', error);
        }
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  const deleteTrapSchedule = async () => {
    try {
      await axiosInstance.delete(`api/schedules/${newTrapSchedule!.id}`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, deleteTrapSchedule);
        } else {
          console.error('통덫 예약 삭제 에러:', error);
        }
      } else {
        console.error('통덫 예약 삭제 에러:', error);
      }
    }
  };

  const updateTrapSchedule = async () => {
    const formData = new FormData();

    // 현재 날짜와 시간을 yyyy-MM-dd hh:mm:ss 형식으로 설정
    const now = new Date();
    const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`;

    if (newTrapSchedule) {
      newTrapSchedule.returnDate = formattedDate;
    }

    formData.append('userId', newTrapSchedule!.user!.id);

    Object.entries(newTrapSchedule!).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      await axiosInstance.put(`api/schedules/${newTrapSchedule!.id}`, formData);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, () => updateTrapSchedule());
        } else {
          console.error('통덫 예약 저장 에러:', error);
        }
      } else {
        console.error('통덫 예약 저장 에러:', error);
      }
    }
  };

  return (
    <>
      <Dropdown.Item
        eventKey="1"
        onClick={() => handleActionClick('RENTED', 'using')}
        disabled={trapSchedule?.trap?.trapStatus !== 'RESERVED'}
      >
        사용중
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="2"
        onClick={() => handleActionClick('AVAILABLE', 'complete')}
        disabled={trapSchedule?.returnDate !== null}
      >
        반납완료
      </Dropdown.Item>
      <Dropdown.Item
        eventKey="3"
        onClick={() => handleActionClick('AVAILABLE', 'cancel')}
        disabled={trapSchedule?.trap?.trapStatus !== 'RESERVED'}
      >
        예약 취소
      </Dropdown.Item>
    </>
  );
};

export default ActionTrapScheduleDropdownItems;
