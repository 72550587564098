import { Nav } from 'react-bootstrap';
import { WizardItem } from '../../data/wizardItem';
import TnrWizardNavItem from '../items/TnrWizardNavItem';
import { useWizardFormContext } from '../../providers/WizardFormProvider';
import { useEffect, useState } from 'react';
import { Tnr } from '../../data/tnr';

const TnrWizardNav = ({ wizardItems }: { wizardItems: WizardItem[] }) => {
  const { formData } = useWizardFormContext();
  const [disabledSteps, setDisabledSteps] = useState<boolean[]>([]); // boolean[]으로 설정

  useEffect(() => {
    const data = formData as Tnr;
    let maxEnabledStep = 0;

    switch (data.tnrStatus) {
      case 'submission':
        maxEnabledStep = 1;
        break;
      case 'trap':
        maxEnabledStep = 2;
        break;
      case 'neuter':
        maxEnabledStep = 3;
        break;
      case 'return':
        maxEnabledStep = 4;
        break;
      default:
        maxEnabledStep = 0;
        break;
    }
    // 각 단계에 대해 true 또는 false 값을 가진 배열 생성
    const disabledArray = wizardItems.map((_, index) => index > maxEnabledStep);
    setDisabledSteps(disabledArray);
  }, [formData, wizardItems]);

  return (
    <Nav className="justify-content-between nav-wizard">
      {wizardItems.map((item, index) => (
        <TnrWizardNavItem
          key={index}
          icon={item.icon}
          step={item.step}
          label={item.label}
          disabled={disabledSteps[index]} // disabled 값 전달
        />
      ))}
    </Nav>
  );
};

export default TnrWizardNav;
