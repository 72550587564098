// src/api/axiosInstance.js
import axios from 'axios';
import { NavigateFunction } from 'react-router-dom';

const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

const axiosInstance = axios.create({
  baseURL: baseUrl, // API의 기본 URL
  timeout: 10000, // 요청 타임아웃
  withCredentials: true
});

// 요청 인터셉터 설정
axiosInstance.interceptors.request.use(
  config => {
    // 여기서 토큰을 추가하거나 기타 공통 설정을 할 수 있습니다.
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers['access'] = accessToken;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 설정
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject(error);
  }
);

export const refreshToken = async (
  navigate: NavigateFunction,
  callback?: () => void
) => {
  try {
    const config = {
      withCredentials: true
    };
    const response = await axios.post(`${baseUrl}api/refresh`, null, config);
    localStorage.setItem('accessToken', response.headers['access']);
    if (callback) {
      callback();
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // refresh token도 만료되었다면 로그인페이지로
      if (
        error.response?.status === 600 ||
        error.response?.status === 406 ||
        error.response?.status === 599
      ) {
        navigate('/auth/sign-in');
      }
    }
  }
};

export default axiosInstance;
