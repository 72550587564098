import { faCalendarDays, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Report } from '../../data/report';

const MemberDetailsTimeline = ({ reports }: { reports: Report[] }) => {
  return (
    <>
      {reports.map((report, index) => (
        <div
          key={report.id}
          className={classNames('border-bottom border-translucent py-4', {
            'pb-0 border-bottom-0': index === reports.length - 1
          })}
        >
          <div className="d-flex">
            <div
              className={`d-flex bg-primary-subtle rounded-circle flex-center me-3`}
              style={{ width: '25px', height: '25px' }}
            >
              <FontAwesomeIcon
                icon={faClipboard}
                className={'text-primary-dark fs-9'}
              />
            </div>
            <div className="flex-1">
              <div className="d-flex justify-content-between flex-column flex-xl-row mb-2 mb-sm-0">
                <div className="flex-1 me-2">
                  <h5 className="text-body-highlight lh-sm">
                    <Link to="/report-form" state={{ report: report }}>
                      {report.title}
                    </Link>
                  </h5>
                  <p className="fs-9 mb-0">by {report.user?.nick}</p>
                </div>
                <div className="fs-9">
                  <FontAwesomeIcon
                    icon={faCalendarDays}
                    className="text-primary me-2"
                  />
                  <span className="fw-semibold">
                    {report.modifiedDate.split('T')[0]}{' '}
                    {report.modifiedDate.split('T')[1].split(':')[0]} :{' '}
                    {report.modifiedDate.split('T')[1].split(':')[1]}
                  </span>
                </div>
              </div>
              {report.comment && <p className="fs-9 mb-0">{report.comment}</p>}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default MemberDetailsTimeline;
