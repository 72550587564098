import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import InfoModal from 'components/modals/InfoModal';
import axios from 'axios';

const SignInForm = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);

  const login = async () => {
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

      const axiosInstance = axios.create({
        baseURL: baseUrl, // API의 기본 URL
        timeout: 10000, // 요청 타임아웃
        withCredentials: true
      });

      const response = await axiosInstance.post('login', formData);

      // 관리자가 아닌 유저가 로그인하려고할때
      if (response.data.role === 'ROLE_USER') {
        setShowModal(true);
        return;
      }

      localStorage.setItem('user', JSON.stringify(response.data)); // user 저장

      localStorage.setItem('userId', response.data.id); // id 저장

      localStorage.setItem('userNick', response.data.nick); // nick 저장

      localStorage.setItem('accessToken', response.headers['access']); // accessToken LocalStorage 에 저장.

      window.location.href = '/'; // 새로고침하여 메인으로 이동
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 401) {
          setShowModal(true);
        } else {
          console.error('Login error:', error);
        }
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  const handleCloseModal = () => {
    setUsername('');
    setPassword('');
    setShowModal(false);
  };

  return (
    <>
      <div className="text-center mb-7">
        <h2 className="text-body-highlight">길고양이 통합관리 플랫폼 </h2>
        {/*<p className="text-body-tertiary">들어가기</p>*/}
      </div>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="username">아이디</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="username"
            type="text"
            className="form-icon-input"
            placeholder="아이디"
            value={username}
            onChange={e => setUsername(e.target.value)}
          />
          <FontAwesomeIcon icon={faUser} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">비밀번호</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="비밀번호"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          <FontAwesomeIcon icon={faKey} className="text-body fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="12" className="text-center">
          <Link to="" className="fs-9 fw-semibold">
            비밀번호를 잃어버리셨나요?
          </Link>
        </Col>
      </Row>
      <Button variant="primary" className="w-100 mb-3" onClick={login}>
        로그인
      </Button>
      {/*<div className="text-center">*/}
      {/*  <Link*/}
      {/*    to={`/pages/authentication/${layout}/sign-up`}*/}
      {/*    className="fs-9 fw-bold"*/}
      {/*  >*/}
      {/*    회원가입*/}
      {/*  </Link>*/}
      {/*</div>*/}

      <InfoModal
        show={showModal}
        title="로그인 실패"
        body="아이디 또는 비밀번호를 확인해 주세요."
        handleClose={handleCloseModal}
      />
    </>
  );
};

export default SignInForm;
