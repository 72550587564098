import Avatar from 'components/base/Avatar';
import { Card, Dropdown } from 'react-bootstrap';
import avatar from 'assets/img/team/40x40/avatar-rounded.webp';
import FeatherIcon from 'feather-icons-react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

const ProfileDropdownMenu = ({ className }: { className?: string }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    navigate('auth/sign-in');
  };

  return (
    <Dropdown.Menu
      align="end"
      className={classNames(
        className,
        'navbar-top-dropdown-menu navbar-dropdown-caret py-0 dropdown-profile shadow border'
      )}
    >
      <Card className="position-relative border-0">
        <Card.Body className="p-0">
          <div className="d-flex flex-column align-items-center justify-content-center gap-2 pt-4 pb-3">
            <Avatar src={avatar} size="xl" />
            <h6 className="text-body-emphasis">
              {localStorage.getItem('userNick')}
            </h6>
          </div>
        </Card.Body>
        <Card.Footer className="border-top border-translucent">
          <div className="px-3">
            <button
              onClick={handleLogout}
              className="btn btn-phoenix-secondary d-flex flex-center w-100"
            >
              <FeatherIcon icon="log-out" className="me-2" size={16} />
              로그아웃
            </button>
          </div>
        </Card.Footer>
      </Card>
    </Dropdown.Menu>
  );
};

export default ProfileDropdownMenu;
