import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { User } from '../../data/users';
import ActionUserDropdownItems from '../dropdown/ActionUserDropdownItems';
import React from 'react';

export const memberTableColumns: ColumnDef<User>[] = [
  {
    accessorKey: 'nick',
    header: '이름',
    cell: ({ row: { original } }) => {
      const { avatar, nick } = original;
      return (
        <div className="d-flex align-items-center">
          <Avatar src={avatar} size="xl" className="me-3" />
          <div>
            <Link
              to="/member-details"
              state={{ user: original }}
              className="fs-8 fw-bold"
            >
              {nick}
            </Link>
          </div>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '25%' }, className: 'ps-0' },
      cellProps: { className: 'white-space-nowrap ps-0' }
    }
  },
  {
    accessorKey: 'company',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-warning-subtle rounded me-2">
            <FeatherIcon icon="grid" size={16} className="text-warning-dark" />
          </div>
          <span>소속</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => original.affiliation,
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap ps-4 border-end border-translucent fw-semibold text-body-highlight'
      }
    }
  },
  {
    accessorKey: 'contact',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-info-subtle rounded me-2">
            <FeatherIcon icon="user" size={16} className="text-info-dark" />
          </div>
          <span>급식소</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      return (
        <>
          {original.feeders?.map((feeder, index) => {
            return (
              <span key={index}>
                {feeder.name}
                <br />
              </span>
            ); // key 추가
          })}
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap ps-4 border-end border-translucent fw-semibold text-body-highlight'
      }
    }
  },
  {
    accessorKey: 'phone',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-primary-subtle rounded me-2">
            <FeatherIcon icon="phone" size={16} className="text-primary-dark" />
          </div>
          <span>핸드폰</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      return <div className="text-body-highlight">010-****-****</div>;
    },
    meta: {
      headerProps: {
        style: { width: '15%', minWidth: '180px' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'username',
    header: () => {
      return (
        <div className="d-inline-flex flex-center">
          <div className="px-1 py-1 bg-success-subtle rounded me-2">
            <FeatherIcon icon="mail" size={16} className="text-success-dark" />
          </div>
          <span>이메일</span>
        </div>
      );
    },
    cell: ({ row: { original } }) => {
      const { username } = original;
      return <div className="text-body-highlight">{username}</div>;
    },
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5 border-end border-translucent'
      },
      cellProps: {
        className:
          'white-space-nowrap fw-semibold ps-4 border-end border-translucent'
      }
    }
  },
  {
    accessorKey: 'date',
    header: '가입일',
    cell: ({ row: { original } }) => original.createdDate!.split('T')[0],
    meta: {
      headerProps: {
        style: { width: '15%' },
        className: 'ps-4 pe-5'
      },
      cellProps: {
        className: 'white-space-nowrap ps-4 text-body-tertiary'
      }
    }
  },
  {
    id: 'leadDropdown',
    cell: ({ row: { original } }) => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionUserDropdownItems user={original} />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      cellProps: {
        className: 'text-end pe-0 ps-4'
      }
    }
  }
];

const MemberTable = () => {
  return (
    <div className="border-top border-translucent">
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-actions-trigger btn-reveal-trigger"
      />
      <AdvanceTableFooter pagination className="py-4" />
    </div>
  );
};

export default MemberTable;
