import {
  faCat,
  faCheck,
  faFileAlt,
  faHouseChimney,
  faStethoscope,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Card, Modal, Tab } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import WizardForm from '../wizard/WizardForm';
import TnrSubmissionForm from '../forms/TnrSubmissionForm';
import TnrTrapForm from '../forms/TnrTrapForm';
import TnrNeuterForm from '../forms/TnrNeuterForm';
import WizardSuccessStep from '../wizard/WizardSuccessStep';
import classNames from 'classnames';
import WizardFormProvider, {
  useWizardFormContext
} from '../../providers/WizardFormProvider';
import useWizardForm from '../../hooks/useWizardForm';
import { WizardItem } from '../../data/wizardItem';
import { Tnr } from '../../data/tnr';
import TnrReturnForm from '../forms/TnrReturnForm';
import TnrWizardFormFooter from '../footers/TnrWizardFormFooter';
import TnrWizardNav from '../navbars/TnrWizardNav';

export interface AttachFile {
  id: string;
  originFileName: string;
  savedFileName: string;
  path: string;
  extension: string;
}

interface TnrFormModalProps {
  show: boolean;
  handleClose: () => void;
  tnr: Tnr;
}

const TnrFormModal = ({ show, handleClose, tnr }: TnrFormModalProps) => {
  const form = useWizardForm<Tnr>({
    totalStep: 5,
    validation: false
  });
  const { selectedStep,setSelectedStep } = form;
  const [updateMode, setUpdateMode] = useState(false);

  const wizardItems: WizardItem[] = [
    { icon: faFileAlt, label: '신청', step: 1 },
    { icon: faCat, label: '포획', step: 2 },
    { icon: faStethoscope, label: '중성화', step: 3 },
    { icon: faHouseChimney, label: '방사', step: 4 },
    { icon: faCheck, label: '완료', step: 5 }
  ];

  useEffect(() => {
    if (Object.keys(tnr).length !== 0) {
      setUpdateMode(true);
      switch (tnr.tnrStatus) {
        case 'submission':
          setSelectedStep(Number(2));
          break;
        case 'trap':
          setSelectedStep(Number(3));
          break;
        case 'tnr':
          setSelectedStep(Number(4));
          break;
      }
    }
  }, [tnr]);

  return (
    <div>
      <Modal
        size="lg"
        show={show}
        centered
        backdrop="static"
        onHide={handleClose}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">
            {Object.keys(tnr).length !== 0 ? 'TNR 진행 상황' : 'TNR 예약'}
          </h3>
          <Button
            variant="phoenix-secondary"
            className="ms-auto"
            onClick={handleClose}
            size="sm"
          >
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <WizardFormProvider {...form}>
            <Card className="theme-wizard">
              <Card.Header className="bg-body-highlight pt-3 pb-2 border-bottom-0">
                <TnrWizardNav wizardItems={wizardItems} />
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey={1}>
                    <WizardForm step={1}>
                      <TnrSubmissionForm id="validation" tnr={tnr} />
                    </WizardForm>
                  </Tab.Pane>
                  <Tab.Pane eventKey={2}>
                    <WizardForm step={2}>
                      <TnrTrapForm />
                    </WizardForm>
                  </Tab.Pane>
                  <Tab.Pane eventKey={3}>
                    <WizardForm step={3}>
                      <TnrNeuterForm />
                    </WizardForm>
                  </Tab.Pane>
                  <Tab.Pane eventKey={4}>
                    <WizardForm step={4}>
                      <TnrReturnForm />
                    </WizardForm>
                  </Tab.Pane>
                  <Tab.Pane eventKey={5}>
                    <WizardSuccessStep />
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
              <Card.Footer className="border-top-0">
                <TnrWizardFormFooter
                  updateMode={updateMode}
                  className={classNames({ 'd-none': !form.getCanNextPage })}
                />
              </Card.Footer>
            </Card>
          </WizardFormProvider>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0"></Modal.Footer>
      </Modal>
    </div>
  );
};

export default TnrFormModal;
