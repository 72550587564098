import FilterTab, { FilterTabItem } from 'components/common/FilterTab';
import SearchBox from 'components/common/SearchBox';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import { ChangeEvent, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { TrapSchedule } from 'data/trapSchedules';

const TrapSchedulesTopSection = () => {
  useNavigate();
  const { setGlobalFilter, getPrePaginationRowModel, getColumn } =
    useAdvanceTableContext<TrapSchedule>();

  const handleFilterItemClick = (columnId: string, value: string) => {
    const column = getColumn(columnId);
    column?.setFilterValue(value === 'all' ? '' : value);
  };

  const tabItems: FilterTabItem[] = useMemo(() => {
    const getDataCount = (label: string) =>
      getPrePaginationRowModel().rows.filter(
        ({ original }) => original.trap?.trapStatus === label
      ).length;

    return [
      {
        label: '전체',
        value: 'all',
        onClick: () => handleFilterItemClick('trapStatus', 'all'),
        count: getPrePaginationRowModel().rows.length
      },
      {
        label: '예약중',
        value: 'RESERVED',
        onClick: () => handleFilterItemClick('trapStatus', 'RESERVED'),
        count: getDataCount('RESERVED')
      },
      {
        label: '사용중',
        value: 'RENTED',
        onClick: () => handleFilterItemClick('trapStatus', 'RENTED'),
        count: getDataCount('RENTED')
      },
      {
        label: '반납완료',
        value: 'AVAILABLE',
        onClick: () => handleFilterItemClick('trapStatus', 'AVAILABLE'),
        count: getDataCount('AVAILABLE')
      }
    ];
  }, [getPrePaginationRowModel]);

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <Row className="g-3 justify-content-between align-items-center mb-4">
      <Col xs={12} sm="auto">
        <FilterTab tabItems={tabItems} />
      </Col>
      <Col xs={12} sm="auto">
        <div className="d-flex align-items-center gap-1">
          <SearchBox
            onChange={handleSearchInputChange}
            placeholder="검색"
            style={{ width: '100%' }}
            // style={{ maxWidth: '30rem' }}
            // className="me-3"
          />
        </div>
      </Col>
    </Row>
  );
};

export default TrapSchedulesTopSection;
