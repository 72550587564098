import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Report } from '../../data/report';
import ReportDetailModal from '../modals/ReportDetailModal';
import { useState } from 'react';
import Button from '../base/Button';

export const feederDetailsReportColumns: ColumnDef<Report>[] = [
  {
    header: '제목',
    accessorKey: 'title',
    cell: ({ row: { original } }) => {
      const [openDetailsModal, setOpenDetailsModal] = useState(false);
      return (
        <>
          <Button
            variant="link"
            className="fw-semibold ps-0"
            onClick={() => setOpenDetailsModal(true)}
          >
            {original.title}
          </Button>
          <ReportDetailModal
            show={openDetailsModal}
            handleClose={() => setOpenDetailsModal(false)}
            report={original}
          />
        </>
      );
    },
    meta: {
      headerProps: {
        style: { width: '30%', minWidth: 350 },
        className: 'ps-0'
      },
      cellProps: { className: 'py-2 ps-0' }
    }
  },
  {
    header: '암컷 고양이',
    accessorKey: 'female',
    meta: {
      headerProps: {
        style: { minWidth: 130, width: '15%' },
        className: 'pe-3'
      },
      cellProps: {
        className: 'fw-bold text-body-tertiary'
      }
    }
  },
  {
    header: '수컷 고양이',
    accessorKey: 'male',
    meta: {
      headerProps: {
        style: { minWidth: 130, width: '15%' },
        className: 'pe-3'
      },
      cellProps: {
        className: 'fw-bold text-body-tertiary'
      }
    }
  },
  {
    header: '작성자',
    accessorKey: 'user.nick',
    meta: {
      headerProps: {
        style: { minWidth: 130, width: '15%' },
        className: 'pe-3'
      },
      cellProps: {
        className: 'fw-bold text-body-tertiary'
      }
    }
  },
  {
    accessorKey: 'modifiedDate',
    header: '작성 일자',
    cell: ({ row: { original } }) => (
      <>
        {original.modifiedDate.split('T')[0]}{' '}
        {original.modifiedDate.split('T')[1].split(':')[0]} :{' '}
        {original.modifiedDate.split('T')[1].split(':')[1]}
      </>
    ),
    meta: {
      headerProps: { style: { minWidth: 165 } },
      cellProps: {
        className: 'text-body'
      }
    }
  }
];

const FeederDetailsReportTable = () => {
  return (
    <div className="border-y border-translucent">
      <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
      <AdvanceTableFooter pagination showViewAllBtn={false} />
    </div>
  );
};

export default FeederDetailsReportTable;
