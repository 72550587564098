import { useState } from 'react';
import { faCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { getFileIcon } from 'helpers/utils';
import ImageModal from 'components/modals/ImageModal';

export interface FileAttachment {
  name: string;
  size: string;
  format: string;
  date?: string;
  preview?: string;
}

interface AttachmentProps {
  attachment: FileAttachment;
  variant?: 'primary' | 'secondary';
  size?: 'lg' | 'xl';
  url?: string;
  handleRemove?: () => void;
}

const AttachmentPreview = ({
  attachment,
  variant = 'primary',
  // size = 'xl',
  handleRemove
}: AttachmentProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <>
      <div
        className="text-decoration-none d-flex align-items-center"
        onClick={handleShowModal}
        style={{ cursor: 'pointer' }}
      >
        <div
          style={{ width: '200px', height: '200px', padding: '8px' }}
          className={classNames(
            // `btn-icon btn-icon-${size} rounded-3 flex-column me-2 position-relative`,
            `btn-icon rounded-3 flex-column me-2 position-relative`,
            {
              border: !attachment.preview,
              'text-body-quaternary': variant === 'primary',
              'border-light-subtle text-white': variant === 'secondary'
            }
          )}
        >
          {attachment.preview ? (
            <img
              src={attachment.preview}
              alt={attachment.name}
              className="w-100 rounded"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <>
              <FontAwesomeIcon
                icon={getFileIcon(attachment.format)}
                className={classNames('fs-8 mb-1')}
              />
              <p className="mb-0 fs-10 fw-bold lh-1">{attachment.format}</p>
            </>
          )}

          {handleRemove && (
            <button
              className="btn btn-x"
              onClick={e => {
                e.stopPropagation();
                handleRemove && handleRemove();
              }}
            >
              <FontAwesomeIcon icon={faXmark} className="text-body" />
            </button>
          )}
        </div>

        <div className="flex-1">
          <h6
            className={classNames('line-clamp-1 text-break', {
              'text-body': variant === 'primary',
              'text-white': variant === 'secondary'
            })}
          >
            {attachment.name}
          </h6>
          <div
            className={classNames(
              'd-flex align-items-center lh-1 fw-semibold fs-10',
              {
                'text-body-tertiary': variant == 'primary',
                'text-light': variant == 'secondary'
              }
            )}
          >
            <p className="mb-0 text-nowrap">{attachment.size}</p>
            {attachment.date && (
              <>
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-body-quaternary mx-2"
                  transform="shrink-12"
                />
                <p className="mb-0 text-nowrap">{attachment.date}</p>
              </>
            )}
          </div>
        </div>
      </div>

      {/*{attachment.preview && (*/}
      {/*  <ImageModal*/}
      {/*    show={showModal}*/}
      {/*    title={attachment.name}*/}
      {/*    imageUrl={attachment.preview}*/}
      {/*    handleClose={handleCloseModal}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

export default AttachmentPreview;
