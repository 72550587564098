import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import TrapScheduleTable, {
  trapScheduleListTableColumns
} from 'components/tables/TrapScheduleTable';
import TrapScheduleTopSection from 'components/modules/manage/TrapSchedulesTopSection';
import { TrapSchedule } from 'data/trapSchedules';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { Col, Row, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Calendar from '../../components/calendar/Calendar';
import CalendarProvider from '../../providers/CalendarProvider';
import FeederDetailsProfileCard from '../../components/feeder/FeederDetailsProfileCard';
import DealsOtherInformation from '../../components/forms/DealsOtherInformation';
import ScrollSpy from '../../components/base/ScrollSpy';
import FeederDetailsUserTab from '../../components/feeder/FeederDetailsUserTab';
import FeederDetailsReportTab from '../../components/feeder/FeederDetailsReportTab';

const TrapSchedules = () => {
  const navigate = useNavigate();
  const [trapSchedules, setTrapSchedules] = useState<TrapSchedule[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getTrapSchedules = async () => {
      try {
        const response = await axiosInstance.get('api/schedules');
        setTrapSchedules(response.data.response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getTrapSchedules);
          } else {
            console.error('서버 에러', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getTrapSchedules();
  }, []);

  const table = useAdvanceTable<TrapSchedule>({
    data: trapSchedules,
    columns: trapScheduleListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div>
      {/*<PageBreadcrumb items={defaultBreadcrumbItems} />*/}
      <div className="mb-5"></div>
      {/*<AdvanceTableProvider {...table}>*/}
      {/*  <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">*/}
      {/*    <h2 className="mb-0">*/}
      {/*      <span className="me-3">통덫 예약 목록</span>{' '}*/}
      {/*      /!*<span className="fw-normal text-body-tertiary">*!/*/}
      {/*      /!*  ({trapSchedules.length})*!/*/}
      {/*      /!*</span>*!/*/}
      {/*    </h2>*/}
      {/*    <Link className="btn btn-primary px-5" to="/trap-schedule-form">*/}
      {/*      <FontAwesomeIcon icon={faPlus} className="me-2" />*/}
      {/*      통덫 예약하기*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*  <CalendarProvider>*/}
      {/*    <Calendar />*/}
      {/*  </CalendarProvider>*/}
      {/*  <TrapScheduleTopSection />*/}
      {/*  <TrapScheduleTable />*/}
      {/*</AdvanceTableProvider>*/}
      <Row className="g-4 g-xl-6">
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center justify-content-between">
          <h2 className="mb-0">
            <span className="me-3">통덫 예약 목록</span>{' '}
            {/*<span className="fw-normal text-body-tertiary">*/}
            {/*  ({trapSchedules.length})*/}
            {/*</span>*/}
          </h2>
          <Link className="btn btn-primary px-5" to="/trap-schedule-form">
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            통덫 예약하기
          </Link>
        </div>
        <Col sm={6}>
          <CalendarProvider>
            <Calendar trapSchedules={trapSchedules} />
          </CalendarProvider>
        </Col>
        <Col sm={6}>
          <AdvanceTableProvider {...table}>
            <TrapScheduleTopSection />
            <TrapScheduleTable />
          </AdvanceTableProvider>
        </Col>
      </Row>
    </div>
  );
};

export default TrapSchedules;
