import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import React, { useEffect, useState } from 'react';
import FeederDetailsUserTable, {
  feederDetailsUserColumns
} from '../tables/FeederDetailsUserTable';
import useAdvanceTable from '../../hooks/useAdvanceTable';
import { User } from '../../data/users';
import { leadDealsTableData } from '../../data/crm/leadsData';
import AdvanceTableProvider from '../../providers/AdvanceTableProvider';
import { useNavigate } from 'react-router-dom';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import UserListModal from '../modals/UserListModal';
import { Feeder } from '../../data/feeder';
import { Col, Row } from 'react-bootstrap';
import InfoModal from '../modals/InfoModal';
import DeleteModal from '../modals/DeleteModal';

interface FeederDetailsReportTabProps {
  feeder: Feeder;
}

const FeederDetailsUserTab = ({ feeder }: FeederDetailsReportTabProps) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [openUserListModal, setOpenUserListModal] = useState(false);
  const [openDeleteUserListModal, setOpenDeleteUserListModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false); // 로딩 상태 추가

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosInstance.get(
          `api/users?feederId=${feeder.id}`
        );
        setUsers(
          response.data.response.filter(
            (user: User) => user.role !== 'ROLE_ADMIN'
          )
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getUsers);
          } else {
            console.error('통신에러:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    getUsers();
  }, []);

  const table = useAdvanceTable<User>({
    data: users,
    columns: feederDetailsUserColumns,
    pageSize: 5,
    pagination: true,
    sortable: true,
    selection: true
  });

  // 선택된 행이 변경될 때 상태 업데이트
  useEffect(() => {
    setSelectedUsers(table.getSelectedRowModel().rows.map(row => row.original));
  }, [table.getSelectedRowModel().rows]);
  //
  useEffect(() => {
    if (selectedUsers.length > 0) {
      console.log(selectedUsers);
    }
  }, [selectedUsers]);

  const removeManager = async () => {
    const formData = new FormData();
    selectedUsers.map((user, index) => {
      formData.append(`deleteUserList[${index}]`, JSON.stringify(user));
    });

    const deleteUserList = selectedUsers.map(user => ({
      id: user.id,
      username: user.username,
      nick: user.nick,
      role: user.role,
      vendor: user.vendor,
      affiliation: user.affiliation,
      useYn: user.useYn,
      status: user.status
    }));

    try {
      const response = await axiosInstance.post(
        `api/feeder-user/${feeder.id}/assignment/cancel`,
        {
          deleteUserList
        }
      );

      if (response.data) {
        setShowSuccessModal(true);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, removeManager);
        } else {
          console.error('관리자 삭제 에러:', error);
        }
      } else {
        console.error('관리자 삭제 에러:', error);
      }
      return false;
    }
  };

  const handleSuccessModalClose = () => {
    setLoading(false);
    setShowSuccessModal(false);
    navigate(0);
  };

  return (
    <div>
      <Row className="d-flex justify-content-between align-items-center mb-4">
        <Col md="auto">
          <h2 className="mb-0">관리자</h2>
        </Col>
        <Col md="auto" className="d-flex">
          <Button
            variant="danger"
            size="sm"
            className="me-2"
            disabled={selectedUsers.length === 0}
            onClick={() => setOpenDeleteUserListModal(true)}
          >
            관리자 삭제
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => setOpenUserListModal(true)}
          >
            관리자 배정
          </Button>
        </Col>

        {/*<Row className="align-items-center justify-content-between g-3 mb-4">*/}
        {/*  <Col md="auto">*/}
        {/*    <h2 className="mb-0">관리자</h2>*/}
        {/*  </Col>*/}
        {/*  <Col md="auto" className="d-flex">*/}
        {/*    <Button*/}
        {/*      variant="primary"*/}
        {/*      size="sm"*/}
        {/*      onClick={() => setOpenUserListModal(true)}*/}
        {/*    >*/}
        {/*      관리자 배정*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      variant="primary"*/}
        {/*      size="sm"*/}
        {/*      onClick={() => setOpenUserListModal(true)}*/}
        {/*    >*/}
        {/*      관리자 배정*/}
        {/*    </Button>*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </Row>
      <AdvanceTableProvider {...table}>
        <FeederDetailsUserTable />
      </AdvanceTableProvider>
      <DeleteModal
        show={openDeleteUserListModal}
        title={'관리자 삭제'}
        body={'해당 관리자를 정말로 삭제하시겠습니까?'}
        handleCancel={() => setOpenDeleteUserListModal(false)}
        handleSubmit={removeManager}
      />
      <InfoModal
        show={showSuccessModal}
        title="성공"
        body={'관리자 삭제 완료'}
        handleClose={handleSuccessModalClose}
      />
      <UserListModal
        show={openUserListModal}
        handleClose={() => setOpenUserListModal(false)}
        feeder={feeder}
      />
    </div>
  );
};

export default FeederDetailsUserTab;
