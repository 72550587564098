import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useEffect, useState } from 'react';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ReportTable, {
  reportListTableColumns
} from '../../components/tables/ReportTable';
import ReportTopSection from '../../components/modules/manage/ReportTopSection';
import { Report } from '../../data/report';
import { Col, Row, Spinner } from "react-bootstrap";
import ReportCard from "../../components/cards/ReportCard";
import AdvanceTableFooter from "../../components/base/AdvanceTableFooter";

const Reports = () => {
  const navigate = useNavigate();
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await axiosInstance.get('api/reports');
        setReports(response.data.response.content);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getReports);
          } else {
            console.error('서버 에러', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getReports();
  }, []);

  const table = useAdvanceTable<Report>({
    data: reports,
    columns: reportListTableColumns,
    pageSize: 12,
    pagination: true,
    sortable: true
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    // <div>
    //   <div className="mb-5"></div>
    //   <AdvanceTableProvider {...table}>
    //     <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
    //       <h2 className="mb-0">
    //         <span className="me-3">일지 목록</span>{' '}
    //         <span className="fw-normal text-body-tertiary">
    //           ({reports.length})
    //         </span>
    //       </h2>
    //       <Link className="btn btn-primary px-5" to="/report-form">
    //         <FontAwesomeIcon icon={faPlus} className="me-2" />
    //         일지 추가
    //       </Link>
    //     </div>
    //     <ReportTopSection />
    //     <ReportTable />
    //   </AdvanceTableProvider>
    // </div>
    <div>
      <div className="mb-5"></div>
      <AdvanceTableProvider {...table}>
        <div className="mb-9">
          <h2 className="mb-4">일지</h2>
          <ReportTopSection />
          <Row className="g-3">
            {table
              .getRowModel()
              .rows.map(row => row.original)
              .map(report => (
                <Col xl={4} key={report.id}>
                  <ReportCard report={report} />
                </Col>
              ))}
          </Row>
          <AdvanceTableFooter pagination className="mt-2 pb-0" />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default Reports;
