import EcomTopCouponsChart from 'components/charts/e-charts/EcomTopCouponsChart';
import { Card } from 'react-bootstrap';

const EcomTopCouponsCard = () => {
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-2">개체 성비</h5>
            <h6 className="text-body-tertiary">
              전체 개소 길고양이 개체들의 성비를 나타냅니다
            </h6>
          </div>
        </div>
        <div className="pb-4 pt-3">
          <EcomTopCouponsChart />
        </div>
        <div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">암컷</h6>
            <h6 className="text-body fw-semibold mb-0">51%</h6>
          </div>
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary-lighter me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">수컷</h6>
            <h6 className="text-body fw-semibold mb-0">49%</h6>
          </div>
          {/*<div className="d-flex align-items-center">*/}
          {/*  <div className="bullet-item bg-info-dark me-2"></div>*/}
          {/*  <h6 className="text-body fw-semibold flex-1 mb-0">*/}
          {/*    Fixed product discount*/}
          {/*  </h6>*/}
          {/*  <h6 className="text-body fw-semibold mb-0">10%</h6>*/}
          {/*</div>*/}
        </div>
      </Card.Body>
    </Card>
  );
};

export default EcomTopCouponsCard;
