import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Tnr } from '../../data/tnr';
import TnrTable, {
  tnrListTableColumns
} from '../../components/tables/TnrTable';
import TnrTopSection from '../../components/modules/manage/TnrTopSection';
import TnrFormModal from '../../components/modals/TnrFormModal';

const TnrList = () => {
  const navigate = useNavigate();
  const [tnrList, setTnrList] = useState<Tnr[]>([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const getTnrList = async () => {
      try {
        const response = await axiosInstance.get('api/tnr');
        setTnrList(response.data.response.content);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getTnrList);
          } else {
            console.error('서버 에러', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getTnrList();
  }, []);

  const table = useAdvanceTable<Tnr>({
    data: tnrList,
    columns: tnrListTableColumns,
    pageSize: 6,
    pagination: true,
    sortable: true
  });

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  return (
    <div>
      {/*<PageBreadcrumb items={defaultBreadcrumbItems} />*/}
      <div className="mb-5"></div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center justify-content-between">
          <h2 className="mb-0">
            <span className="me-3">TNR 신청 목록</span>{' '}
            {/*<span className="fw-normal text-body-tertiary">*/}
            {/*  ({tnrList.length})*/}
            {/*</span>*/}
          </h2>
          {/*<Link className="btn btn-primary px-5" to="/tnr-form">*/}
          {/*  <FontAwesomeIcon icon={faPlus} className="me-2" />*/}
          {/*  TNR 신청*/}
          {/*</Link>*/}
          <Button
            className="btn btn-primary px-5"
            onClick={() => setOpenModal(true)}
          >
            <FontAwesomeIcon icon={faPlus} className="me-2" />
            TNR 신청
          </Button>
        </div>
        <TnrTopSection />
        <TnrTable />
      </AdvanceTableProvider>
      <TnrFormModal
        show={openModal}
        handleClose={() => setOpenModal(false)}
        tnr={{}}
      />
    </div>
  );
};

export default TnrList;
