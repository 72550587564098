import classNames from 'classnames';
import {
  Accept,
  DropEvent,
  FileRejection,
  DropzoneProps as ReactDropZoneProps,
  useDropzone
} from 'react-dropzone';
import imageIcon from 'assets/img/icons/image-icon.png';
import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { convertFileToAttachment } from 'helpers/utils';
import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import { Col, Row } from 'react-bootstrap';
import { FileAttachment } from 'components/common/AttachmentPreview';

interface DropzoneProps {
  className?: string;
  size?: 'sm';
  reactDropZoneProps?: ReactDropZoneProps;
  accept?: Accept;
  noPreview?: boolean;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  multiple?: boolean;
  onRemoveFile?: (file: File) => void; // 파일이 제거될 때 호출할 콜백 추가
  initialFiles?: File[];
}

const TnrDropzone = ({
  className,
  size,
  onDrop,
  accept,
  noPreview,
  reactDropZoneProps,
  onRemoveFile,
  initialFiles = [],
  children,
  multiple
}: PropsWithChildren<DropzoneProps>) => {
  const [files, setFiles] = useState<File[]>(initialFiles!);
  const [previews, setPreviews] = useState<FileAttachment[]>(
    initialFiles!.map(file => convertFileToAttachment(file))
  );

  useEffect(() => {
    setFiles(initialFiles!);
    setPreviews(initialFiles!.map(file => convertFileToAttachment(file)));
  }, [initialFiles]);

  const handleRemoveFile = (index: number) => {
    const fileToRemove = files[index];
    setFiles(files.filter((file, ind) => index !== ind));
    setPreviews(previews.filter((file, ind) => index !== ind));
    if (onRemoveFile) {
      onRemoveFile(fileToRemove); // 파일이 제거될 때 콜백 호출
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, fileRejections, event) => {
      const newFiles: File[] = [];

      if (multiple === false && acceptedFiles.length > 0) {
        newFiles.push(acceptedFiles[0]);
      } else {
        newFiles.push(...acceptedFiles.slice(0, 3));
      }

      setFiles(newFiles);
      setPreviews(newFiles.map(file => convertFileToAttachment(file)));

      if (onDrop) {
        onDrop(acceptedFiles, fileRejections, event);
      }
    },
    accept,
    multiple,
    ...reactDropZoneProps
  });

  const imageOnly = useMemo(() => {
    return Boolean(accept && accept['image/*']);
  }, [accept]);

  return (
    <>
      <Row>
        <Col sm={3}>
          <div
            {...getRootProps()}
            className={classNames(className, 'dropzone', {
              'dropzone-sm': size === 'sm'
            })}
            style={{
              height: '150px'
            }}
          >
            <input {...getInputProps()} />
            {children ? (
              <>{children}</>
            ) : (
              <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
                <img
                  src={imageIcon}
                  width={classNames({ 24: size === 'sm', 40: size !== 'sm' })}
                  alt=""
                />
              </div>
            )}
          </div>
        </Col>

        {imageOnly && !noPreview && files.length > 0 && (
          <>
            {files.map((file, index) => (
              <Col sm={3} key={file.name}>
                <div
                  className="d-flex flex-wrap gap-2 mb-2"
                  style={{
                    height: '150px'
                  }}
                >
                  <ImageAttachmentPreview
                    image={URL.createObjectURL(file)}
                    handleClose={() => handleRemoveFile(index)}
                  />
                </div>
              </Col>
            ))}
          </>
        )}
      </Row>
    </>
  );
};

export default TnrDropzone;
