import { useWizardFormContext } from 'providers/WizardFormProvider';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'components/base/DatePicker';
import { Tnr } from '../../data/tnr';
import axiosInstance from '../../helpers/apiService';
import { useNavigate } from 'react-router-dom';
import DaumPostModal from '../modals/DaumPostModal';
import { Address } from 'react-daum-postcode';
import TnrDropzone from '../base/TnrDropzone';
import fetchDropzonePreview from '../../helpers/fetchDropzonePreivew';

const TnrTrapForm = () => {
  const navigate = useNavigate();
  const methods = useWizardFormContext<Tnr>();
  const { formData, setFormData, onChange, validation } = methods;
  const [showPostcode, setShowPostcode] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  useEffect(() => {
    if (!formData?.tnrTFileList || formData.tnrTFileList.length === 0) return;

    fetchDropzonePreview(axiosInstance, formData.tnrTFileList, navigate).then(
      value => {
        setFormData(prevState => ({
          ...prevState,
          selectedTnrTFileList: value || []
        }));
        setSelectedFiles(value || []);
      }
    );
  }, [formData.tnrTFileList]);

  const onDrop = (acceptedFiles: File[]) => {
    setFormData(prevState => ({
      ...prevState,
      selectedTnrTFileList: acceptedFiles
    }));
  };

  const handleRemoveFile = (file: File) => {
    setFormData(prevState => ({
      ...prevState,
      selectedTnrTFileList: prevState.selectedTnrTFileList!.filter(
        f => f.name !== file.name
      )
    }));
  };

  const handleAddressComplete = async (data: Address) => {
    setFormData(prevState => ({
      ...prevState,
      trapLocation: data.address
    }));
    setShowPostcode(false);
  };

  const handleDateChange = (
    date: Date[],
    dateType: 'trapDate' | 'neuterDate' | 'returnDate'
  ) => {
    const kstDate = new Date(date[0].getTime() + 9 * 60 * 60 * 1000); // UTC+9로 변환
    setFormData(prevState => ({
      ...prevState,
      [dateType]:
        kstDate.toISOString().split('T')[0] +
        ' ' +
        kstDate.toISOString().split('T')[1].split('.')[0]
    }));
  };

  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <TnrDropzone
            multiple={false}
            className="mb-3"
            accept={{
              'image/*': ['.png', '.gif', '.jpeg', '.jpg']
            }}
            onDrop={onDrop}
            onRemoveFile={handleRemoveFile}
            initialFiles={selectedFiles}
          />
        </Col>
      </Row>

      <Row className="g-3">
        <Col sm={4}>
          <Form.Group className="mb-2">
            <Form.Label className="text-body fs-9">성별 *</Form.Label>
            <Form.Select
              name="gender"
              value={formData.gender || ''}
              onChange={onChange}
              required={validation}
            >
              <option value="">선택</option>
              <option value="male">수컷</option>
              <option value="female">암컷</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              성별을 선택해 주세요
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-2">
            <Form.Label className="text-body fs-9">몸무게 *</Form.Label>
            <Form.Control
              id="weight"
              type="text"
              name="weight"
              placeholder="몸무게 (단위 생략)"
              value={formData.weight || ''}
              onChange={onChange}
              required={validation}
            />
            <Form.Control.Feedback type="invalid">
              몸무게를 입력해 주세요
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-2">
            <Form.Label className="text-body fs-9">추정 연령 *</Form.Label>
            <Form.Control
              id="age"
              type="text"
              name="age"
              placeholder="추정 연령"
              value={formData.age || ''}
              onChange={onChange}
              required={validation}
            />
            <Form.Control.Feedback type="invalid">
              추정 연령을 입력해 주세요
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row className="g-3">
        <Col sm={6}>
          <Form.Group className="mb-2">
            <Form.Label className="text-body fs-9">포획 장소 *</Form.Label>
            <Form.Control
              type="text"
              name="trapLocation"
              placeholder="도로명 주소"
              value={formData.trapLocation || ''}
              onChange={onChange}
              required={validation}
              onClick={() => setShowPostcode(true)}
              // readOnly
              tabIndex={-1}
            />
            <Form.Control.Feedback type="invalid">
              포획 장소를 입력해 주세요
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <DatePicker
            onChange={date => handleDateChange(date, 'trapDate')}
            value={formData.trapDate ? new Date(formData.trapDate) : ''}
            hideIcon
            render={(_, ref) => (
              <Form.Group className="mb-2">
                <Form.Label className="text-body fs-9">포획 일자 *</Form.Label>
                <Form.Control
                  id="trapDate"
                  type="text"
                  placeholder="포획 일자"
                  ref={ref}
                  value={formData.trapDate || ''}
                  readOnly
                />
                <Form.Control.Feedback type="invalid">
                  포획 일자를 입력해 주세요
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        </Col>
      </Row>

      <Form.Group className="mb-2">
        <Form.Label className="text-body fs-9">특이사항</Form.Label>
        <Form.Control
          as="textarea"
          value={formData.significant || ''}
          onChange={onChange}
          rows={4}
          // required={validation}
        />
      </Form.Group>
      <DaumPostModal
        show={showPostcode}
        handleClose={() => setShowPostcode(false)}
        handleComplete={handleAddressComplete}
      />
    </>
  );
};

export default TnrTrapForm;
