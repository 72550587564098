import { useNavigate } from 'react-router-dom';
import { PropsWithChildren, useEffect } from 'react';
import axios from 'axios';
import axiosInstance, { refreshToken } from '../helpers/apiService';

const ProtectedRouteLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');

    const verifyToken = async () => {
      try {
        await axiosInstance.get('/token-verify');
      } catch (error) {
        console.error('Token verification failed', error);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate);
          } else {
            console.error('Login error:', error);
            localStorage.clear();
            navigate('/auth/sign-in');
          }
        } else {
          console.error('Unexpected error:', error);
          localStorage.clear();
          navigate('/auth/sign-in');
        }
      }
    };

    if (accessToken) {
      verifyToken();
    } else {
      navigate('/auth/sign-in');
    }
  }, []); // 의존성 배열 추가

  // children을 반환하여 내부에 컴포넌트를 포함할 수 있도록 함
  return <div>{children}</div>;
};

export default ProtectedRouteLayout;
