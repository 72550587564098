import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';
import MainLayoutProvider from 'providers/MainLayoutProvider';
import Error404 from 'pages/error/Error404';
import App from 'App';
import SignIn from './pages/auth/SignIn';
import TrapSchedules from './pages/manage/TrapSchedules';
import ProtectedRouteLayout from './layouts/ProtectedRouteLayout';
import Feeders from './pages/feeder/Feeders';
import FeederForm from './pages/feeder/FeederForm';
import Reports from './pages/manage/Reports';
import ReportForm from './pages/manage/ReportForm';
import Traps from './pages/manage/Traps';
import TrapForm from './pages/manage/TrapForm';
import TrapScheduleForm from './pages/manage/TrapScheduleForm';
import Dashboard from './pages/dashboard';
import NoticeForm from './pages/notice/NoticeForm';
import NoticeList from './pages/notice/NoitceList';
import TnrForm from './pages/manage/TnrForm';
import TnrList from './pages/manage/TnrList';
import Members from './pages/members/Members';
import MemberForm from './pages/members/MemberForm';
import MemberDetails from './pages/members/MemberDetails';
import FeederDetails from "./pages/feeder/FeederDetails";

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <ProtectedRouteLayout>
            <MainLayoutProvider>
              <MainLayout />
            </MainLayoutProvider>
          </ProtectedRouteLayout>
        ),
        children: [
          {
            index: true,
            element: <Dashboard />
          },
          {
            path: '/feeders',
            element: <Feeders />,
            children: []
          },
          {
            path: '/feeder-form',
            element: <FeederForm />,
            children: []
          },
          {
            path: '/feeder-details',
            element: <FeederDetails />
          },
          {
            path: '/reports',
            element: <Reports />,
            children: []
          },
          {
            path: '/report-form',
            element: <ReportForm />,
            children: []
          },
          {
            path: '/traps',
            element: <Traps />,
            children: []
          },
          {
            path: '/trap-form',
            element: <TrapForm />,
            children: []
          },
          {
            path: '/trap-schedules',
            element: <TrapSchedules />,
            children: []
          },
          {
            path: '/trap-schedule-form',
            element: <TrapScheduleForm />,
            children: []
          },
          {
            path: '/tnr',
            // element: <ComingSoon />,
            element: <TnrList />,
            children: []
          },
          {
            path: '/tnr-form',
            element: <TnrForm />,
            children: []
          },
          {
            path: '/notice',
            element: <NoticeList />
          },
          {
            path: '/notice-form',
            element: <NoticeForm />,
            children: []
          },
          {
            path: '/members',
            element: <Members />
          },
          {
            path: '/member-form',
            element: <MemberForm />
          },
          {
            path: '/member-details',
            element: <MemberDetails />
          }
        ]
      },
      {
        path: '/auth',
        children: [
          {
            path: 'sign-in',
            element: <SignIn />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
