import { Dropdown } from 'react-bootstrap';
import { Notice } from '../../data/notice';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { User } from '../../data/users';

interface UserProps {
  user: User;
}

const ActionUserDropdownItems = ({ user }: UserProps) => {
  const navigate = useNavigate();

  const deleteUser = async () => {
    try {
      await axiosInstance.delete(`api/users/${user!.id}`);
      navigate(0);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, deleteUser);
          navigate(0);
        } else {
          console.error('회원 삭제 에러:', error);
        }
      } else {
        console.error('회원 삭제 에러:', error);
      }
    }
  };

  return (
    <>
      <Dropdown.Item className="text-danger" onClick={deleteUser}>
        삭제
      </Dropdown.Item>
    </>
  );
};

export default ActionUserDropdownItems;
