import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import MemberTable, {
  memberTableColumns
} from '../../components/tables/MemberTable';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import { User } from '../../data/users';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

const Members = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosInstance.get('api/users');
        setUsers(
          response.data.response.filter(
            (user: User) => user.role !== 'ROLE_ADMIN'
          )
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getUsers);
          } else {
            console.error('통신에러:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    getUsers();
  }, []);

  const table = useAdvanceTable<User>({
    data: users,
    columns: memberTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    initialState: {
      columnVisibility: {
        status: false,
        designation: false
      }
    }
  });
  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };
  return (
    <div>
      <div className="mb-6">
        <AdvanceTableProvider {...table}>
          <div className="mb-5"></div>
          <h2 className="mb-4">회원 {users.length}명</h2>
          <Row className="g-3 justify-content-between mb-4">
            <Col xs="auto">
              <Link className="btn btn-primary px-5" to="/member-form">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                회원 추가
              </Link>
            </Col>
            <Col xs="auto">
              <div className="d-flex">
                <SearchBox
                  placeholder="회원 이름 검색"
                  className="me-2"
                  onChange={handleSearchInputChange}
                />
              </div>
            </Col>
          </Row>
          <MemberTable />
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Members;
