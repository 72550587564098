import { Dropdown, Modal } from 'react-bootstrap';
import FeederFormModal from '../modals/FeederFormModal';
import React, { useState } from 'react';
import { Feeder } from '../../data/feeder';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Button from '../base/Button';
import InfoModal from '../modals/InfoModal';

interface FeederProps {
  feeder?: Feeder;
}

const ActionDropdownItems = ({ feeder }: FeederProps) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleDelete = async () => {
    // setDeleteOpenModal(true);
    // await deleteFeeder();
    setLoading(true);
    deleteFeeder();
  };

  const deleteFeeder = async () => {
    const formData = new FormData();

    const userId = localStorage.getItem('userId');

    feeder!.uptId = userId!;
    feeder!.deleted = 'Y';

    Object.entries(feeder!).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      // await axiosInstance.put(`api/feeders/${feeder!.id}`, formData);
      await axiosInstance.delete(`api/feeders/${feeder!.id}`);
      setShowSuccessModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, deleteFeeder);
        } else {
          console.error('Feeder 삭제 에러:', error);
        }
      } else {
        console.error('Feeder 삭제 에러:', error);
      }
    }
  };

  const handleSuccessModalClose = () => {
    setDeleteOpenModal(false);
    setShowSuccessModal(false);
    navigate(0);
  };

  return (
    <>
      <Dropdown.Item eventKey="1" onClick={() => setOpenModal(true)}>
        수정
      </Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item
        eventKey="4"
        className="text-danger"
        onClick={() => setDeleteOpenModal(true)}
      >
        삭제
      </Dropdown.Item>
      <FeederFormModal
        show={openModal}
        handleClose={() => setOpenModal(false)}
        feeder={feeder}
      />
      <Modal
        show={openDeleteModal}
        centered
        backdrop="static"
        onHide={() => setDeleteOpenModal(false)}
        contentClassName="bg-body-highlight p-6 border border-translucent"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">급식소 삭제</h3>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <span className="text-primary">{feeder?.name}</span> 급식소를 정말로
          삭제하시겠습니까?
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-primary px-3 my-0"
            onClick={() => setDeleteOpenModal(false)}
            disabled={loading}
          >
            취소
          </Button>
          <Button
            variant="danger"
            className="my-0"
            loading={loading}
            loadingPosition={'start'}
            onClick={handleDelete}
          >
            삭제
          </Button>
        </Modal.Footer>
      </Modal>
      <InfoModal
        show={showSuccessModal}
        title="성공"
        body={'급식소 삭제 성공'}
        handleClose={handleSuccessModalClose}
      />
    </>
  );
};

export default ActionDropdownItems;
