import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Button from 'components/base/Button';
import { useWizardFormContext } from 'providers/WizardFormProvider';
import React, { useEffect, useState } from 'react';
import SaveModal from '../modals/SaveModal';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Tnr } from '../../data/tnr';
import InfoModal from '../modals/InfoModal';
import { AttachFile } from '../../pages/manage/ReportForm';

const WizardFormFooter = ({
  className,
  nextBtnLabel = '저장',
  handleSubmit,
  updateMode
}: {
  className?: string;
  nextBtnLabel?: string;
  handleSubmit?: () => void;
  updateMode: boolean;
}) => {
  const navigate = useNavigate();
  const {
    selectedStep,
    goToStep,
    getCanNextPage,
    getCanPreviousPage,
    formData,
    setFormData,
    formCheck
  } = useWizardFormContext();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);

  useEffect(() => {
    if (fileUploadSuccess) {
      saveTnr();
      setFileUploadSuccess(false);
    }
  }, [fileUploadSuccess]);

  const saveTnr = async () => {
    const sendFormData = new FormData();
    const data = formData as Tnr;

    console.log(data);

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          sendFormData.append(`${key}[${index}]`, item);
        });
      } else {
        sendFormData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/tnr/${data.id}`, sendFormData);
      } else {
        await axiosInstance.post('api/tnr', sendFormData);
      }

      setModalTitle('성공');
      setModalBody(updateMode ? 'TNR 신청 수정 완료' : 'TNR 신청 완료');
      setShowModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveTnr);
        } else {
          console.error('TNR 신청서 저장 에러:', error);
          setLoading(false);
        }
      } else {
        console.error('TNR 신청서 저장 에러:', error);
        setLoading(false);
      }
    }
  };

  const handleFileUpload = async (
    selectedFileList: File[],
    existingFileList: AttachFile[],
    listType: 'T' | 'N' | 'R'
  ): Promise<boolean> => {
    const sendFormData = new FormData();
    selectedFileList.forEach(file => {
      sendFormData.append('files', file);
    });

    if (existingFileList.length > 0) {
      existingFileList.map((file, index) => {
        sendFormData.append(`deleteFileList[${index}]`, file.id);
      });

      try {
        await axiosInstance.post('api/file-delete', sendFormData);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, () =>
              handleFileUpload(selectedFileList, existingFileList, listType)
            );
          } else {
            console.error('파일 삭제 에러:', error);
          }
        } else {
          console.error('파일 삭제 에러:', error);
        }
        return false;
      }
    }

    try {
      const response = await axiosInstance.post(
        'api/file-upload',
        sendFormData
      );
      const filesData: AttachFile[] = response.data.response;

      setFormData((prevState: Tnr) => ({
        ...prevState,
        [`addTnr${listType}FileList`]: [
          ...(prevState[`addTnr${listType}FileList`] || []),
          ...filesData.map(file => file.id)
        ]
      }));

      setFileUploadSuccess(true);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, () =>
            handleFileUpload(selectedFileList, existingFileList, listType)
          );
        } else {
          console.error('파일 업로드 에러:', error);
        }
      } else {
        console.error('파일 업로드 에러:', error);
      }
      return false;
    }
  };

  return (
    <div
      className={classNames(className, 'd-flex justify-content-between mb-0')}
    >
      {/*<Button*/}
      {/*  variant="link"*/}
      {/*  className={classNames('p-0', {*/}
      {/*    'd-none': !getCanPreviousPage*/}
      {/*  })}*/}
      {/*  startIcon={<FontAwesomeIcon icon={faChevronLeft} className="fs-10" />}*/}
      {/*  onClick={() => goToStep(selectedStep - 1)}*/}
      {/*>*/}
      {/*  이전*/}
      {/*</Button>*/}
      <Button
        loading={loading}
        loadingPosition={'start'}
        disabled={loading}
        variant="primary"
        className={classNames('ms-auto px-6')}
        // endIcon={<FontAwesomeIcon icon={faChevronRight} className="fs-10" />}
        onClick={() => {
          const data = formData as Tnr;

          // TNR 신청할때 다음버튼 누르면 다음페이지 바로가는게아니라 저장 이후 다음 스텝 갈수있도록
          if (!getCanPreviousPage && !updateMode) {
            if (formCheck()) {
              setOpenModal(true);
            }
            return;
          }

          if (!formCheck()) {
            return;
          }

          if (
            (selectedStep as number) === 2 &&
            (data.selectedTnrTFileList?.length ?? 0) > 0
          ) {
            handleFileUpload(
              data.selectedTnrTFileList ?? [],
              data.tnrTFileList ?? [],
              'T'
            );
          } else {
            saveTnr();
            return;
          }

          if (
            (selectedStep as number) === 3 &&
            (data.selectedTnrNFileList?.length ?? 0) > 0
          ) {
            handleFileUpload(
              data.selectedTnrNFileList ?? [],
              data.tnrNFileList ?? [],
              'N'
            );
          } else {
            saveTnr();
            return;
          }

          if (
            (selectedStep as number) === 4 &&
            (data.selectedTnrRFileList?.length ?? 0) > 0
          ) {
            handleFileUpload(
              data.selectedTnrRFileList ?? [],
              data.tnrRFileList ?? [],
              'R'
            );
          } else {
            saveTnr();
            return;
          }

          // if (getCanNextPage) {
          //   goToStep(selectedStep + 1);
          // } else {
          //   if (handleSubmit) {
          //     handleSubmit();
          //   }
          // }
        }}
      >
        {nextBtnLabel}
      </Button>
      <InfoModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        handleClose={() => navigate(0)}
      />
      <SaveModal
        show={openModal}
        title={'TNR 예약하기'}
        body={'신청하신 주소로 TNR예약을 진행하시겠습니까?'}
        handleCancel={() => setOpenModal(false)}
        handleSubmit={saveTnr}
      />
    </div>
  );
};

export default WizardFormFooter;
