import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Report } from '../../data/report';
import FeederDetailsReportTable, {
  feederDetailsReportColumns
} from 'components/tables/FeederDetailsReportTable';
import SearchBox from 'components/common/SearchBox';
import { Col, Row } from 'react-bootstrap';
import Button from '../base/Button';
import ReportFormModal from '../modals/ReportFormModal';

interface FeederDetailsReportTabProps {
  feederId: string;
}

const FeederDetailsReportTab = ({ feederId }: FeederDetailsReportTabProps) => {
  const navigate = useNavigate();
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const getReports = async () => {
      try {
        const response = await axiosInstance.get(
          `api/reports?feederId=${feederId}`
        );
        setReports(response.data.response.content);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getReports);
          } else {
            console.error('서버 에러', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getReports();
  }, [feederId]);

  const table = useAdvanceTable<Report>({
    data: reports,
    columns: feederDetailsReportColumns,
    pageSize: 5,
    pagination: true,
    sortable: true,
    initialState: {
      columnVisibility: {
        type: false
      }
    }
  });

  const { setGlobalFilter } = table;

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <Row className="d-flex justify-content-between align-items-center mb-4">
        <Col md="auto">
          <h2 className="mb-0">일지</h2>
        </Col>
        <Col md="auto" className="d-flex">
          <Button
            variant="primary"
            size="sm"
            onClick={() => setOpenModal(true)}
          >
            일지 작성
          </Button>
        </Col>
      </Row>
      <AdvanceTableProvider {...table}>
        <SearchBox
          onChange={handleSearchInputChange}
          placeholder="일지 제목 검색"
          className="w-100 mb-3"
        />
        <FeederDetailsReportTable />
      </AdvanceTableProvider>
      {feederId && (
        <ReportFormModal
          show={openModal}
          handleClose={() => setOpenModal(false)}
          feederId={feederId}
        />
      )}
    </div>
  );
};

export default FeederDetailsReportTab;
