import classNames from 'classnames';
import {
  Accept,
  DropEvent,
  FileRejection,
  DropzoneProps as ReactDropZoneProps,
  useDropzone
} from 'react-dropzone';
import Button from './Button';
import imageIcon from 'assets/img/icons/image-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren, useEffect, useState } from 'react';
import AttachmentPreview, {
  FileAttachment
} from 'components/common/AttachmentPreview';
import { convertFileToAttachment } from 'helpers/utils';
// import ImageAttachmentPreview from 'components/common/ImageAttachmentPreview';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

interface DropzoneProps {
  className?: string;
  size?: 'sm';
  reactDropZoneProps?: ReactDropZoneProps;
  accept?: Accept;
  noPreview?: boolean;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  onRemoveFile?: (file: File) => void; // 파일이 제거될 때 호출할 콜백 추가
  initialFiles?: File[]; // Add this prop for initial files
}

const Dropzone = ({
  className,
  size,
  onDrop,
  accept,
  noPreview,
  onRemoveFile,
  reactDropZoneProps,
  initialFiles = [],
  children
}: PropsWithChildren<DropzoneProps>) => {
  const [files, setFiles] = useState<File[]>(initialFiles!);
  const [previews, setPreviews] = useState<FileAttachment[]>(
    initialFiles!.map(file => convertFileToAttachment(file))
  );

  useEffect(() => {
    setFiles(initialFiles!);
    setPreviews(initialFiles!.map(file => convertFileToAttachment(file)));
  }, [initialFiles]);

  const handleRemoveFile = (index: number) => {
    const fileToRemove = files[index];
    setFiles(files.filter((file, ind) => index !== ind));
    setPreviews(previews.filter((file, ind) => index !== ind));
    if (onRemoveFile) {
      onRemoveFile(fileToRemove); // 파일이 제거될 때 콜백 호출
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (...args) => {
      const [acceptedFiles] = args;
      setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
      setPreviews(prevPreviews => [
        ...prevPreviews,
        ...acceptedFiles.map(file => convertFileToAttachment(file))
      ]);
      if (onDrop) {
        onDrop(...args);
      }
    },
    accept,
    ...reactDropZoneProps
  });

  return (
    <>
      <div
        {...getRootProps()}
        className={classNames(className, 'dropzone', {
          'dropzone-sm': size === 'sm'
        })}
      >
        <input {...getInputProps()} />
        {children ? (
          <>{children}</>
        ) : (
          <div className="text-body-tertiary text-opacity-85 fw-bold fs-9">
            여기에 끌어다 놓거나{' '}
            <Button variant="link" className="p-0">
              장치에서 찾기
            </Button>
            <br />
            사진은 한장만 업로드 가능합니다
            <br />
            <img
              className="mt-3"
              src={imageIcon}
              width={classNames({ 24: size === 'sm', 40: size !== 'sm' })}
              alt=""
            />
          </div>
        )}
      </div>
      {files.length > 0 &&
        previews.map((file, index) => (
          <div
            key={index}
            className={classNames(
              'border-bottom border-translucent d-flex align-items-center justify-content-between py-3'
            )}
          >
            <AttachmentPreview attachment={file} />

            <button className="btn p-0" onClick={() => handleRemoveFile(index)}>
              <FontAwesomeIcon icon={faTrashAlt} className="fs-0 text-danger" />
            </button>
          </div>
        ))}
    </>
  );
};

export default Dropzone;
