import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from 'components/base/Button';
import ReactSelect from 'components/base/ReactSelect';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { Feeder } from 'data/feeder';
import Dropzone from 'components/base/Dropzone';
import { User } from 'data/users';
import axiosInstance, { refreshToken } from 'helpers/apiService';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import DaumPostModal from 'components/modals/DaumPostModal';
import { Address } from 'react-daum-postcode';
import { jsonpRequest } from 'helpers/jsonpHelper';
import { ActionMeta, MultiValue } from 'react-select';
import InfoModal from 'components/modals/InfoModal';

export interface AttachFile {
  id: string;
  originFileName: string;
  savedFileName: string;
  path: string;
  extension: string;
}

interface SelectOption {
  value: string;
  label: string;
}

const FeederForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formState, setFormState] = useState<Feeder>({
    id: '',
    name: '',
    address: '',
    type: '',
    latitude: 0,
    longitude: 0,
    deleted: 'N',
    publicStatus: '',
    addUserList: [],
    deleteUserList: [],
    addFileList: [],
    deleteFileList: [],
    fileList: []
  });

  const [userList, setUserList] = useState<User[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<SelectOption[]>([]);
  const [showPostcode, setShowPostcode] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [isUserFiltered, setIsUserFiltered] = useState(false);
  const [isFileUploadSuccess, setIsFileUploadSuccess] = useState(false);

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosInstance.get('api/users');
        setUserList(
          response.data.response.filter(
            (user: User) => user.username !== 'admin'
          )
        );
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getUsers);
          } else {
            console.error('통신에러:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    getUsers();
  }, [navigate]);

  useEffect(() => {
    if (location.state?.feeder) {
      setUpdateMode(true);
      const feederData = location.state.feeder;
      setFormState(feederData);
      setFormState(prevState => ({
        ...prevState,
        addUserList: feederData.userList!.map((user: User) => user.id)
      }));

      const selectedUsers = feederData
        .userList!.map((manager: User) => {
          const feederManager = userList.find(user => user.id === manager.id);
          return feederManager
            ? { value: feederManager.id, label: feederManager.nick }
            : null;
        })
        .filter(Boolean) as SelectOption[];

      setSelectedOptions(selectedUsers);

      // 파일 목록을 Dropzone에 추가
      const getFiles = async () => {
        const files = await Promise.all(
          feederData.fileList?.map(async (file: AttachFile) => {
            const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;
            try {
              const response = await axiosInstance.get(fileUrl, {
                responseType: 'blob',
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
              });

              const blob = response.data;
              return new File([blob], file.originFileName, { type: blob.type });
            } catch (error) {
              console.error('파일 로드 에러:', error);
              return null;
            }
          }) || []
        );
        setSelectedFiles(files.filter(file => file !== null) as File[]);
      };

      getFiles();
    }
  }, [location.state, userList]);

  useEffect(() => {
    if (isUserFiltered) {
      saveFeeder();
      setIsUserFiltered(false);
    }
  }, [formState, isUserFiltered]);

  useEffect(() => {
    if (isFileUploadSuccess) {
      if (updateMode) {
        userFilter();
      } else {
        saveFeeder();
      }
    }
  }, [isFileUploadSuccess]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleAddressClick = () => {
    setShowPostcode(true);
  };

  const handleAddressComplete = async (data: Address) => {
    setFormState(prevState => ({
      ...prevState,
      address: data.address
    }));
    setShowPostcode(false);
    try {
      const key = `${process.env.REACT_APP_VWORLD_KEY}`;
      const url = `https://api.vworld.kr/req/address?service=address&request=getcoord&version=2.0&crs=epsg:4326&address=${data.address}&refine=true&simple=true&format=json&type=road&key=${key}`;
      const response = await jsonpRequest(url, 'handleVworldResponse');
      if (
        response.response &&
        response.response.result &&
        response.response.result.point
      ) {
        const { x: longitude, y: latitude } = response.response.result.point;
        setFormState(prevState => ({
          ...prevState,
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude)
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectChange = (
    newValue: MultiValue<SelectOption>,
    actionMeta: ActionMeta<unknown>
  ) => {
    const selectedValues = newValue.map(option => option.value);
    setSelectedOptions(newValue as SelectOption[]);
    setFormState(prevState => ({
      ...prevState,
      addUserList: selectedValues
    }));
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFiles([]);
    setSelectedFiles(prevFiles => [...prevFiles, acceptedFiles[0]]);
  };

  const handleRemoveFile = (file: File) => {
    setSelectedFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
  };

  const handleFileUpload = async (): Promise<boolean> => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('files', file);
    });

    if (updateMode) {
      formState.fileList!.map((file, index) => {
        formData.append(`deleteFileList[${index}]`, file.id);
      });

      try {
        await axiosInstance.post('api/file-delete', formData);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, handleFileUpload);
          } else {
            console.error('파일 삭제 에러:', error);
          }
        } else {
          console.error('파일 삭제 에러:', error);
        }
        return false;
      }
    }

    try {
      const response = await axiosInstance.post('api/file-upload', formData);
      const filesData: AttachFile[] = response.data.response;

      setFormState(prevState => ({
        ...prevState,
        addFileList: [
          ...(prevState.addFileList || []),
          ...filesData.map(file => file.id)
        ]
      }));

      setIsFileUploadSuccess(true);

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, handleFileUpload);
        } else {
          console.error('파일 업로드 에러:', error);
        }
      } else {
        console.error('파일 업로드 에러:', error);
      }
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { name, address, type, latitude, longitude, publicStatus } =
      formState;

    if (
      !name ||
      !address ||
      !type ||
      latitude === 0 ||
      longitude === 0 ||
      !publicStatus
    ) {
      setShowModal(true);
      return;
    }

    if (selectedFiles.length > 0 || formState.fileList!.length > 0) {
      const uploadSuccess = await handleFileUpload();
      if (!uploadSuccess) {
        console.error('파일 업로드에 실패하여 급식소 저장을 중단합니다.');
      }
    } else {
      if (updateMode) {
        await userFilter();
      } else {
        await saveFeeder();
      }
    }
  };

  const saveFeeder = async () => {
    const formData = new FormData();

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/feeders/${formState.id}`, formData);
      } else {
        await axiosInstance.post('api/feeders', formData);
      }
      // 급식소 등록 성공 후 모달 띄우기
      setShowSuccessModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveFeeder);
        } else {
          console.error('Feeder 저장 에러:', error);
        }
      } else {
        console.error('Feeder 저장 에러:', error);
      }
    }
  };

  const userFilter = async () => {
    const initialUserList: string[] = location.state.feeder.userList.map(
      (user: User) => user.id
    );
    const currentAddUserList = formState.addUserList
      ? [...formState.addUserList]
      : [];
    const currentDeleteUserList = formState.deleteUserList
      ? [...formState.deleteUserList]
      : [];

    initialUserList.forEach(userId => {
      if (!currentAddUserList.includes(userId)) {
        currentDeleteUserList.push(userId);
      } else {
        const index = currentAddUserList.indexOf(userId);
        if (index > -1) {
          currentAddUserList.splice(index, 1);
        }
      }
    });

    setFormState(prevState => ({
      ...prevState,
      addUserList: currentAddUserList,
      deleteUserList: currentDeleteUserList
    }));

    setIsUserFiltered(true);
  };

  const handleSuccessModalClose = () => {
    setShowSuccessModal(false);
    navigate('/feeders');
  };

  return (
    <div>
      {/*<PageBreadcrumb items={defaultBreadcrumbItems} />*/}
      <div className="mb-5"></div>
      <h2 className="mb-4">{updateMode ? '급식소 수정' : '급식소 등록'}</h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6" onSubmit={handleSubmit}>
            <Col sm={6} md={8}>
              <FloatingLabel controlId="name" label="급식소명 (12자) *">
                <Form.Control
                  type="text"
                  placeholder="급식소명 (12)자 *"
                  value={formState.name}
                  onChange={handleChange}
                  maxLength={12}
                />
              </FloatingLabel>
            </Col>
            <Col sm={2} md={2}>
              <FloatingLabel controlId="type" label="타입 *">
                <Form.Select value={formState.type} onChange={handleChange}>
                  <option>선택</option>
                  <option value="PUBLIC">공공</option>
                  <option value="PRIVATE">사설</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={2} md={2}>
              <FloatingLabel controlId="publicStatus" label="공개여부 *">
                <Form.Select
                  value={formState.publicStatus}
                  onChange={handleChange}
                >
                  <option>선택</option>
                  <option value="ENABLED">공개</option>
                  <option value="DISABLED">비공개</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={8} className="gy-6">
              <FloatingLabel controlId="address" label="주소 *">
                <Form.Control
                  type="text"
                  placeholder="주소 *"
                  value={formState.address}
                  onChange={handleChange}
                  onClick={handleAddressClick}
                  readOnly
                />
              </FloatingLabel>
            </Col>
            <Col sm={2} md={2} className="gy-6">
              <FloatingLabel controlId="latitude" label="위도 *">
                <Form.Control
                  type="text"
                  disabled
                  placeholder="위도 *"
                  value={formState.latitude}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={2} md={2} className="gy-6">
              <FloatingLabel controlId="longitude" label="경도 *">
                <Form.Control
                  type="text"
                  disabled
                  placeholder="경도 *"
                  value={formState.longitude}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <ReactSelect
                isMulti
                placeholder="관리자"
                value={selectedOptions}
                options={userList.map(user => ({
                  value: user.id,
                  label: user.nick
                }))}
                classNames={{
                  control: () => 'py-3'
                }}
                onChange={(newValue, actionMeta) =>
                  handleSelectChange(
                    newValue as MultiValue<SelectOption>,
                    actionMeta
                  )
                }
              />
            </Col>
            <Col xs={12} className="gy-6">
              <Dropzone
                accept={{
                  'image/*': ['.png', '.gif', '.jpeg', '.jpg']
                }}
                onDrop={handleDrop}
                onRemoveFile={handleRemoveFile}
                initialFiles={selectedFiles}
              />
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="phoenix-primary"
                  className="px-5"
                  onClick={() => navigate('/feeders')}
                >
                  취소
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="px-5 px-sm-15"
                >
                  {updateMode ? '수정' : '등록'}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <DaumPostModal
        show={showPostcode}
        handleClose={() => setShowPostcode(false)}
        handleComplete={handleAddressComplete}
      />
      <InfoModal
        show={showModal}
        title="오류"
        body="*는 필수 입력값입니다"
        handleClose={() => setShowModal(false)}
      />
      <InfoModal
        show={showSuccessModal}
        title="성공"
        body={updateMode ? '급식소 수정 완료' : '급식소 등록 완료'}
        handleClose={handleSuccessModalClose}
      />
    </div>
  );
};

export default FeederForm;
