import dayjs from 'dayjs';

const currentDay = dayjs && dayjs().format('DD');
const currentMonth = dayjs && dayjs().format('MM');
const prevMonth = dayjs && dayjs().subtract(1, 'month').format('MM');
const nextMonth = dayjs && dayjs().add(1, 'month').format('MM');
const currentYear = dayjs && dayjs().format('YYYY');

export interface Schedule {
  id: string;
  title: string;
  start: string;
  end?: string;
  description: string;
  className: string;
}

export interface CalendarEvent {
  id: string;
  title: string;
  start: string | Date;
  end?: string | Date;
  description: string;
  className?: string;
  location?: string;
  organizer?: string;
  schedules?: Schedule[];
  allDay?: boolean;
  url?: string;
}

export const events: CalendarEvent[] = [];

export default events;
