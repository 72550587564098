import { Dropdown } from 'react-bootstrap';
import { Notice } from '../../data/notice';
import axiosInstance, { refreshToken } from "../../helpers/apiService";
import axios from "axios";
import { useNavigate } from "react-router-dom";

interface NoticeProps {
  notice: Notice;
}

const ActionNoticeDropdownItems = ({ notice }: NoticeProps) => {
  const navigate = useNavigate();

  const deleteNotice = async () => {
    try {
      await axiosInstance.delete(`api/notice/${notice!.id}`);
      navigate(0);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, deleteNotice);
          navigate(0);
        } else {
          console.error('공지사항 삭제 에러:', error);
        }
      } else {
        console.error('공지사항 삭제 에러:', error);
      }
    }
  };

  return (
    <>
      <Dropdown.Item className="text-danger" onClick={deleteNotice}>
        삭제
      </Dropdown.Item>
    </>
  );
};

export default ActionNoticeDropdownItems;
