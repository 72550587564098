import React from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV,
  faMapLocationDot,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { Feeder } from 'data/feeder';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import AvatarDropdown from 'components/common/AvatarDropdown';
import Avatar from 'components/base/Avatar';
import Badge, { BadgeBg } from '../../base/Badge';
import RevealDropdown from '../../base/RevealDropdown';
import ActionFeederDropdownItems from '../../dropdown/ActionFeederDropdownItems';

const CardViewItem = ({ feeder }: { feeder: Feeder }) => {
  const navigate = useNavigate();

  const getVariant = (percent: number): BadgeBg => {
    if (percent < 20) return 'danger';
    if (percent < 40) return 'warning';
    if (percent < 80) return 'primary';
    return 'success';
  };

  const getLabel = (percent: number): string => {
    if (percent < 20) return '조치 필요';
    if (percent < 40) return '권고 필요';
    if (percent < 80) return '양호';
    return '매우 양호';
  };

  return (
    <>
      <Card className="h-100 hover-actions-trigger">
        <Card.Body>
          <div className="d-flex align-items-center justify-content-between">
            <Link
              // to="/feeder-form"
              to="/feeder-details"
              state={{ feeder: feeder }}
              className="text-decoration-none fw-bold fs-7"
            >
              {feeder.name}
            </Link>
            <div className="z-2">
              <RevealDropdown btnClassName="btn-icon" icon={faEllipsisV}>
                <ActionFeederDropdownItems feeder={feeder} />
              </RevealDropdown>
            </div>
          </div>
          <Badge
            variant="phoenix"
            bg={getVariant(feeder.tnrRate!)}
            className="fs-10 mb-4"
          >
            {getLabel(feeder.tnrRate!)}
          </Badge>
          <div className="d-flex align-items-center mb-2">
            <FontAwesomeIcon
              icon={faUser}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              소유주 :{' '}
              <span className="fw-semibold text-primary ms-1">
                {' '}
                {/*{feeder.userList!.map(user => user.nick).join(' ')}*/}
                {feeder.owner}
              </span>
            </p>
          </div>
          <div className="d-flex align-items-center mb-4">
            <FontAwesomeIcon
              icon={faMapLocationDot}
              className="me-2 text-body-tertiary fs-9 fw-extra-bold"
            />
            <p className="fw-bold mb-0 text-truncate lh-1">
              주소 :{' '}
              <span className="text-body-emphasis ms-1">{feeder.address}</span>
            </p>
          </div>
          <div className="d-flex justify-content-between text-body-tertiary fw-semibold">
            <p className="mb-2"> 중성화율</p>
            <p className="mb-2 text-body-emphasis">{feeder.tnrRate}%</p>
          </div>
          <ProgressBar
            now={feeder.tnrRate}
            className={classNames(
              'flex-1',
              `bg-${getVariant(feeder.tnrRate ?? 0)}-subtle`
            )}
            variant={getVariant(feeder.tnrRate ?? 0)}
          />

          <div>
            <div className="d-flex align-items-center mt-4">
              <p className="mb-0 fw-bold fs-9">
                개설일자 :
                <span className="fw-semibold text-body-tertiary text-opactity-85 ms-1">
                  {feeder.createdDate!.split('T')[0]}
                </span>
              </p>
            </div>

            <div className="d-flex d-lg-block d-xl-flex justify-content-between align-items-center mt-3">
              <div className="d-flex gap-1">
                {feeder.userList && feeder.userList.length > 0 ? (
                  <Avatar.Group total={feeder.userList.length} size="m">
                    {feeder.userList.slice(0, 4).map(user => (
                      <AvatarDropdown user={user} size="m" key={user.id} />
                    ))}
                  </Avatar.Group>
                ) : (
                  <p className="mb-0 fw-semibold text-muted">관리자 미배정</p>
                )}
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default CardViewItem;
