import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import AppProvider from './providers/AppProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from './Routes';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppProvider>
      <BreakpointsProvider>
        <RouterProvider router={router} />
      </BreakpointsProvider>
    </AppProvider>
  </React.StrictMode>
);
