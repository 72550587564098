import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { User } from '../../data/users';
import Badge, { BadgeBg } from '../base/Badge';

enum AssignmentStatus {
  APPROVE_WAITING = '승인대기',
  APPROVE = '승인',
  APPROVE_DENIED = '승인거절',
  DELETED_WAITING = '삭제대기',
  DELETED = '삭제'
}

const getBadgeBg = (label: string): BadgeBg => {
  switch (label) {
    case 'APPROVE':
      return 'success';
    case 'APPROVE_WAITING':
      return 'primary';
    case 'DELETED_WAITING':
      return 'danger';
    default:
      return 'primary';
  }
};

export const feederDetailsUserColumns: ColumnDef<User>[] = [
  {
    header: '이름',
    accessorKey: 'nick',
    cell: ({ row: { original } }) => original.nick,
    meta: {
      headerProps: {
        style: { minWidth: 100, width: '15%' },
        className: 'text-start pe-6'
      },
      cellProps: {
        className: 'text-start fw-bold text-body-tertiary pe-6'
      }
    }
  },
  {
    header: '소속',
    accessorKey: 'affiliation',
    cell: ({ row: { original } }) => original.affiliation,
    meta: {
      headerProps: {
        style: { minWidth: 100, width: '15%' },
        className: 'text-start pe-6'
      },
      cellProps: {
        className: 'text-start fw-bold text-body-tertiary pe-6'
      }
    }
  },
  {
    header: '연락처',
    accessorKey: 'phone',
    cell: ({ row: { original } }) => '010-xxxx-xxxx',
    meta: {
      headerProps: {
        style: { minWidth: 100, width: '15%' },
        className: 'text-start pe-6'
      },
      cellProps: {
        className: 'text-start fw-bold text-body-tertiary pe-6'
      }
    }
  },
  {
    header: '이메일',
    accessorKey: 'username',
    cell: ({ row: { original } }) => original.username,
    meta: {
      headerProps: {
        style: { minWidth: 100, width: '15%' },
        className: 'text-start pe-6'
      },
      cellProps: {
        className: 'text-start fw-bold text-body-tertiary pe-6'
      }
    }
  },
  {
    header: '상태',
    accessorKey: 'status',
    cell: ({ row: { original } }) => (
      <Badge variant="phoenix" bg={getBadgeBg(original.status!)}>
        {AssignmentStatus[original.status as keyof typeof AssignmentStatus]}
      </Badge>
    ),
    meta: {
      headerProps: {
        style: { minWidth: 100, width: '15%' },
        className: 'text-start pe-6'
      },
      cellProps: {
        className: 'text-start fw-bold text-body-tertiary pe-6'
      }
    }
  },
  {
    header: '가입일',
    accessorKey: 'createdDate',
    cell: ({ row: { original } }) => {
      return (
        <>
          {original.createdDate!.split('T')[0]}{' '}
          {original.createdDate!.split('T')[1].split(':')[0]}
        </>
      );
    },
    meta: {
      headerProps: {
        style: { minWidth: 100, width: '15%' },
        className: 'text-start pe-6'
      },
      cellProps: {
        className: 'text-start fw-bold text-body-tertiary pe-6'
      }
    }
  }
];

const FeederDetailsUserTable = () => {
  return (
    <div>
      <div className="border-y border-translucent">
        <AdvanceTable tableProps={{ className: 'phoenix-table fs-9' }} />
        <AdvanceTableFooter pagination showViewAllBtn={false} />
      </div>
    </div>
  );
};

export default FeederDetailsUserTable;
