import { useWizardFormContext } from 'providers/WizardFormProvider';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'components/base/DatePicker';
import { Tnr } from '../../data/tnr';
import DaumPostModal from '../modals/DaumPostModal';
import axiosInstance from '../../helpers/apiService';
import { useNavigate } from 'react-router-dom';
import { Address } from 'react-daum-postcode';
import TnrDropzone from '../base/TnrDropzone';
import fetchDropzonePreview from '../../helpers/fetchDropzonePreivew';

const TnrReturnForm = () => {
  const navigate = useNavigate();
  const methods = useWizardFormContext<Tnr>();
  const { formData, setFormData, onChange, validation } = methods;
  const [showPostcode, setShowPostcode] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    // setSelectedFiles([]);
    // setSelectedFiles(prevFiles => [...prevFiles, acceptedFiles[0]]);
    setFormData(prevState => ({
      ...prevState,
      selectedTnrRFileList: acceptedFiles
    }));
  };

  useEffect(() => {
    if (!formData?.tnrRFileList || formData.tnrRFileList.length === 0) return;

    fetchDropzonePreview(axiosInstance, formData.tnrRFileList, navigate).then(
      value => {
        setFormData(prevState => ({
          ...prevState,
          selectedTnrRFileList: value || []
        }));
        setSelectedFiles(value || []);
      }
    );
  }, [formData.tnrRFileList]);

  const handleAddressComplete = async (data: Address) => {
    formData.trapLocation;
    setShowPostcode(false);
  };

  const handleRemoveFile = (file: File) => {
    setFormData(prevState => ({
      ...prevState,
      selectedTnrRFileList: prevState.selectedTnrRFileList!.filter(
        f => f.name !== file.name
      )
    }));
  };

  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <TnrDropzone
            multiple={false}
            className="mb-3"
            accept={{
              'image/*': ['.png', '.gif', '.jpeg', '.jpg']
            }}
            onDrop={onDrop}
            onRemoveFile={handleRemoveFile}
            initialFiles={selectedFiles}
          />
        </Col>
      </Row>

      <Row className="g-3">
        <Col sm={6}>
          <Form.Group className="mb-2">
            <Form.Label className="text-body fs-9">방사 장소</Form.Label>
            <Form.Control
              type="text"
              name="trapLocation"
              placeholder="도로명 주소"
              // value={formData.phone}
              onChange={onChange}
              required={validation}
              onClick={() => setShowPostcode(true)}
              readOnly
            />
            <Form.Control.Feedback type="invalid">
              This field is required.
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <DatePicker
            hideIcon
            render={(_, ref) => {
              return (
                <Form.Group className="mb-2">
                  <Form.Label className="text-body fs-9">방사 일자</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="방사 일자"
                    ref={ref}
                    // value={formData.dob}
                    name="dob"
                  />
                </Form.Group>
              );
            }}
          />
        </Col>
      </Row>

      <Form.Group className="mb-2">
        <Form.Label className="text-body fs-9">특이사항</Form.Label>
        <Form.Control
          as="textarea"
          // value={formData.address}
          onChange={onChange}
          rows={4}
          required={validation}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
      <DaumPostModal
        show={showPostcode}
        handleClose={() => setShowPostcode(false)}
        handleComplete={handleAddressComplete}
      />
    </>
  );
};

export default TnrReturnForm;
