import Avatar from 'components/base/Avatar';
import { Dropdown, Nav } from 'react-bootstrap';
import avatar from 'assets/img/team/40x40/avatar-rounded.webp';
import ProfileDropdownMenu from './ProfileDropdownMenu';
import { Link } from 'react-router-dom';
import ThemeToggler from 'components/common/ThemeToggler';

const NavItems = () => {
  return (
    <div className="navbar-nav navbar-nav-icons flex-row">
      <Nav.Item>
        <ThemeToggler className="px-2" />
      </Nav.Item>
      <Nav.Item>
        <Dropdown autoClose="outside" className="h-100">
          <Dropdown.Toggle
            className="dropdown-caret-none nav-link pe-0 py-0 lh-1 h-100 d-flex align-items-center"
            variant=""
          >
            <Avatar src={avatar} size="l" />
          </Dropdown.Toggle>
          <ProfileDropdownMenu />
        </Dropdown>
      </Nav.Item>
    </div>
  );
};

export default NavItems;
