import React from 'react';
import { ColumnDef, flexRender } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import { Table, TableProps } from 'react-bootstrap';
import { useAdvanceTableContext } from 'providers/AdvanceTableProvider';
import classNames from 'classnames';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from '../base/RevealDropdown';
import ActionNoticeDropdownItems from '../dropdown/ActionNoticeDropdownItems';
import { Notice } from '../../data/notice';

interface NoticeWithPinned extends Notice {
  isPinned: boolean;
}

interface AdvanceTableProps {
  headerClassName?: string;
  bodyClassName?: string;
  rowClassName?: string;
  tableProps?: TableProps;
  hasFooter?: boolean;
}

export const noticeListTableColumns: ColumnDef<NoticeWithPinned>[] = [
  {
    id: 'number',
    header: '번호',
    cell: ({ row: { original } }) => {
      const { number, isPinned } = original;
      return <>{isPinned ? '고정' : number}</>;
    },
    meta: {
      cellProps: {
        className: 'ps-3 text-body py-4'
      },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'title',
    header: '공지사항 제목',
    cell: ({ row: { original } }) => {
      return (
        <Link
          to="/notice-form"
          state={{ notice: original }}
          className="text-decoration-none fw-bold fs-8"
        >
          {original.title}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap py-4' },
      headerProps: { style: { width: '50%' } }
    }
  },
  {
    header: '작성 일자',
    accessorKey: 'modifiedDate',
    cell: ({ row: { original } }) => {
      const { modifiedDate } = original;
      return (
        <>
          {modifiedDate.split('T')[0]}{' '}
          {modifiedDate.split('T')[1].split(':')[0]} :{' '}
          {modifiedDate.split('T')[1].split(':')[1]}
        </>
      );
    },
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-body white-space-nowrap py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },

  {
    id: 'views',
    header: '조회수',
    cell: ({ row: { original } }) => {
      const { views } = original;
      return <>{views}</>;
    },
    meta: {
      cellProps: { className: 'ps-3 py-4' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    id: 'action',
    cell: ({ row: { original } }) => {
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionNoticeDropdownItems notice={original} />
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      headerProps: { style: { width: '5%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const NoticeTable = ({
  headerClassName,
  bodyClassName,
  rowClassName
}: AdvanceTableProps) => {
  const table = useAdvanceTableContext();
  const { getRowModel, getFlatHeaders } = table;

  return (
    <div className="border-translucent">
      <div className="scrollbar ms-n1 ps-1" style={{ minHeight: '500px' }}>
        <Table className={'phoenix-table border-top border-translucent fs-9'}>
          <thead className={headerClassName}>
            <tr>
              {getFlatHeaders().map(header => {
                return (
                  <th
                    key={header.id}
                    {...header.column.columnDef.meta?.headerProps}
                    className={classNames(
                      header.column.columnDef.meta?.headerProps?.className,
                      {
                        sort: header.column.getCanSort(),
                        desc: header.column.getIsSorted() === 'desc',
                        asc: header.column.getIsSorted() === 'asc'
                      }
                    )}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className={bodyClassName}>
            {getRowModel().rows.map(row => {
              const notice = row.original as NoticeWithPinned;
              return (
                <tr key={row.id} className={rowClassName}>
                  {row.getVisibleCells().map(cell => (
                    <td
                      key={cell.id}
                      {...cell.column.columnDef.meta?.cellProps}
                      style={{
                        backgroundColor: notice.isPinned
                          ? 'lightgray'
                          : 'transparent'
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <AdvanceTableFooter pagination className="py-3" />
    </div>
  );
};

export default NoticeTable;
