import { useWizardFormContext } from 'providers/WizardFormProvider';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DatePicker from 'components/base/DatePicker';
import { Tnr } from '../../data/tnr';
import axiosInstance from '../../helpers/apiService';
import { useNavigate } from 'react-router-dom';
import TnrDropzone from '../base/TnrDropzone';
import fetchDropzonePreview from '../../helpers/fetchDropzonePreivew';

const TnrNeuterForm = () => {
  const navigate = useNavigate();
  const methods = useWizardFormContext<Tnr>();
  const { formData, setFormData, onChange, validation } = methods;
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    // setSelectedFiles([]);
    // setSelectedFiles(prevFiles => [...prevFiles, acceptedFiles[0]]);
    setFormData(prevState => ({
      ...prevState,
      selectedTnrNFileList: acceptedFiles
    }));
  };

  useEffect(() => {
    if (!formData?.tnrNFileList || formData.tnrNFileList.length === 0) return;

    fetchDropzonePreview(axiosInstance, formData.tnrNFileList, navigate).then(
      value => {
        setFormData(prevState => ({
          ...prevState,
          selectedTnrNFileList: value || []
        }));
        setSelectedFiles(value || []);
      }
    );
  }, [formData.tnrNFileList]);

  const handleRemoveFile = (file: File) => {
    setFormData(prevState => ({
      ...prevState,
      selectedTnrNFileList: prevState.selectedTnrNFileList!.filter(
        f => f.name !== file.name
      )
    }));
  };

  return (
    <>
      <Row className="mb-4">
        <Col sm={12}>
          <TnrDropzone
            multiple={false}
            className="mb-3"
            accept={{
              'image/*': ['.png', '.gif', '.jpeg', '.jpg']
            }}
            onDrop={onDrop}
            onRemoveFile={handleRemoveFile}
            initialFiles={selectedFiles}
          />
        </Col>
      </Row>

      <Row className="g-3">
        <Col sm={6}>
          <Form.Group className="mb-2">
            <Form.Label className="text-body fs-9">중성화 병원</Form.Label>
            <Form.Control
              type="text"
              name="age"
              placeholder="병원이름"
              // value={formData}
              onChange={onChange}
              required={validation}
            />
            <Form.Control.Feedback type="invalid">
              병원이름은 필수입니다
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <DatePicker
            hideIcon
            render={(_, ref) => {
              return (
                <Form.Group className="mb-2">
                  <Form.Label className="text-body fs-9">
                    중성화 일자
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="중성화 일자"
                    ref={ref}
                    // value={formData.dob}
                    name="dob"
                  />
                </Form.Group>
              );
            }}
          />
        </Col>
      </Row>

      <Form.Group className="mb-2">
        <Form.Label className="text-body fs-9">특이사항</Form.Label>
        <Form.Control
          as="textarea"
          // value={formData.address}
          onChange={onChange}
          rows={4}
          required={validation}
        />
        <Form.Control.Feedback type="invalid">
          This field is required.
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export default TnrNeuterForm;
