import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from 'components/base/Button';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import Dropzone from 'components/base/Dropzone';
import axiosInstance, { refreshToken } from 'helpers/apiService';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoModal from 'components/modals/InfoModal';
import { Report } from '../../data/report';
import { Feeder } from '../../data/feeder';

export interface AttachFile {
  id: string;
  originFileName: string;
  savedFileName: string;
  path: string;
  extension: string;
}

const ReportForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [formState, setFormState] = useState<Report>({
    id: '',
    male: 0,
    female: 0,
    tnred: 0,
    title: '',
    content: '',
    comment: '',
    modifiedDate: '',
    feederId: '',
    fileList: [],
    addFileList: [],
    deleteFileList: []
  });

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [feeders, setFeeders] = useState<Feeder[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [updateMode, setUpdateMode] = useState(false);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);

  const cats: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const contents: string[] = ['주변 청결 상태 양호', '사료 재고 확인 필요'];

  useEffect(() => {
    const getFeeders = async () => {
      try {
        const response = await axiosInstance.get('api/feeders');
        setFeeders(response.data.response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getFeeders);
          } else {
            console.error('통신에러:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    getFeeders();
  }, []);

  useEffect(() => {
    if (location.state?.report) {
      setUpdateMode(true);
      const reportData = location.state?.report;
      setFormState({
        ...reportData,
        feederId: reportData.feeder.id // Set feederId in formState
      });

      // 파일 목록을 Dropzone에 추가
      const getFiles = async () => {
        const files = await Promise.all(
          reportData.fileList?.map(async (file: AttachFile) => {
            const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;
            try {
              const response = await axiosInstance.get(fileUrl, {
                responseType: 'blob',
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
              });

              const blob = response.data;
              return new File([blob], file.originFileName, { type: blob.type });
            } catch (error) {
              console.error('파일 로드 에러:', error);
              return null;
            }
          }) || []
        );
        setSelectedFiles(files.filter(file => file !== null) as File[]);
      };

      getFiles();
    }
  }, [location.state]);

  useEffect(() => {
    if (fileUploadSuccess) {
      saveReport();
      setFileUploadSuccess(false);
    }
  }, [fileUploadSuccess]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFiles([]);
    setSelectedFiles(prevFiles => [...prevFiles, acceptedFiles[0]]);
  };

  const handleRemoveFile = (file: File) => {
    setSelectedFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
  };

  const handleFileUpload = async (): Promise<boolean> => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('files', file);
    });

    if (updateMode) {
      formState.fileList!.map((file, index) => {
        formData.append(`deleteFileList[${index}]`, file.id);
      });

      try {
        await axiosInstance.post('api/file-delete', formData);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, handleFileUpload);
          } else {
            console.error('파일 삭제 에러:', error);
          }
        } else {
          console.error('파일 삭제 에러:', error);
        }
        return false;
      }
    }

    try {
      const response = await axiosInstance.post('api/file-upload', formData);
      const filesData: AttachFile[] = response.data.response;

      setFormState(prevState => ({
        ...prevState,
        addFileList: [
          ...(prevState.addFileList || []),
          ...filesData.map(file => file.id)
        ]
      }));

      setFileUploadSuccess(true);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, handleFileUpload);
        } else {
          console.error('파일 업로드 에러:', error);
        }
      } else {
        console.error('파일 업로드 에러:', error);
      }
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { male, female, tnred, title, content, comment } = formState;

    if (
      male === undefined ||
      female === undefined ||
      tnred === undefined ||
      title.trim() === '' ||
      content.trim() === '' ||
      comment.trim() === '' ||
      formState.feederId === ''
    ) {
      setModalTitle('오류');
      setModalBody('*는 필수 입력값입니다');
      setShowModal(true);
      return;
    }

    if (male + female < tnred) {
      setModalTitle('오류');
      setModalBody('전체 고양이 수보다 중성화 고양이 수가 많을 수 없습니다');
      setShowModal(true);
      return;
    }

    if (selectedFiles.length > 0 || formState.fileList!.length > 0) {
      const uploadSuccess = await handleFileUpload();
      if (!uploadSuccess) {
        console.error('파일 업로드에 실패하여 급식소 저장을 중단합니다.');
      }
    } else {
      await saveReport();
    }
  };

  const saveReport = async () => {
    const formData = new FormData();

    formData.append('userId', localStorage.getItem('userId')!);

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/reports/${formState.id}`, formData);
      } else {
        await axiosInstance.post('api/reports', formData);
      }

      setModalTitle('성공');
      setModalBody(updateMode ? '일지 수정 완료' : '일지 등록 완료');
      setShowModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveReport);
        } else {
          console.error('일지 저장 에러:', error);
        }
      } else {
        console.error('일지 저장 에러:', error);
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalTitle === '성공') {
      navigate('/reports');
    }
  };

  return (
    <div>
      {/*<PageBreadcrumb items={defaultBreadcrumbItems} />*/}
      <div className="mb-5"></div>
      <h2 className="mb-4">{updateMode ? '일지 수정' : '일지 등록'}</h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6" onSubmit={handleSubmit}>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="title" label="제목 *">
                <Form.Control
                  type="text"
                  placeholder="제목 *"
                  onChange={handleChange}
                  maxLength={12}
                  value={formState.title}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="content" label="내용">
                <Form.Select onChange={handleChange} value={formState.content}>
                  <option>선택</option>
                  {contents.map((content, index) => (
                    <option key={index} value={content}>
                      {content}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={2} md={3} className="gy-6">
              <FloatingLabel controlId="feederId" label="급식소 *">
                <Form.Select
                  id="feederId"
                  onChange={handleChange}
                  value={formState.feederId}
                >
                  <option>선택</option>
                  {feeders.map(feeder => (
                    <option key={feeder.id} value={feeder.id}>
                      {feeder.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={2} md={3} className="gy-6">
              <FloatingLabel controlId="male" label="수컷 *">
                <Form.Select onChange={handleChange} value={formState.male}>
                  <option>선택</option>
                  {cats.map(catNum => (
                    <option key={catNum} value={catNum}>
                      {catNum}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={2} md={3} className="gy-6">
              <FloatingLabel controlId="female" label="암컷 *">
                <Form.Select onChange={handleChange} value={formState.female}>
                  <option>선택</option>
                  {cats.map(catNum => (
                    <option key={catNum} value={catNum}>
                      {catNum}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={2} md={3} className="gy-6">
              <FloatingLabel controlId="tnred" label="중성화 *">
                <Form.Select onChange={handleChange} value={formState.tnred}>
                  <option>선택</option>
                  {cats.map(catNum => (
                    <option key={catNum} value={catNum}>
                      {catNum}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <FloatingLabel controlId="comment" label="비고">
                <Form.Control
                  as="textarea"
                  placeholder="비고"
                  style={{ height: '100px' }}
                  onChange={handleChange}
                  value={formState.comment}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <Dropzone
                accept={{
                  'image/*': ['.png', '.gif', '.jpeg', '.jpg']
                }}
                onDrop={handleDrop}
                onRemoveFile={handleRemoveFile}
                initialFiles={selectedFiles}
              />
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="phoenix-primary"
                  className="px-5"
                  onClick={() => navigate('/reports')}
                >
                  취소
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="px-5 px-sm-15"
                >
                  {updateMode ? '수정' : '등록'}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <InfoModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default ReportForm;
