import {
  faArrowLeft,
  faEdit,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "components/base/Button";
import MemberProfileCard from "components/member/MemberProfileCard";
import DealsPrintingDimensionsCard from "components/cards/DealsPrintingDimensionsCard";
import DealsOtherInformation from "components/forms/DealsOtherInformation";
import MemberDetailsInfo from "components/member/MemberDetailsInfo";
import MemberDetailsTab from "components/member/MemberDetailsTab";
import { dealDetailsInfoData, stats } from "data/crm/dealDetailsInfo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { User } from "../../data/users";
import { Col, Row } from "react-bootstrap";
import comingSoonText from "../../assets/img/spot-illustrations/40.png";
import "style.css";

const MemberDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (location.state?.user) {
      const user = location.state?.user;
      setUser(user);
    }
  }, [location.state]);

  return (
    <div>
      <div className="blur-overlay">
        <img
          src={comingSoonText}
          alt=""
          className="mb-5 w-75 w-lg-50 w-xl-75 w-xxl-100 d-dark-none"
          style={{ maxWidth: 415 }}
        />
      </div>
      <div className="pb-5 blur-content">
        <div className="mb-5"></div>
        <div className="mb-9">
          <Row className="align-items-center justify-content-between g-3 mb-4">
            <Col md="auto">
              <h2 className="mb-0">회원 상세</h2>
            </Col>
            <Col md="auto" className="d-flex">
              <Button
                variant="phoenix-primary"
                className="me-2"
                startIcon={
                  <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                }
                onClick={() => navigate("/members")}
              >
                <span>뒤로가기</span>
              </Button>
              <Link
                to="/member-form"
                state={{ user: user }}
                className="text-decoration-none fw-bold fs-8"
              >
                <button className="btn btn-phoenix-secondary px-3 px-sm-5 me-2">
                  <FontAwesomeIcon icon={faEdit} className="me-sm-2" />
                  <span className="d-none d-sm-inline">수정</span>
                </button>
              </Link>
              <Button
                variant="phoenix-danger"
                className="me-2"
                startIcon={<FontAwesomeIcon icon={faTrash} className="me-2" />}
              >
                <span>삭제</span>
              </Button>
            </Col>
          </Row>
          <Row className="g-4 g-xl-6">
            <Col xl={5} xxl={4}>
              <div className="sticky-leads-sidebar">
                <MemberProfileCard className="mb-3" user={user!} />
                <DealsOtherInformation />
              </div>
            </Col>
            <Col xl={7} xxl={8}>
              <DealsPrintingDimensionsCard stats={stats} className="mb-5" />
              <MemberDetailsInfo data={dealDetailsInfoData} className="mb-7" />
              {user && <MemberDetailsTab user={user} />}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MemberDetails;
