import Button from 'components/base/Button';
import DatePicker from 'components/base/DatePicker';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Dropzone from '../../components/base/Dropzone';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Feeder } from '../../data/feeder';
import { Tnr } from '../../data/tnr';
import { User } from '../../data/users';
import { AttachFile } from './ReportForm';
import InfoModal from '../../components/modals/InfoModal';
import DaumPostModal from '../../components/modals/DaumPostModal';
import { Address } from 'react-daum-postcode';
import useDidMountEffect from '../../hooks/useDidMountEffect';

const TnrForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [formState, setFormState] = useState<Tnr>({});
  const [feeders, setFeeders] = useState<Feeder[]>([]);
  const [userList, setUserList] = useState<User[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileUploadSuccess, setFileUploadSuccess] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [showPostcode, setShowPostcode] = useState(false);
  const [addressField, setAddressField] = useState<
    'trapLocation' | 'returnLocation'
  >('trapLocation');

  useEffect(() => {
    const getFeeders = async () => {
      try {
        const response = await axiosInstance.get('api/feeders');
        setFeeders(response.data.response);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getFeeders);
          } else {
            console.error('통신에러:', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    getFeeders();
  }, []);

  const formattingDate = (date: string) => {
    return date
      ? `${date.split('T')[0]} ${date.split('T')[1].split('.')[0]}`
      : null;
  };

  useDidMountEffect(() => {
    if (location.state?.tnr) {
      setUpdateMode(true);
      const tnrData = location.state?.tnr;

      const selectedFeeder = feeders.find(
        feeder => feeder.id === tnrData.feeder.id
      );
      setUserList(selectedFeeder ? selectedFeeder!.userList! : []);

      setFormState({
        ...tnrData,
        feederId: tnrData.feeder.id,
        proposerId: tnrData.user.id,
        trapDate: formattingDate(tnrData?.trapDate),
        neuterDate: formattingDate(tnrData?.neuterDate),
        returnDate: formattingDate(tnrData?.returnDate)
      });

      // 파일 목록을 Dropzone에 추가
      const getFiles = async () => {
        const files = await Promise.all(
          tnrData.tnrTFileList?.map(async (file: AttachFile) => {
            const fileUrl = `${process.env.REACT_APP_BASE_URL}pics/${file.savedFileName}${file.extension}`;
            try {
              const response = await axiosInstance.get(fileUrl, {
                responseType: 'blob',
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
              });

              const blob = response.data;
              return new File([blob], file.originFileName, { type: blob.type });
            } catch (error) {
              console.error('파일 로드 에러:', error);
              return null;
            }
          }) || []
        );
        setSelectedFiles(files.filter(file => file !== null) as File[]);
      };

      getFiles();
    }
  }, [feeders]);

  useEffect(() => {
    if (fileUploadSuccess) {
      saveTnr();
      setFileUploadSuccess(false);
    }
  }, [fileUploadSuccess]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: value
    }));

    if (id === 'feederId') {
      const selectedFeeder = feeders.find(feeder => feeder.id === value);
      setUserList(selectedFeeder ? selectedFeeder!.userList! : []);
      setFormState(prevState => ({
        ...prevState,
        [id]: value,
        proposerId: '' // Reset userId when feederId changes
      }));
    } else if (id === 'proposerId' && value === '선택') {
      setFormState(prevState => ({
        ...prevState,
        [id]: '' // Reset userId if "선택" is chosen
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        [id]: value
      }));
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    setSelectedFiles([]);
    setSelectedFiles(prevFiles => [...prevFiles, acceptedFiles[0]]);
  };

  const handleRemoveFile = (file: File) => {
    setSelectedFiles(prevFiles => prevFiles.filter(f => f.name !== file.name));
  };

  const handleFileUpload = async (): Promise<boolean> => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('files', file);
    });

    if (updateMode) {
      console.log(formState.tnrTFileList);
      formState.tnrTFileList!.map((file, index) => {
        formData.append(`deleteFileList[${index}]`, file.id);
      });

      try {
        await axiosInstance.post('api/file-delete', formData);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, handleFileUpload);
          } else {
            console.error('파일 삭제 에러:', error);
          }
        } else {
          console.error('파일 삭제 에러:', error);
        }
        return false;
      }
    }

    try {
      const response = await axiosInstance.post('api/file-upload', formData);
      const filesData: AttachFile[] = response.data.response;

      setFormState(prevState => ({
        ...prevState,
        addTnrTFileList: [
          ...(prevState.addTnrTFileList || []),
          ...filesData.map(file => file.id)
        ]
      }));

      setFileUploadSuccess(true);
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, handleFileUpload);
        } else {
          console.error('파일 업로드 에러:', error);
        }
      } else {
        console.error('파일 업로드 에러:', error);
      }
      return false;
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const {
      feederId,
      proposerId,
      trapDate,
      gender,
      weight,
      age,
      trapLocation,
      returnDate,
      returnLocation
    } = formState;

    if ((feederId ?? '').trim() === '' || (proposerId ?? '').trim() === '') {
      setModalTitle('오류');
      setModalBody('*는 필수 입력값입니다');
      setShowModal(true);
      return;
    }

    if (
      trapDate &&
      (!gender ||
        !weight ||
        !age ||
        !trapLocation ||
        !(
          selectedFiles.length > 0 ||
          (formState.addTnrTFileList?.length ?? 0) > 0
        ))
    ) {
      setModalTitle('오류');
      setModalBody(
        '포획 일자를 선택 하셨습니다\n' +
          '성별, 몸무게, 추정연령, 포획장소 칸을 채워주세요'
      );
      setShowModal(true);
      return;
    }

    if (returnDate && !returnLocation) {
      setModalTitle('오류');
      setModalBody('방사 일자를 선택하셨습니다\n' + '방사 장소를 입력해주세요');
      setShowModal(true);
      return;
    }

    if (selectedFiles.length > 0 || (formState.tnrTFileList?.length ?? 0) > 0) {
      const uploadSuccess = await handleFileUpload();
      if (!uploadSuccess) {
        console.error('파일 업로드에 실패하여 급식소 저장을 중단합니다.');
        return;
      }
    } else {
      await saveTnr();
    }
  };

  const saveTnr = async () => {
    const formData = new FormData();

    Object.entries(formState).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    try {
      if (updateMode) {
        await axiosInstance.put(`api/tnr/${formState.id}`, formData);
      } else {
        await axiosInstance.post('api/tnr', formData);
      }

      setModalTitle('성공');
      setModalBody(
        updateMode ? 'TNR 신청서 수정 완료' : 'TNR 신청서 등록 완료'
      );
      setShowModal(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 600) {
          await refreshToken(navigate, saveTnr);
        } else {
          console.error('TNR 신청서 저장 에러:', error);
        }
      } else {
        console.error('TNR 신청서 저장 에러:', error);
      }
    }
  };

  const handleDateChange = (
    date: Date[],
    dateType: 'trapDate' | 'neuterDate' | 'returnDate'
  ) => {
    const kstDate = new Date(date[0].getTime() + 9 * 60 * 60 * 1000); // UTC+9로 변환
    setFormState(prevState => ({
      ...prevState,
      [dateType]:
        kstDate.toISOString().split('T')[0] +
        ' ' +
        kstDate.toISOString().split('T')[1].split('.')[0]
    }));
  };

  const handleAddressClick = (field: 'trapLocation' | 'returnLocation') => {
    setAddressField(field);
    setShowPostcode(true);
  };

  const handleAddressComplete = async (data: Address) => {
    setFormState(prevState => ({
      ...prevState,
      [addressField]: data.address
    }));
    setShowPostcode(false);
  };

  const handleModalClose = () => {
    setShowModal(false);
    if (modalTitle === '성공') {
      navigate('/tnr');
    }
  };

  return (
    <div>
      <div className="mb-5"></div>
      <h2 className="mb-4">TNR 신청서</h2>
      <Row>
        <Col xs={12} xl={9}>
          <Row as="form" className="g-3 mb-6" onSubmit={handleSubmit}>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="feederId" label="급식소 *">
                <Form.Select
                  onChange={handleChange}
                  value={formState.feederId || ''}
                >
                  <option>선택</option>
                  {feeders.map(feeder => (
                    <option key={feeder.id} value={feeder.id}>
                      {feeder.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="proposerId" label="관리자 *">
                <Form.Select
                  onChange={handleChange}
                  value={formState.proposerId || ''}
                >
                  <option>선택</option>
                  {userList.map(user => (
                    <option key={user.id} value={user.id}>
                      {user.nick}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="gender" label="성별">
                <Form.Select
                  id="gender"
                  onChange={handleChange}
                  value={formState.gender || ''}
                >
                  <option>선택</option>
                  <option value="male">수컷</option>
                  <option value="female">암컷</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="weight" label="몸무게 (단위 생략)">
                <Form.Control
                  type="text"
                  placeholder="몸무게"
                  value={formState.weight || ''}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <FloatingLabel controlId="age" label="추정연령 (단위 생략)">
                <Form.Control
                  type="text"
                  placeholder="추정연령"
                  value={formState.age || ''}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                onChange={date => handleDateChange(date, 'trapDate')}
                value={formState.trapDate ? new Date(formState.trapDate) : ''}
                render={(_, ref) => (
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="포획 일자"
                      ref={ref}
                      id="trapDate"
                      value={formState.trapDate || ''}
                      readOnly
                    />
                    <label htmlFor="trapDate" className="ps-6">
                      포획 일자
                    </label>
                  </Form.Floating>
                )}
              />
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                onChange={date => handleDateChange(date, 'neuterDate')}
                value={
                  formState.neuterDate ? new Date(formState.neuterDate) : ''
                }
                render={(_, ref) => (
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="중성화 일자"
                      ref={ref}
                      id="neuterDate"
                      value={formState.neuterDate || ''}
                      readOnly
                    />
                    <label htmlFor="neuterDate" className="ps-6">
                      중성화 일자
                    </label>
                  </Form.Floating>
                )}
              />
            </Col>
            <Col sm={6} md={4}>
              <DatePicker
                onChange={date => handleDateChange(date, 'returnDate')}
                value={
                  formState.returnDate ? new Date(formState.returnDate) : ''
                }
                render={(_, ref) => (
                  <Form.Floating>
                    <Form.Control
                      type="text"
                      placeholder="방사 일자"
                      ref={ref}
                      id="returnDate"
                      value={formState.returnDate || ''}
                      readOnly
                    />
                    <label htmlFor="returnDate" className="ps-6">
                      방사 일자
                    </label>
                  </Form.Floating>
                )}
              />
            </Col>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="trapLocation" label="포획장소">
                <Form.Control
                  type="text"
                  placeholder="포획장소"
                  value={formState.trapLocation || ''}
                  onClick={() => handleAddressClick('trapLocation')}
                  readOnly
                />
              </FloatingLabel>
            </Col>
            <Col sm={6} md={6}>
              <FloatingLabel controlId="returnLocation" label="방사장소">
                <Form.Control
                  type="text"
                  placeholder="방사장소"
                  value={formState.returnLocation || ''}
                  onClick={() => handleAddressClick('returnLocation')}
                  readOnly
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <FloatingLabel controlId="significant" label="특이사항">
                <Form.Control
                  as="textarea"
                  placeholder="특이사항"
                  style={{ height: '100px' }}
                  value={formState.significant || ''}
                  onChange={handleChange}
                />
              </FloatingLabel>
            </Col>
            <Col xs={12} className="gy-6">
              <Dropzone
                accept={{ 'image/*': ['.png', '.gif', '.jpeg', '.jpg'] }}
                onDrop={handleDrop}
                onRemoveFile={handleRemoveFile}
                initialFiles={selectedFiles}
              />
            </Col>
            <Col xs={12} className="gy-6">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="phoenix-primary"
                  className="px-5"
                  onClick={() => navigate('/tnr')}
                >
                  취소
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="px-5 px-sm-15"
                >
                  {updateMode ? '수정' : '등록'}
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <DaumPostModal
        show={showPostcode}
        handleClose={() => setShowPostcode(false)}
        handleComplete={handleAddressComplete}
      />
      <InfoModal
        show={showModal}
        title={modalTitle}
        body={modalBody}
        handleClose={handleModalClose}
      />
    </div>
  );
};

export default TnrForm;
