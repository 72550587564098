import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import DaumPostcode, { Address } from 'react-daum-postcode';

interface DaumPostModalProps {
  show: boolean;
  handleClose: () => void;
  handleComplete: (data: Address) => void;
}

const DaumPostModal: React.FC<DaumPostModalProps> = ({
  show,
  handleClose,
  handleComplete
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered className="modal-open">
      <Modal.Header closeButton>
        <Modal.Title>주소 검색</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DaumPostcode onComplete={handleComplete} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DaumPostModal;
