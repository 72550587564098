import Badge from 'components/base/Badge';
import EcomTotalOrdersChart from 'components/charts/e-charts/EcomTotalOrdersChart';
import React from 'react';
import { Card } from 'react-bootstrap';

const EcomTotalOrdersCard = () => {
  return (
    <Card className="h-100">
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            <h5 className="mb-1">
              일별 방문 현황
              <Badge bg="warning" variant="phoenix" pill className="ms-2">
                75%
              </Badge>
            </h5>
            <h6 className="text-body-tertiary">일주일간의 공공급식소 일지 작성을 나타냅니다.</h6>
          </div>
          {/*<h4>873</h4>*/}
        </div>
        <div className="d-flex justify-content-center px-4 py-6">
          <EcomTotalOrdersChart />
        </div>
        <div className="mt-2">
          <div className="d-flex align-items-center mb-2">
            <div className="bullet-item bg-primary me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">작성완료</h6>
            <h6 className="text-body fw-semibold mb-0">52%</h6>
          </div>
          <div className="d-flex align-items-center">
            <div className="bullet-item bg-primary-subtle me-2"></div>
            <h6 className="text-body fw-semibold flex-1 mb-0">
              미작성
            </h6>
            <h6 className="text-body fw-semibold mb-0">48%</h6>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default EcomTotalOrdersCard;
