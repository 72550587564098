import { useWizardFormContext } from 'providers/WizardFormProvider';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Tnr } from '../../data/tnr';
import DaumPostModal from '../modals/DaumPostModal';
import { Address } from 'react-daum-postcode';

const TnrSubmissionForm = ({ id, tnr }: { id: string; tnr: Tnr }) => {
  const methods = useWizardFormContext<Tnr>();
  const { formData, setFormData, onChange, validation } = methods;
  const [showPostcode, setShowPostcode] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);

  useEffect(() => {
    if (Object.keys(tnr).length !== 0) {
      setFormData(prevData => ({
        ...prevData,
        ...tnr
      }));
      setUpdateMode(true);
    } else {
      const userId = localStorage.getItem('userId') ?? '';
      setFormData(prevData => ({
        ...prevData,
        proposerId: userId
      }));
    }
  }, [tnr]);

  const handleAddressComplete = async (data: Address) => {
    setFormData(prevState => ({
      ...prevState,
      submissionAddress: data.address
    }));
    setShowPostcode(false);
  };

  return (
    <>
      <Form.Group className="mb-2">
        <Form.Label className="text-body fs-9">신청자 *</Form.Label>
        <Form.Control
          id="userId"
          type="text"
          name="userId"
          placeholder="신청자"
          value={
            updateMode
              ? formData.user?.nick || '' // Fallback to empty string
              : localStorage.getItem('userNick') ?? ''
          }
          required={validation}
          readOnly
          disabled={updateMode}
        />
        <Form.Control.Feedback type="invalid">
          신청자 이름은 필수입니다
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="text-body fs-9">신청 주소 *</Form.Label>
        <Form.Control
          id="submissionAddress"
          type="text"
          name="submissionAddress"
          placeholder="중성화가 필요한 곳의 주소를 입력해주세요"
          value={formData.submissionAddress || ''} // Fallback to empty string
          onChange={onChange}
          required={validation}
          onClick={() => setShowPostcode(true)}
          // readOnly
          tabIndex={-1}
          disabled={updateMode}
        />
        <Form.Control.Feedback type="invalid">
          신청 주소를 입력해 주세요
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2">
        <Form.Label className="text-body fs-9">상세 *</Form.Label>
        <Form.Control
          id="detailAddress"
          type="text"
          name="detailAddress"
          placeholder="중성화가 필요한 곳의 상세위치를 입력해주세요"
          value={formData.detailAddress || ''} // Fallback to empty string
          onChange={onChange}
          required={validation}
          disabled={updateMode}
        />
        <Form.Control.Feedback type="invalid">
          상세 주소를 입력해 주세요
        </Form.Control.Feedback>
      </Form.Group>
      <div className="g-3 mb-3"></div>
      <DaumPostModal
        show={showPostcode}
        handleClose={() => setShowPostcode(false)}
        handleComplete={handleAddressComplete}
      />
    </>
  );
};

export default TnrSubmissionForm;
