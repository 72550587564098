import SearchBox from 'components/common/SearchBox';
import MemberDetailsTimeline from 'components/member/MemberDetailsTimeline';
import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Report } from '../../data/report';
import axiosInstance, { refreshToken } from '../../helpers/apiService';
import axios from 'axios';
import { User } from '../../data/users'; // Import the User type if not already imported

const MemberDetailsReport = ({ user }: { user: User }) => {
  const navigate = useNavigate();
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getReports = async () => {
      if (!user) {
        setLoading(false);
        return;
      }
      try {
        const response = await axiosInstance.get(
          `api/reports?userId=${user.id}&limit=6`
        );
        setReports(response.data.response.content);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 600) {
            await refreshToken(navigate, getReports);
          } else {
            console.error('서버 에러', error);
          }
        } else {
          console.error('Unexpected error:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    getReports();
  }, [user]); // Ensure the effect re-runs if the user changes

  return (
    <>
      <h2 className="mb-4">일지</h2>
      {/*<Row className="align-items-center g-3 justify-content-between">*/}
      {/*  <Col sm="auto">*/}
      {/*    <SearchBox placeholder="일지 검색" />*/}
      {/*  </Col>*/}
      {/*</Row>*/}
      {loading ? (
        <>
          {[...Array(6)].map((_, index) => (
            <div
              key={index}
              className="placeholder-glow py-4 border-bottom border-translucent"
              style={{ width: '100%' }}
            >
              <div className="d-flex">
                <div
                  className="d-flex bg-primary-subtle rounded-circle flex-center me-3"
                  style={{ width: '25px', height: '25px' }}
                >
                  {/*<span className="placeholder col-12 bg-primary"></span>*/}
                </div>
                <div className="flex-1">
                  <p className="placeholder col-6 mb-2"></p>
                  <p className="placeholder col-4 mb-2"></p>
                  <p className="placeholder col-8 mb-0"></p>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {reports.length > 0 ? (
            <MemberDetailsTimeline reports={reports} />
          ) : (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ height: '200px' }}
            >
              <p className="text-muted">일지가 없습니다.</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default MemberDetailsReport;
