import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Icon, UilClipboardAlt, UilCube, UilSetting, UilUser } from "@iconscout/react-unicons";

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  next?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export const routes: RouteItems[] = [
  // {
  //   label: '관리',
  //   horizontalNavLabel: 'home',
  //   active: true,
  //   icon: UilSetting,
  //   labelDisabled: true,
  //   pages: [
  //     {
  //       name: '관리',
  //       icon: 'clipboard',
  //       active: true,
  //       hasNew: false,
  //       pages: [
  //         {
  //           name: '급식소',
  //           active: true,
  //           pages: [
  //             {
  //               name: '급식소 관리',
  //               path: '/manage/feeders',
  //               active: true
  //             },
  //             {
  //               name: '일지 관리',
  //               path: '/manage/reports',
  //               active: true
  //             }
  //           ]
  //         },
  //         {
  //           name: '통덫',
  //           active: true,
  //           pages: [
  //             {
  //               name: '통덫 관리',
  //               path: '/manage/traps',
  //               active: true
  //             },
  //             {
  //               name: '통덫 예약 관리',
  //               path: '/manage/trap-schedule',
  //               active: true
  //             }
  //           ]
  //         },
  //         {
  //           name: 'TNR',
  //           active: true,
  //           pages: [
  //             {
  //               name: 'TNR 신청 관리',
  //               path: '/manage/tnr',
  //               active: true
  //             }
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // },
  {
    label: '급식소',
    icon: UilCube,
    pages: [
      {
        name: '급식소',
        active: true,
        icon: 'home',
        path: '/feeders'
      }
      // {
      //   name: '일지',
      //   active: true,
      //   icon: 'clipboard',
      //   path: '/reports'
      // }
    ]
  },
  {
    label: '통덫',
    icon: UilCube,
    pages: [
      {
        name: '통덫',
        active: true,
        icon: 'film',
        path: '/traps'
      },
      {
        name: '통덫 예약',
        active: true,
        icon: 'film',
        path: '/trap-schedules'
      }
    ]
  },
  {
    label: 'TNR',
    icon: UilCube,
    pages: [
      {
        name: 'TNR',
        active: true,
        icon: 'github',
        path: '/tnr'
      }
    ]
  },
  {
    label: '공지',
    icon: UilCube,
    pages: [
      {
        name: '공지사항',
        icon: 'bell',
        active: true,
        hasNew: false,
        path: 'notice'
        // pages: []
      }
    ]
  },
  {
    label: '회원',
    icon: UilUser,
    pages: [
      {
        name: '회원',
        active: true,
        icon: 'users',
        path: '/members'
      }
    ]
  }
];
